import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Sidenav, initTE } from 'tw-elements';
import HomeIcon from '@mui/icons-material/Home';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import BrushIcon from '@mui/icons-material/Brush';
import DnsIcon from '@mui/icons-material/Dns';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import InventoryIcon from '@mui/icons-material/Inventory';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SettingsIcon from '@mui/icons-material/Settings';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { RequestPageOutlined, UploadFile } from '@mui/icons-material';
import { SettingsSuggest, SettingsSuggestTwoTone } from '@mui/icons-material';
interface SidebarProps {
    sidebarOpen: boolean;
    setSidebarOpen: (arg: boolean) => void;
    logoImage: any;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen, logoImage }: SidebarProps) => {
    initTE({ Sidenav });

    const location = useLocation();
    const { pathname } = location;

    const trigger = useRef<any>(null);
    const sidebar = useRef<any>(null);

    const invoice_id = 'new';
    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null
            ? false
            : storedSidebarExpanded === 'true'
    );

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: MouseEvent) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: KeyboardEvent) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
        if (sidebarExpanded) {
            document.querySelector('body')?.classList.add('sidebar-expanded');
        } else {
            document
                .querySelector('body')
                ?.classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    return (
        <aside
            ref={sidebar}
            className={`absolute left-0 top-0 z-[1035] flex h-screen w-60 flex-col overflow-y-hidden bg-white duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
        >
            <nav
                id="sidenav-1"
                className="absolute left-0 top-0 z-[1035] h-full w-60 -translate-x-full overflow-hidden bg-white data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-boxdark"
                data-te-sidenav-init
                data-te-sidenav-hidden="false"
                data-te-sidenav-position="absolute"
                data-te-sidenav-accordion="true"
            >
                {/* <!-- SIDEBAR HEADER --> */}
                <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
                    <NavLink to="/">
                        <img
                            className="mx-auto hidden h-10 w-auto dark:block"
                            src={logoImage}
                            alt="Logo"
                        />
                        <img
                            className="mx-auto h-10 w-auto dark:hidden"
                            src={logoImage}
                            alt="Logo"
                        />
                    </NavLink>

                    <button
                        ref={trigger}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                        className="block lg:hidden"
                    >
                        <svg
                            className="fill-current"
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                                fill=""
                            />
                        </svg>
                    </button>
                </div>
                {/* <!-- SIDEBAR HEADER --> */}
                {/* <h3 className="mb-4 ml-4 text-sm font-semibold dark:text-gray-200 text-black">
                    MENU
                </h3> */}
                <ul
                    className="relative m-0 list-none px-[0.2rem]"
                    data-te-sidenav-menu-ref
                >
                    {/* <!-- Dashboard --> */}
                    <li>
                        <NavLink
                            to={`/admin/dashboard`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/dashboard') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <HomeIcon className="h-[20px] w-[20px]" />
                            Dashboard
                        </NavLink>
                    </li>
                    {/* <!-- Dashboard --> */}

                    {/* <!-- Sources --> */}
                    <li>
                        <NavLink
                            to={`/admin/sources`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/sources') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <ContentPasteSearchIcon className="h-[20px] w-[20px]" />
                            Sources
                        </NavLink>
                    </li>
                    {/* <!-- Sources --> */}

                    {/* <!--  Batch Import --> */}
                    <li>
                        <NavLink
                            to={`/admin/batch-import`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/batch-import') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <UploadFile className="h-[20px] w-[20px]" />
                            Batch Import
                        </NavLink>
                    </li>
                    {/* <!--  Batch Import --> */}

                    {/* <!-- Faculty --> */}
                    <li>
                        <NavLink
                            to={`/admin/faculty`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/faculty') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <AccountBalanceIcon className="h-[20px] w-[20px]" />
                            Faculties
                        </NavLink>
                    </li>
                    {/* <!-- Faculty --> */}

                    {/* <!-- Programs --> */}
                    <li>
                        <NavLink
                            to={`/admin/programme`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/programme') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <LibraryBooksIcon className="h-[20px] w-[20px]" />
                            Programs
                        </NavLink>
                    </li>
                    {/* <!-- Programs --> */}

                    {/* <!-- Subjects --> */}
                    <li>
                        <NavLink
                            to={`/admin/subject`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/subject') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <BookmarkIcon className="h-[20px] w-[20px]" />
                            Subjects
                        </NavLink>
                    </li>
                    {/* <!-- Subjects --> */}

                    {/* <!-- Themes --> */}
                    <li>
                        <NavLink
                            to={`/admin/theme`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/theme') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <BrushIcon className="h-[20px] w-[20px]" />
                            Themes
                        </NavLink>
                    </li>
                    {/* <!-- Themes --> */}

                    {/* <!-- Premises --> */}
                    <li>
                        <NavLink
                            to={`/admin/premise`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/premise') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <DnsIcon className="h-[20px] w-[20px]" />
                            Premises
                        </NavLink>
                    </li>
                    {/* <!-- Premises --> */}

                    {/* <!-- Unallocated Premises --> */}
                    <li>
                        <NavLink
                            to={`/admin/unallocated-premises`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes(
                                    'admin/unallocated-premises'
                                ) && 'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <DnsIcon className="h-[20px] w-[20px]" />
                            Unallocated Premises
                        </NavLink>
                    </li>
                    {/* <!-- Unallocated Premises --> */}

                    {/* <!-- Users --> */}
                    <li>
                        <NavLink
                            to={`/admin/users`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/users') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <PermIdentityIcon className="h-[20px] w-[20px]" />
                            Users
                        </NavLink>
                    </li>
                    {/* <!-- Users --> */}

                    {/* <!-- Packages --> */}
                    <li>
                        <NavLink
                            to={`/admin/package`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/package') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <InventoryIcon className="h-[20px] w-[20px]" />
                            Packages
                        </NavLink>
                    </li>
                    {/* <!-- Packages --> */}

                    {/* <!-- Notifications --> */}
                    <li>
                        <NavLink
                            to={`/admin/notification`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/notification') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <NotificationsActiveIcon className="h-[20px] w-[20px]" />
                            Notifications
                        </NavLink>
                    </li>
                    {/* <!-- Notifications --> */}

                    {/* <!-- Settings --> */}
                    <li>
                        <NavLink
                            to={`/admin/setting`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/setting') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <SettingsIcon className="h-[20px] w-[20px]" />
                            Settings
                        </NavLink>
                    </li>
                    {/* <!-- Settings --> */}

                    {/* <!-- Settings --> */}
                    <li>
                        <NavLink
                            to={`/admin/suggestions`}
                            className={`group relative flex items-center gap-2.5 truncate rounded-sm px-4 py-3 font-medium text-black duration-300 ease-in-out hover:bg-sky-100 data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 ${
                                pathname.includes('admin/suggestions') &&
                                'bg-sky-200 dark:bg-white/10'
                            }`}
                            data-te-sidenav-link-ref
                        >
                            <SettingsSuggestTwoTone className="h-[20px] w-[20px]" />
                            User Request
                        </NavLink>
                    </li>
                    {/* <!-- Settings --> */}
                </ul>
            </nav>
        </aside>
    );
};

export default Sidebar;
