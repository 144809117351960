import React, { useEffect, useState } from 'react';
import { Button, FormControl, Modal, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { axiosPost } from '@/services/axios';

export default function DeleteModal({
    openModal,
    setOpenModal,
    onUpdate,
    item,
}) {
    const [error, setError] = useState(false);

    const onChangePassword = (e) => {
        setError(false);
    };

    const deleteAccount = async () => {
        if (item) {
            const res = await axiosPost('/user/group/delete', {
                id: item.id,
            });
            if (res.status == 200) {
                onUpdate({ id: Math.random(), message: res.data.message });
                setOpenModal(false);
            }
            if (res.status == 201) {
                toast.warn(res.data.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="flex min-h-screen items-center px-4 py-8">
                    <div className="relative mx-auto w-full max-w-lg rounded-md bg-black p-4 text-white shadow-lg">
                        <div className="mt-3 sm:flex">
                            <div className="mx-auto flex h-12 w-12 flex-none items-center justify-center rounded-full">
                                <ExclamationTriangleIcon className="text-red-600" />
                            </div>
                            <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                <h4 className="text-lg font-medium">
                                    Delete this SourceBank List: Are You Sure?
                                </h4>
                                <p className="mt-2 text-[15px] leading-relaxed">
                                    Please confirm you wish to delete your
                                    SourceBank List: {item.name}.
                                    <span className="font-bold">
                                        This action cannot be undone.
                                    </span>
                                </p>

                                <div className="mt-3 flex justify-end gap-2">
                                    <button
                                        className="rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                        onClick={deleteAccount}
                                    >
                                        Delete List
                                    </button>
                                    <button
                                        className="ml-2 rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
