import React, { useEffect, useState, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Login from '../component/Login';
import Signup from '../component/Signup';
import { Navigate, Link, useParams, useNavigate } from 'react-router-dom';
import BackgroundImage from '../images/cover/sourcebank_bg.jpg';
import { Grid } from '@mui/material';
import ScrollToTop from '../component/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { axiosPost, fetchImage } from '../services/axios';
import { toast, ToastContainer } from 'react-toastify';

export default function ForgetPassword() {
    const navigate = useNavigate();

    const [emailError, setEmailError] = React.useState('');

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setEmailError('');
            const { email } = e.target.elements;
            const params = {
                email: email.value,
            };
            const ret = await axiosPost('/forgotPassword', params);
            if (ret.status == 200) {
                toast.success(ret.data.message);
            } else {
                toast.error(ret.data.message);
            }
        } catch (err) {
            toast.error(err.response.data.message);
        }
    };

    return (
        <main className="flex flex-col">
            <Grid container spacing={2}>
                <Grid item sm={3}></Grid>
                <Grid item sm={9}>
                    <section
                        style={{ minHeight: '100vh' }}
                        className="h-screen w-full overflow-auto px-4 pb-20 font-sans"
                    >
                        <div id="back-to-top-anchor"></div>
                        <div className="mt-32 w-full px-3">
                            <div className="mx-auto mb-8">
                                <div>
                                    <main className="flex flex-col items-center justify-center">
                                        <div className="w-full rounded-sm bg-transparent">
                                            <div className="flex flex-wrap items-center">
                                                <div className="w-full max-w-lg rounded-lg border-2 border-white bg-black bg-opacity-40 backdrop-blur-[20px]">
                                                    <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                                                        <h2 className="mb-2 text-2xl font-bold text-white dark:text-white">
                                                            Reset Password
                                                        </h2>
                                                        <form
                                                            className="space-y-6"
                                                            action="#"
                                                            method="POST"
                                                            onSubmit={
                                                                handleSubmit
                                                            }
                                                        >
                                                            <div>
                                                                <label
                                                                    htmlFor="email"
                                                                    className="block text-sm font-medium leading-6 text-white"
                                                                >
                                                                    Email
                                                                    address
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        id="email"
                                                                        name="email"
                                                                        type="email"
                                                                        autoComplete="email"
                                                                        required
                                                                        className="w-full rounded-lg border border-white bg-transparent py-3 pl-6 pr-10 text-white outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:focus:border-primary"
                                                                    />
                                                                    {emailError && (
                                                                        <p className="text-sm text-danger">
                                                                            {
                                                                                emailError
                                                                            }
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <button
                                                                    type="submit"
                                                                    className="w-full cursor-pointer rounded-sm border border-white bg-white p-2 text-black transition hover:bg-opacity-90"
                                                                >
                                                                    Reset
                                                                </button>
                                                            </div>
                                                        </form>
                                                        <div className="mt-6 flex justify-between">
                                                            <button
                                                                className="rounded-sm border-white bg-white p-2 text-black"
                                                                onClick={() => {
                                                                    navigate(
                                                                        '/'
                                                                    );
                                                                }}
                                                            >
                                                                Sign In
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </section>
                </Grid>
            </Grid>
        </main>
    );
}
