import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import EditIcon from '@mui/icons-material/Edit';

import TablePagination from '@mui/material/TablePagination';

import { axiosPost } from '@/services/axios';
import CreateEditModal from './CreateEditModal';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { api_url } from '../../../constant';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Toolbar from '@mui/material/Toolbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteModal from './DeleteModal';

const headCells = [
    {
        id: 'status',
        numeric: false,
        sortable: true,
        label: 'Status',
    },
    {
        id: 'user.name',
        numeric: false,
        sortable: false,
        label: 'User Name',
    },
    {
        id: 'url',
        numeric: false,
        sortable: true,
        label: 'Page Url',
    },

    {
        id: 'created_at',
        numeric: false,
        sortable: true,
        label: 'Date Submitted',
    },
    {
        id: 'feedback_type',
        numeric: false,
        sortable: true,
        label: 'Feedback Type',
    },
    {
        id: 'regarding',
        numeric: false,
        sortable: true,
        label: 'Regarding',
    },
    {
        id: 'content',
        numeric: false,
        sortable: false,
        label: 'Comment',
    },
    {
        id: 'action',
        numeric: false,
        sortable: false,
        label: 'Action',
        align: 'right',
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white">
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        align={headCell?.align}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <div>{headCell.label}</div>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const { numSelected, handleDelete, handleExport } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                ></Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton onClick={(e) => handleDelete()}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
                // <Tooltip title="Download Suggestion">
                //     <IconButton onClick={(e) => handleExport()}>
                //         <FileDownloadIcon />
                //     </IconButton>
                // </Tooltip>
            )}
        </Toolbar>
    );
}

const UserSuggestPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [feedbackType, setFeedbackType] = useState('all');
    const [regarding, setRegarding] = useState('all');
    const [status, setStatus] = useState('all');

    const [selected, setSelected] = React.useState([]);

    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_at');

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [editItem, setEditItem] = useState();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
        // Clear previous timeout to avoid redundant API calls
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout to trigger search after 500ms (adjust as needed)
        const timeout = setTimeout(() => {
            getData();
        }, 1000); // Adjust delay time (in milliseconds) as needed

        // Update searchTimeout state to store the timeout ID
        setSearchTimeout(timeout);

        // Cleanup function to clear timeout when component unmounts or searchKey changes
        return () => clearTimeout(timeout);
    }, [
        searchKey,
        feedbackType,
        regarding,
        status,
        page,
        rowsPerPage,
        orderBy,
        order,
    ]);

    const getData = async () => {
        setLoading(true);
        try {
            const params = {
                searchKey,
                feedbackType,
                regarding,
                status,
                page,
                pageSize: rowsPerPage,
                orderBy,
                order,
            };
            const ret = await axiosPost('/admin/suggest/getList', params);
            if (ret.status == 200) {
                const list = ret.data.data;
                const total = ret.data.total;
                setRows(list);
                setTotal(total);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const handleEdit = (item) => {
        setEditItem(item);
        setOpenEditModal(true);
    };

    const onUpdated = () => {
        toast.success('Update User Suggestion Successfully!');
        getData();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openExport = () => {
        let url = api_url + `/admin/suggest/export`;
        window.open(url, '_blank');
    };

    const handleDelete = async () => {
        if (selected.length > 0) {
            setOpenDeleteModal(true);
        }
    };

    const handleDeleteOne = (id) => {
        setSelected([id]);
        setOpenDeleteModal(true);
    };

    const handleDuplicate = async (id) => {
        const ret = await axiosPost('/admin/suggest/duplicate', {
            id: id,
        });

        if (ret.status == 200) {
            toast.success(ret.data.message);
            getData();
        } else {
            toast.error(ret.data.message);
        }
    };

    const displayContent = (content) => {
        let val = content;
        if (content.length > 60) {
            val = content.substring(0, 57) + '...';
        }
        return val;
    };

    const StatusCell = ({ status }) => {
        let color = '';

        switch (status) {
            case 'Pending':
                color = '#ab6f28';
                break;
            case 'Resolved':
                color = '#03b903';
                break;
            case 'Rejected':
                color = '#ff0000';
                break;

            default:
                break;
        }
        if (status == 'Pending') {
        } else {
        }
        return (
            <div
                className="rounded-full px-2 py-1 text-center"
                style={{ backgroundColor: color }}
            >
                {status}
            </div>
        );
    };

    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative mx-auto w-full p-4 dark:bg-transparent">
                <Box
                    component="fieldset"
                    className="rounded-md border border-slate-500 p-3 py-5"
                >
                    <legend className="ml-3 flex">
                        <div className="w-5"></div>
                        {' Filter '} <div className="w-5"></div>
                    </legend>
                    <div className="flex">
                        <TextField
                            id="outlined-error-helper-text"
                            label="Comment"
                            placeholder="Search Comment"
                            value={searchKey}
                            size="small"
                            onChange={(e) => setSearchKey(e.target.value)}
                        />

                        <FormControl size="small" sx={{ ml: 3 }}>
                            <InputLabel id="demo-select-small-label">
                                Feedback Type
                            </InputLabel>
                            <Select
                                className="min-w-[200px]"
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={feedbackType}
                                label="Feedback Type"
                                onChange={(e) =>
                                    setFeedbackType(e.target.value)
                                }
                            >
                                <MenuItem key={0} value={'all'}>
                                    All
                                </MenuItem>
                                <MenuItem key={1} value="Current Page">
                                    Current Page
                                </MenuItem>
                                <MenuItem key={2} value="System-Wide">
                                    System-Wide
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size="small" sx={{ ml: 3 }}>
                            <InputLabel id="demo-select-small-label">
                                Regarding
                            </InputLabel>
                            <Select
                                className="min-w-[200px]"
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={regarding}
                                label="Regarding"
                                onChange={(e) => setRegarding(e.target.value)}
                            >
                                <MenuItem key={5} value={'all'}>
                                    All
                                </MenuItem>
                                <MenuItem key={0} value="Bug/Technical Issue">
                                    Bug/Technical Issue
                                </MenuItem>
                                <MenuItem key={1} value="Suggested Improvement">
                                    Suggested Improvement
                                </MenuItem>
                                <MenuItem key={3} value="Feature Request">
                                    Feature Request
                                </MenuItem>
                                <MenuItem key={4} value="Something Else">
                                    Something Else
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size="small" sx={{ ml: 3 }}>
                            <InputLabel id="demo-select-small-label">
                                Status
                            </InputLabel>
                            <Select
                                className="min-w-[200px]"
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={status}
                                label="Regarding"
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <MenuItem key={3} value={'all'}>
                                    All
                                </MenuItem>
                                <MenuItem key={0} value="Pending">
                                    Pending
                                </MenuItem>
                                <MenuItem key={1} value="Resolved">
                                    Resolved
                                </MenuItem>
                                <MenuItem key={2} value="Rejected">
                                    Rejected
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Box>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={12}></Grid>
                </Grid>

                <EnhancedTableToolbar
                    numSelected={selected.length}
                    handleExport={openExport}
                    handleDelete={handleDelete}
                />
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="rounded-lg bg-gray-50 text-black dark:bg-gray-800 dark:text-white"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        key={index}
                                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        hover
                                        onClick={(event) =>
                                            handleClick(event, row.id)
                                        }
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell component="th">
                                            <StatusCell status={row.status} />
                                        </TableCell>

                                        <TableCell component="th">
                                            {row.user?.name}
                                        </TableCell>

                                        <TableCell component="th">
                                            {row.url}
                                        </TableCell>
                                        <TableCell>
                                            {dayjs(row.created_at).format(
                                                'MMM D, YYYY H:m:s'
                                            )}
                                        </TableCell>
                                        <TableCell component="th">
                                            {row.feedback_type}
                                        </TableCell>
                                        <TableCell component="th">
                                            {row.regarding}
                                        </TableCell>
                                        <TableCell component="th">
                                            {displayContent(row.content)}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    handleEdit(row.id)
                                                }
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    handleDeleteOne(row.id)
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {openEditModal && (
                    <CreateEditModal
                        openModal={openEditModal}
                        setOpenModal={setOpenEditModal}
                        refresh={onUpdated}
                        id={editItem}
                        title={'Update Faculty'}
                    />
                )}

                {openDeleteModal && (
                    <DeleteModal
                        openModal={openDeleteModal}
                        setOpenModal={setOpenDeleteModal}
                        refresh={getData}
                        ids={selected}
                    />
                )}
            </div>
        </div>
    );
};

export default UserSuggestPage;
