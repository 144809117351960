import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CustomCheckbox from './CustomCheckBox';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const schema = yup.object().shape({
    name: yup.string().required(),
    term: yup.string().required(),
    price: yup.number().required().min(0),
    sort_order: yup.number().min(1),
    // referral_discount: yup.number().min(0),
});

const CreateEditModal = ({ setOpenModal, openModal, refresh, id, title }) => {
    const [updateData, setUpdateData] = useState({});
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(0);

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            // description: item?.description || "",
            name: '',
            available: 0,
            term: '',
            price: 0,
            referral_code: '',
            referral_discount: 0,
            blur_quote: 0,
            blur_page_range: 0,
            blur_quote_txt: '',
            blur_range_txt: '',
            sort_order: 0,
            description: '',
        },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const name = watch('name');
    const blur_quote = watch('blur_quote');
    const blur_page_range = watch('blur_page_range');

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const getData = async () => {
        const params = {
            id: id,
        };

        const ret = await axiosPost('/admin/package/getData', params);
        if (ret.status == 200) {
            const _package = ret.data.data;
            const setValues = {
                name: _package?.name,
                available: _package.available,
                term: _package.term,
                price: _package.price,
                referral_code: _package.referral_code,
                referral_discount: _package.referral_discount,
                blur_quote: _package.blur_quote,
                blur_page_range: _package.blur_page_range,
                blur_quote_txt: _package.blur_quote_txt,
                blur_range_txt: _package.blur_range_txt,
                sort_order: _package.sort_order,
                description: _package.description,
            };

            reset(setValues);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const transform = (data) => {
        return {
            ...data,
            blur_quote_txt: data.blur_quote ? data.blur_quote_txt : '',
            blur_range_txt: data.blur_page_range ? data.blur_range_txt : '',
        };
    };

    const onSubmit = async (_data) => {
        const data = transform(_data);
        let url = '/admin/package/store';
        if (id) {
            data['id'] = id;
            setUpdateData(data);
            checkActiveSubscriptions(data);
        } else {
            const ret = await axiosPost(url, data);
            if (ret.status == 200) {
                refresh();
                setOpenModal(false);
            } else {
                toast.error(ret.data.message);
            }
        }
    };

    const checkActiveSubscriptions = async (_data) => {
        let url = '/admin/package/getActiveSubscriptions';
        if (_data['available'] == 0) {
            const ret = await axiosPost(url, _data);
            if (ret.status == 200) {
                const count = ret.data.count;
                if (count == 0) {
                    update(_data);
                } else {
                    setCount(count);
                    setOpen(true);
                }
            }
        } else {
            update(_data);
        }
    };

    const update = async (_data = null) => {
        let url = '/admin/package/update';
        const data = _data ? _data : updateData;
        const ret = await axiosPost(url, data);
        if (ret.status == 200) {
            refresh();
            setOpenModal(false);
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <DialogTitle> {title}</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" sx={{ mb: 2 }}></Typography>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Name</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.name && (
                                                <span className="text-rose-500">
                                                    {errors.name.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Controller
                                    name="available"
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div>
                                            <label>Available</label>
                                            <CustomCheckbox
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Controller
                                    name="term"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Term</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.term && (
                                                <span className="text-rose-500">
                                                    {errors.term.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Controller
                                    name="price"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Price</label>
                                            <TextField
                                                type="number"
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.price && (
                                                <span className="text-rose-500">
                                                    {errors.price.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Controller
                                    name="referral_code"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Referral Code</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.referral_code && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.referral_code
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Controller
                                    name="referral_discount"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Referral Discount</label>
                                            <TextField
                                                type="number"
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.referral_discount && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.referral_discount
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Box className="mt-3">
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    <Controller
                                        name="description"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <div className="mb-3 w-full">
                                                <label>
                                                    Package Description
                                                </label>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    size="small"
                                                    className="mt-1 w-full"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.description && (
                                                    <span className="text-rose-500">
                                                        {
                                                            errors.description
                                                                .message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box className="mt-3 rounded-lg border border-gray-500 p-5">
                            <Grid container spacing={2}>
                                <Grid item sm={6}>
                                    <Controller
                                        name="blur_quote"
                                        control={control}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <div className="mb-8">
                                                <label>Blur Quote</label>
                                                <CustomCheckbox
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        )}
                                    />
                                </Grid>

                                {blur_quote == 1 && (
                                    <Grid item sm={6}>
                                        <Controller
                                            name="blur_quote_txt"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>
                                                        Add placeholder text for
                                                        quote
                                                    </label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.blur_quote_txt && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors
                                                                    .blur_quote_txt
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>

                        <Box className="mt-3 rounded-lg border border-gray-500 p-5">
                            <Grid container spacing={2}>
                                <Grid item sm={6}>
                                    <Controller
                                        name="blur_page_range"
                                        control={control}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <div className="mb-8">
                                                <label>Blur page range</label>
                                                <CustomCheckbox
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        )}
                                    />
                                </Grid>
                                {blur_page_range == 1 && (
                                    <Grid item sm={6}>
                                        <Controller
                                            name="blur_range_txt"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>
                                                        Add placeholder text for
                                                        premise page range
                                                    </label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.blur_range_txt && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors
                                                                    .blur_range_txt
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>

                        <Box className="mt-3">
                            <Grid container spacing={2}>
                                <Grid item sm={6}>
                                    <Controller
                                        name="sort_order"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <div className="mb-3 w-full">
                                                <label>Sort Order</label>
                                                <TextField
                                                    type="number"
                                                    size="small"
                                                    className="mt-1 w-full"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.sort_order && (
                                                    <span className="text-rose-500">
                                                        {
                                                            errors.sort_order
                                                                .message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            maxWidth="sm"
                            fullWidth
                            aria-labelledby="child-modal-title"
                            aria-describedby="child-modal-description"
                        >
                            <DialogContent>
                                <div className="flex">
                                    <div className="mx-auto flex h-12 w-12 flex-none items-center justify-center rounded-full">
                                        <ExclamationTriangleIcon className="text-red-600" />
                                    </div>
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <p
                                            id="child-modal-description"
                                            className=""
                                        >
                                            The {name} Subscription Package has{' '}
                                            {count} number of users currently
                                            subscribed. Disabling this
                                            Subscription Package will disable
                                            all auto-renewals, but users will
                                            retain their subscription access
                                            until all subscriptions periods
                                            expire. Continue?
                                        </p>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        update();
                                    }}
                                >
                                    Continue
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateEditModal;
