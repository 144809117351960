import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { axiosPost } from '@/services/axios';

export default function EditModal({ openModal, setOpenModal, onUpdate, item }) {
    const [name, setName] = useState(item?.name);
    const [error, setError] = useState(false);

    const onChangeName = (e) => {
        setName(e);
        setError(false);
    };

    const updateListName = async () => {
        if (item) {
            try {
                const res = await axiosPost('/user/group/update', {
                    id: item.id,
                    name: name,
                });
                if (res.status == 200) {
                    onUpdate({ id: Math.random(), message: res.data.message });
                    setOpenModal(false);
                }
                if (res.status == 201) {
                    toast.warn(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    });
                }
            } catch (err) {
                console.log(err);
                toast.warn(err?.response?.data?.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="flex min-h-screen items-center px-4 py-8">
                    <div className="relative mx-auto w-full max-w-lg rounded-md bg-gray-900 p-4 shadow-lg">
                        <div className="mt-3">
                            <div className="mt-2 text-center text-white sm:ml-4">
                                <h4 className="text-lg font-medium">
                                    Rename this SourceBank List
                                </h4>
                                <div className="mt-3 text-left text-white">
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            sx={{
                                                color: '#000000',
                                                border: '1px solid #000000',
                                            }}
                                            value={name}
                                            onChange={(e) =>
                                                onChangeName(e.target.value)
                                            }
                                            error={error}
                                        />
                                    </FormControl>
                                </div>
                                <div className="mt-3 flex justify-end gap-2">
                                    <button
                                        className="rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                        onClick={updateListName}
                                    >
                                        Update List Name
                                    </button>
                                    <button
                                        className="ml-2 rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
