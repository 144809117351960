import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { axiosPost } from '@/services/axios';
import dayjs from 'dayjs';
import { Switch } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Spinner from '../../../../component/Spinner';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const UserSubscription = ({ user_id, active, expired }) => {
    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();

    console.log(active?.pause_collection, 'active?.pause_collection');
    const [renew, setRenew] = useState(false);

    useEffect(() => {
        setRenew(active?.pause_collection);
    }, [active]);

    const handleRenew = (renew) => {
        renew ? submit(renew) : setOpen(true);
    };

    const submit = async (renew) => {
        setRenew(renew);
        setOpen(false);
        setLoading(true);

        try {
            const ret = await axiosPost('/admin/user/toggleSubscription', {
                renew: renew,
                user_id,
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong');
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="">
            {active && (
                <Table
                    aria-label="simple table"
                    className="rounded-lg bg-gray-50 text-black dark:bg-gray-800 dark:text-white"
                >
                    <TableHead>
                        <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white">
                            <TableCell>Name of Subscription</TableCell>
                            <TableCell>Subscription Fee</TableCell>
                            <TableCell>Subscription Purchased</TableCell>
                            <TableCell>Duration of Subscription</TableCell>
                            <TableCell>Days Remaining</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Auto Renew</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{active.name}</TableCell>
                            <TableCell>£ {active.price}</TableCell>
                            <TableCell>
                                {dayjs(active.created_at).format(
                                    'YYYY-MM-DD HH:mm:ss'
                                )}
                            </TableCell>
                            <TableCell>{active.term} day(s)</TableCell>
                            <TableCell>
                                {active.remaining_days} day(s)
                            </TableCell>
                            <TableCell>{active.status}</TableCell>
                            <TableCell>
                                <Switch.Group
                                    as="div"
                                    className="flex gap-x-4 sm:col-span-2"
                                >
                                    <div className="flex h-6 items-center">
                                        <Switch
                                            disabled={
                                                loading || !active.available
                                            }
                                            checked={renew}
                                            onChange={handleRenew}
                                            className={classNames(
                                                renew
                                                    ? 'bg-indigo-600'
                                                    : 'bg-gray-200',
                                                'mr-2 flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50'
                                            )}
                                        >
                                            <span className="sr-only">
                                                Agree to auto-renew
                                            </span>
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    renew
                                                        ? 'translate-x-3.5'
                                                        : 'translate-x-0',
                                                    'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                                                )}
                                            />
                                        </Switch>
                                        {loading && <Spinner />}
                                    </div>
                                </Switch.Group>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationTriangleIcon
                                    className="h-6 w-6 text-red-600"
                                    aria-hidden="true"
                                />
                            </div>
                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <div
                                    as="h3"
                                    className="text-base font-semibold leading-6 text-gray-900"
                                >
                                    Turn Auto-Renew off
                                </div>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Are you sure you want to turn Auto-Renew
                                        off? If yes, your access to SourceBank
                                        via this subscription package will end
                                        in {active?.remaining_days} days.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                            onClick={() => submit(false)}
                        >
                            Disable Auto-Renew
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => setOpen(false)}
                        >
                            Keep Auto-Renew
                        </button>
                    </div>
                </div>
            </Dialog>
            {expired.length > 0 && (
                <Table
                    aria-label="simple table"
                    className="rounded-lg bg-gray-50 text-black dark:bg-gray-800 dark:text-white"
                >
                    <TableHead>
                        <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white">
                            <TableCell>Name </TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Ends At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{active.name}</TableCell>
                            <TableCell>£ {active.price}</TableCell>
                            <TableCell>{active.created_at}</TableCell>
                            <TableCell>{active.ends_at}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

export default UserSubscription;
