import React, { useEffect, useRef, useState } from 'react';
import TopSection from '../components/TopSection';
import Filters from '../components/Filters';
import { CSSTransition } from 'react-transition-group';
import {
    PencilSquareIcon,
    PlusIcon,
} from '@heroicons/react/24/outline/index.js';
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { axiosPost } from '../../../services/axios';
import { useAuth } from '../../../context/AuthContext';
import EditThemeModal from './EditThemeModal';

export default function UserSubjectPage({}) {
    const { id } = useParams();

    const [searchParams] = useSearchParams();
    const searchKey = searchParams.get('searchKey');

    const { user } = useAuth();

    const [subject, setSubject] = useState();
    const [countCategories, setCountCategories] = useState([]);
    const [themes, setThemes] = useState([]);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [editItem, setEditItem] = useState();

    const [sourceType, setSourceType] = useState();
    const [sort, setSort] = useState('premises');
    const [gridView, setGridView] = useState(true);
    const [gridViewAnimation, setGridViewAnimation] = useState(true);
    const [keyword, setKeyword] = useState(
        searchKey == undefined ? '' : searchKey
    );

    const gridRef = useRef(null);

    const [page, setPage] = useState(0);

    const [total, setTotal] = useState(0);
    const [last, setLast] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, [id]);

    useEffect(() => {
        if (!loading) {
            setPage(0);
            getSubjectData(0);
        }
    }, [id, sort, sourceType, keyword]);

    useEffect(() => {
        if (page > 0) {
            getSubjectData(page);
        }
    }, [page]);

    useEffect(() => {
        setGridViewAnimation(true);

        setTimeout(() => {
            gridRef.current.classList.toggle('grid-cols-1');
            gridRef.current.classList.toggle('grid-cols-3');
            setGridViewAnimation(false);
        }, 500);
    }, [gridView]);

    const getData = async () => {
        const params = {
            id,
        };

        const ret = await axiosPost('/user/main/subject', params);

        if (ret.status == 200) {
            const subject = ret.data.subject;
            setSubject(subject);
        }
    };

    const getSubjectData = async (_page) => {
        setLoading(true);
        const params = {
            id,
            keyword,
            sort,
            filter: sourceType,
            page: _page,
        };

        const ret = await axiosPost('/user/main/subjectData', params);
        setLoading(false);
        if (ret.status == 200) {
            const _themes = ret.data.themes;
            const total = ret.data.total;
            const last = ret.data.last;
            const countCategories = ret.data.countCategories;

            if (_page == 0) {
                setThemes([..._themes]);
            } else {
                setThemes([...themes, ..._themes]);
            }

            setTotal(total);
            setLast(last);
            setCountCategories(countCategories);
        }
    };

    const handleEdit = (item) => {
        console.log(item);
        setEditItem(item);
        setOpenEditModal(true);
    };

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <TopSection subject={subject} countCategories={countCategories} />

            <div className="mt-4">
                <Filters
                    setSourceType={setSourceType}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    setSort={setSort}
                    setGridView={setGridView}
                    showGridView={true}
                    searchPlaceHolder={
                        'Search for Themes, Premises, Key Words…'
                    }
                />

                <div className="m-2 text-[14pt] font-bold text-white">
                    Select your theme of interest…
                </div>

                <div className={`grid grid-cols-1 gap-4`} ref={gridRef}>
                    {themes &&
                        themes.map((theme, index) => (
                            <CSSTransition
                                key={`theme-${index}`}
                                in={true}
                                appear={true}
                                timeout={1000}
                                classNames="theme-item"
                            >
                                <div
                                    className={`group relative transition-opacity duration-500 ${gridViewAnimation ? 'opacity-0' : 'opacity-1'}`}
                                >
                                    <Link
                                        to={`/theme/${theme.theme_id}?searchKey=${keyword}`}
                                        className="block h-full rounded border border-[#0010aa] bg-white transition-colors hover:bg-indigo-50 hover:shadow"
                                    >
                                        <h4 className="bg-[#0010aa] p-2 text-lg font-medium text-white">
                                            {theme.theme} (
                                            {theme.premises_count})
                                        </h4>
                                        <div className="p-2">
                                            <ul className="pl-0 text-indigo-700">
                                                {theme.premises.map(
                                                    (concept, index) => (
                                                        <li
                                                            key={`concept-${index}`}
                                                        >
                                                            <span className="mr-2 inline-block h-4 w-4 border border-white bg-[#00aa9a] align-middle"></span>{' '}
                                                            {concept}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </Link>

                                    {user.is_admin && (
                                        <div className="absolute bottom-2 right-2 hidden gap-1 group-hover:flex">
                                            <div
                                                onClick={(e) =>
                                                    handleEdit(theme.theme_id)
                                                }
                                                className="inline-block rounded border border-black bg-white p-1 text-[#0010aa] transition-transform duration-200 hover:translate-y-0.5"
                                            >
                                                <PencilSquareIcon className="h-5 w-5" />
                                            </div>
                                            <Link
                                                to={`/add/premise?theme=${theme.theme_id}`}
                                                className="inline-block rounded border border-black bg-white p-1 text-[#0010aa] transition-transform duration-200 hover:translate-y-0.5"
                                            >
                                                <PlusIcon className="h-5 w-5" />
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </CSSTransition>
                        ))}
                </div>

                <div className="mt-5 flex justify-center">
                    {last == false && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                setPage((page) => page + 1);
                            }}
                        >
                            {loading ? 'Loading' : 'Load more'}
                        </Button>
                    )}
                </div>
            </div>
            {openEditModal && (
                <EditThemeModal
                    openModal={openEditModal}
                    setOpenModal={setOpenEditModal}
                    refresh={getData}
                    id={editItem}
                    title={'Update Theme'}
                />
            )}
        </div>
    );
}
