import { Switch } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
// import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Spinner from '../../../../component/Spinner';
import { Dialog } from '@mui/material';
import Slide from '@mui/material/Slide';
import { axiosPost } from '../../../../services/axios';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function SourceBankAccess({
    subscription,
    expiredSubscriptions,
    className = '',
}) {
    const [open, setOpen] = useState(false);
    const [renew, setRenew] = useState(!subscription?.pause_collection);
    const [loading, setLoading] = useState(false);
    const cancelButtonRef = useRef(null);

    useEffect(() => {
        setRenew(subscription?.pause_collection);
    }, [subscription]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleRenew = (renew) => {
        renew ? submit(renew) : setOpen(true);
    };

    const submit = async (renew) => {
        setRenew(renew);
        setOpen(false);
        setLoading(true);

        try {
            const ret = await axiosPost('/user/profile/updateSubscription', {
                renew: renew,
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error('Something went wrong');
        }
    };

    return (
        <section className={className}>
            <header className="mb-5">
                <h2 className="text-lg font-medium">SourceBank Access</h2>
            </header>

            {subscription && (
                <div>
                    <div className="px-4 sm:px-0">
                        <h3 className="text-base font-semibold leading-7">
                            Active Subscriptions
                        </h3>
                    </div>
                    <div className="mt-6 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6">
                                    Name of Subscription
                                </dt>
                                <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                                    {subscription.name}
                                </dd>
                            </div>
                            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6">
                                    Subscription Fee
                                </dt>
                                <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                                    £{subscription.price}
                                </dd>
                            </div>
                            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6">
                                    Duration of Subscription
                                </dt>
                                <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                                    {subscription.term} day(s)
                                </dd>
                            </div>
                            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6">
                                    Days Remaining
                                </dt>
                                <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                                    {subscription.remaining_days} day(s)
                                </dd>
                            </div>
                            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6">
                                    Status
                                </dt>
                                <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                                    Active
                                </dd>
                            </div>
                            <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6">
                                    Auto-Renew
                                </dt>
                                <dd className="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0">
                                    <Switch.Group
                                        as="div"
                                        className="flex gap-x-4 sm:col-span-2"
                                    >
                                        <div className="flex h-6 items-center">
                                            <Switch
                                                disabled={
                                                    loading ||
                                                    !subscription.available
                                                }
                                                checked={renew}
                                                onChange={handleRenew}
                                                className={classNames(
                                                    renew
                                                        ? 'bg-indigo-600'
                                                        : 'bg-gray-200',
                                                    'mr-2 flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50'
                                                )}
                                            >
                                                <span className="sr-only">
                                                    Agree to auto-renew
                                                </span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        renew
                                                            ? 'translate-x-3.5'
                                                            : 'translate-x-0',
                                                        'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                                                    )}
                                                />
                                            </Switch>
                                            {loading && <Spinner />}
                                        </div>
                                    </Switch.Group>
                                </dd>
                            </div>
                        </dl>
                    </div>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon
                                            className="h-6 w-6 text-red-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <div
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Turn Auto-Renew off
                                        </div>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to turn
                                                Auto-Renew off? If yes, your
                                                access to SourceBank via this
                                                subscription package will end in{' '}
                                                {subscription.remaining_days}{' '}
                                                days.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                    onClick={() => submit(false)}
                                >
                                    Disable Auto-Renew
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Keep Auto-Renew
                                </button>
                            </div>
                        </div>
                    </Dialog>
                </div>
            )}

            {!!expiredSubscriptions.length && (
                <div className="mt-5">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-base font-semibold leading-7 text-white">
                            Expired Subscriptions
                        </h3>
                    </div>
                    <div className="mt-6">
                        <table className="table-auto text-center">
                            <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">
                                        Name of Subscription
                                    </th>
                                    <th className="border border-gray-300 p-2">
                                        Subscription Fee
                                    </th>
                                    <th className="border border-gray-300 p-2">
                                        Date Purchased
                                    </th>
                                    <th className="border border-gray-300 p-2">
                                        Date Expired
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {expiredSubscriptions.map(
                                    (expiredSubscription, index) => (
                                        <tr key={index}>
                                            <td className="border border-gray-300 p-2">
                                                {expiredSubscription.name}
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                £{expiredSubscription.price}
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                {
                                                    expiredSubscription.formatted_created_at
                                                }
                                            </td>
                                            <td className="border border-gray-300 p-2">
                                                {
                                                    expiredSubscription.formatted_ends_at
                                                }
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </section>
    );
}
