import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { axiosPost } from '../services/axios';

export default function PremiseGroupModal({
    openModal,
    setOpenModal,
    item,
    onUpdate,
}) {
    const [groupId, setGroupId] = useState('');
    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        if (item) {
            getGroupListForPremise();
        }
    }, [item]);

    useEffect(() => {
        console.log(groupList);
    }, [groupList]);

    const onChangeGroup = (e) => {
        setGroupId(e);
    };

    const getGroupListForPremise = async () => {
        try {
            const ret = await axiosPost('/user/group/getListForPremise', {
                premise: item.id,
            });
            if (ret.status == 200) {
                const list = ret.data.list;
                if (list.length > 0) {
                    const group = list[0];
                    setGroupId(group.id);
                }
                if (list.length == 0) {
                    onUpdate({ id: Math.random(), action: 'OpenCreateModal' });
                    setOpenModal(false);
                    return;
                }
                setGroupList(ret.data.list);
            }
        } catch (err) {
            setGroupList([]);
        }
    };

    const addPremiseToGroup = async () => {
        if (groupId == '') {
            toast.warn('Please Select List', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            return;
        }
        if (item) {
            try {
                const res = await axiosPost('/user/group/addPremiseToGroup', {
                    premise_id: item.id,
                    group_id: groupId,
                });
                if (res.status == 200) {
                    setOpenModal(false);
                    onUpdate({ id: Math.random(), message: res.data.message });
                }
                if (res.status == 201) {
                    toast.warn(res.data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    });
                }
            } catch (err) {
                console.log(err);
                toast.warn(err?.response?.data?.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="flex min-h-screen items-center px-4 py-8">
                    <div className="relative mx-auto w-full max-w-lg rounded-md bg-black p-4 text-white shadow-lg">
                        <div className="mt-3">
                            <div className="mt-2 text-center sm:ml-4">
                                <h4 className="text-lg font-medium">
                                    Add Premise To My SourceBank
                                </h4>
                                <div className="mt-3">
                                    <p className="text-left">
                                        Select your SourceBank list…
                                    </p>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        sx={{ mt: 1 }}
                                    >
                                        <Select
                                            fullWidth
                                            size="small"
                                            value={groupId}
                                            onChange={(e) =>
                                                onChangeGroup(e.target.value)
                                            }
                                            sx={{
                                                mt: 2,
                                                border: 1,
                                                // backgroundColor: "white",
                                                // color: 'black',
                                                // '.MuiSvgIcon-root ': {
                                                //     fill: "black !important",
                                                // }
                                            }}
                                            inputProps={{
                                                MenuProps: {
                                                    MenuListProps: {
                                                        sx: {
                                                            backgroundColor:
                                                                'white',
                                                            color: 'black',
                                                        },
                                                    },
                                                },
                                            }}
                                        >
                                            {groupList.map((item, index) => (
                                                <MenuItem
                                                    value={item.id}
                                                    key={index}
                                                >
                                                    {item?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="mt-3 flex justify-end gap-2">
                                    <button
                                        className="rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                        onClick={addPremiseToGroup}
                                    >
                                        Add
                                    </button>
                                    <button
                                        className="rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
