import React, { useState, useEffect, useCallback } from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';

import { axiosPost } from '@/services/axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { marked } from 'marked';
import {
    ArrowDownIcon,
    ArrowPathIcon,
    ArrowsUpDownIcon,
    DocumentTextIcon,
    EyeIcon,
} from '@heroicons/react/24/outline/index.js';
import { Check, WarningOutlined } from '@mui/icons-material';
import { debounce } from 'lodash';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableCellsIcon } from '@heroicons/react/16/solid/index.js';
import DnsIcon from '@mui/icons-material/Dns';
import { TrashIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const ShowBatchImport = () => {
    const [loading, setLoading] = useState(false);
    const [batchImport, setBatchImport] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [autoUpdateInterval, setAutoUpdateInterval] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (
            ['failed', 'processed', 'extracted'].includes(batchImport?.status)
        ) {
            clearInterval(autoUpdateInterval);
            setAutoUpdateInterval(null);
        } else {
            setAutoUpdateInterval(
                setInterval(() => {
                    axiosPost('/admin/batch-import/getData', {
                        id,
                    }).then((ret) => {
                        ret.data.pages = ret.data.pages.map((row) => {
                            row.markdown = marked(row.markdown);
                            row.show_premises =
                                batchImport.pages.find((r) => r.id === row.id)
                                    ?.show_premises ?? false;
                            return row;
                        });

                        setBatchImport(ret.data);
                    });
                }, 5000)
            );
        }
    }, [batchImport?.status]);

    const getData = async (update = false) => {
        setLoading(true);
        try {
            const params = {
                id,
            };

            const ret = await axiosPost('/admin/batch-import/getData', params);

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            setLoading(false);

            ret.data.pages = ret.data.pages.map((row) => {
                row.markdown = marked(row.markdown);
                return row;
            });

            if (update) {
                return setBatchImport({
                    ...batchImport,
                    ...ret.data,
                });
            } else {
                setBatchImport(ret.data);
            }
        } catch (err) {
            setLoading(false);
        }
    };

    const handleReprocess = async (page) => {
        setLoading(true);

        try {
            const ret = await axiosPost('/admin/batch-import/reprocess', {
                id: batchImport.id,
                page: page,
            });

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            await getData();

            setLoading(false);
            toast.success(
                'The page has been deleted, and the request for reprocessing has been added to the queue.'
            );
        } catch (err) {
            setLoading(false);
        }
    };

    const handleAcceptPremise = async (premiseIds) => {
        console.log(premiseIds);
        if (!Array.isArray(premiseIds)) {
            premiseIds = [premiseIds];
        }

        setLoading(true);

        try {
            const ret = await axiosPost('/admin/batch-import-premises/accept', {
                premises: premiseIds,
            });

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            await getData(true);

            setLoading(false);
            toast.success(
                'The ' +
                    (premiseIds.length > 1 ? 'premises have' : 'premise has') +
                    ' been accepted.'
            );
        } catch (err) {
            setLoading(false);
            toast.error('An error occurred while accepting the premises.');
        }
    };

    const handleDeletePremise = async (premiseIds) => {
        if (!Array.isArray(premiseIds)) {
            premiseIds = [premiseIds];
        }

        setLoading(true);

        try {
            const ret = await axiosPost('/admin/batch-import-premises/delete', {
                premises: premiseIds,
            });

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            batchImport.pages = batchImport.pages.map((row) => {
                row.premises = row.premises.filter(
                    (premise) => !premiseIds.includes(premise.id)
                );
                return row;
            });

            setBatchImport(batchImport);

            setLoading(false);
            toast.success(
                'The ' +
                    (premiseIds.length > 1 ? 'premises have' : 'premise has') +
                    ' been deleted.'
            );
        } catch (err) {
            setLoading(false);
            toast.error('An error occurred while deleting the premises.');
        }
    };

    const debouncedUpdatePrompt = useCallback(
        debounce(async (id, extractPrompt) => {
            try {
                const ret = await axiosPost('/admin/batch-import/update', {
                    id,
                    extract_prompt: extractPrompt,
                });

                if (ret.status !== 200) {
                    return toast.error(ret.data.message);
                }

                toast.success('The extract prompt has been updated.');
            } catch (err) {
                toast.error(
                    'An error occurred while updating the extract prompt.'
                );
            }
        }, 1000),
        []
    );

    const handlePromptEdit = async (value) => {
        if (!value) return;

        setBatchImport({
            ...batchImport,
            extract_prompt: value,
        });

        debouncedUpdatePrompt(batchImport.id, value);
    };

    const debouncePageNumberEdit = useCallback(
        debounce(async (batchImportPageId, value) => {
            try {
                const ret = await axiosPost('/admin/batch-import-page/update', {
                    id: batchImportPageId,
                    page_number: value,
                });

                if (ret.status !== 200) {
                    return toast.error(ret.data.message);
                }

                toast.success('The page number has been updated.');
            } catch (err) {
                toast.error(
                    'An error occurred while updating the page number.'
                );
            }
        }, 1000),
        []
    );

    const handlePageNumberEdit = async (batchImportPageId, value) => {
        if (!value) return;

        const newPages = batchImport.pages.map((row) => {
            if (row.id === batchImportPageId) {
                row.page_number = value;
            }

            return row;
        });

        setBatchImport({
            ...batchImport,
            pages: newPages,
        });

        debouncePageNumberEdit(batchImportPageId, value);
    };

    const handleExtractPremises = async () => {
        setLoading(true);

        try {
            const ret = await axiosPost('/admin/batch-import/extractPremises', {
                id: batchImport.id,
            });

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            await getData();

            setLoading(false);
            toast.success(
                'The premises extraction process has been added to the queue.'
            );
        } catch (err) {
            setLoading(false);
            toast.error(err.data.message);
        }
    };

    const handleEditAllPageNumbers = async (batchImportPageId) => {
        setLoading(true);

        try {
            const ret = await axiosPost('/admin/batch-import/reorderPages', {
                id: batchImport.id,
                page_id: batchImportPageId,
            });

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            await getData();

            setLoading(false);
            toast.success('All page numbers have been updated.');
        } catch (err) {
            setLoading(false);
            toast.error('An error occurred while updating the page numbers.');
        }
    };

    const togglePremiseShow = (row) => {
        const newPages = batchImport.pages.map((r) => {
            if (r.id === row.id) {
                r.show_premises = !r.show_premises;
            }

            return r;
        });

        setBatchImport({
            ...batchImport,
            pages: newPages,
        });
    };

    const tabs = [
        {
            id: 'premises',
            label: 'Premises',
            icon: <DnsIcon className="size-6" />,
            onClick: () => {
                const newPages = batchImport.pages.map((row) => {
                    row.show_premises = !row.show_premises;
                    return row;
                });

                setBatchImport({
                    ...batchImport,
                    pages: newPages,
                });
            },
        },
        {
            id: 'prompt',
            label: 'Extract Prompt',
            icon: <EyeIcon className="size-6" />,
        },
    ];

    const AcceptAllPremises = (params) => (
        <button
            className="flex transform items-center justify-center gap-1 rounded-full bg-green-600 px-4 py-2 font-semibold text-white shadow-md transition duration-200 ease-in-out hover:scale-105 hover:bg-green-500"
            onClick={() => handleAcceptPremise(params.premisesId)}
        >
            <Check className="h-5 w-5" />
            Accept All
        </button>
    );

    const ShowPremise = (premise) => (
        <div
            key={premise.id}
            className={
                'card relative mb-4 rounded-lg border bg-white p-4 shadow-md dark:bg-gray-800 ' +
                (premise.premise_id
                    ? 'border-green-500'
                    : 'dark:border-gray-700')
            }
        >
            <div className="card-content space-y-2">
                <div className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                    {premise.premise}
                </div>

                <div className="text-gray-700 dark:text-gray-300">
                    <span className="font-bold">Theme:</span> {premise.theme}
                </div>

                <div className="text-gray-700 dark:text-gray-300">
                    <span className="font-bold">Quote:</span> {premise.quote}
                </div>

                <div className="text-gray-700 dark:text-gray-300">
                    <span className="font-bold">Page Range:</span>{' '}
                    {premise.quote_page_range}
                </div>

                <div className="text-gray-700 dark:text-gray-300">
                    <span className="font-bold">Type:</span>{' '}
                    {premise.premise_type}
                </div>

                {!premise.premise_id && (
                    <div className="absolute bottom-2 right-2 flex gap-2">
                        <button
                            onClick={() => handleDeletePremise(premise.id)}
                            className="flex size-10 items-center justify-center rounded-full bg-red-500 p-2 text-white transition hover:bg-red-600"
                            aria-label="Delete Premise"
                        >
                            <TrashIcon className="h-5 w-5" />
                        </button>

                        <button
                            onClick={() => handleAcceptPremise(premise.id)}
                            className="flex size-10 items-center justify-center rounded-full bg-green-500 p-2 text-white transition hover:bg-green-600"
                            aria-label="Accept Premise"
                        >
                            <Check className="h-5 w-5" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative mx-auto w-full p-4 dark:bg-transparent">
                <div className="flex justify-between">
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        {batchImport
                            ? 'Batch Import #' + batchImport.id
                            : 'Batch Import'}
                    </Typography>
                </div>

                {['queued', 'processing'].includes(batchImport?.status) && (
                    <div className="mb-6 bg-yellow-100 p-4 dark:bg-yellow-800">
                        <div className="flex flex-row items-center gap-2">
                            <WarningOutlined className="size-6 text-yellow-500" />
                            <div>
                                The batch import is currently being processed.
                                Please wait for the process to complete.
                            </div>
                        </div>
                    </div>
                )}

                <div className="mb-5 bg-white dark:bg-boxdark">
                    <div className="border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
                        <ul className="-mb-px flex flex-wrap">
                            {tabs.map((tab) => (
                                <li key={tab.id} className="me-2">
                                    <div
                                        className={`group inline-flex cursor-pointer items-center justify-center gap-2 rounded-t-lg border-b-2 p-4 ${
                                            selectedTab === tab.id
                                                ? 'border-blue-600 text-blue-600 dark:border-blue-500 dark:text-blue-500'
                                                : 'border-transparent hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300'
                                        }`}
                                        onClick={() => {
                                            setSelectedTab(tab.id);
                                            tab.onClick?.();
                                        }}
                                    >
                                        {tab.icon}
                                        {tab.label}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {selectedTab && (
                        <div className="p-4">
                            {selectedTab === 'prompt' && (
                                <div>
                                    <textarea
                                        value={batchImport?.extract_prompt}
                                        className="h-96 w-full border bg-white p-2 dark:bg-boxdark"
                                        onChange={(e) =>
                                            handlePromptEdit(e.target.value)
                                        }
                                    ></textarea>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => handleExtractPremises()}
                                    >
                                        Extract Premises
                                    </Button>
                                </div>
                            )}

                            {selectedTab === 'premises' && (
                                <div className="flex flex-col gap-5">
                                    {batchImport?.pages
                                        .map((p) => p.premises)
                                        .flat()
                                        .some((p) => !p.premise_id) && (
                                        <div className="flex justify-end">
                                            <AcceptAllPremises
                                                premisesId={batchImport?.pages
                                                    .map((p) => p.premises)
                                                    .flat()
                                                    .map(
                                                        (premise) => premise.id
                                                    )}
                                            />
                                        </div>
                                    )}

                                    {!batchImport?.pages
                                        .map((p) => p.premises)
                                        .flat().length && (
                                        <span className="text-center">
                                            No premises found.
                                        </span>
                                    )}

                                    {batchImport?.pages
                                        .map((p) => p.premises)
                                        .flat()
                                        .map((premise) => (
                                            <ShowPremise
                                                key={premise.id}
                                                {...premise}
                                            />
                                        ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="flex flex-col">
                    {batchImport?.pages.map((row) => {
                        return (
                            <div
                                className="flex flex-col gap-5 xl:flex-row"
                                key={row.id}
                            >
                                <div className="grid grid-cols-2 gap-x-5">
                                    <div className="flex items-center justify-between">
                                        <div className="text-md my-2 flex flex-row items-center gap-2 font-bold uppercase">
                                            <DocumentTextIcon className="size-6" />
                                            Page
                                            <input
                                                type="number"
                                                min="1"
                                                step="1"
                                                className="w-10 border-b bg-transparent text-center [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                                                value={row.page_number}
                                                onChange={(e) =>
                                                    handlePageNumberEdit(
                                                        row.id,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <Tippy content="Renumber pages starting from this point">
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) =>
                                                        handleEditAllPageNumbers(
                                                            row.id
                                                        )
                                                    }
                                                >
                                                    <ArrowsUpDownIcon className="size-5" />
                                                </IconButton>
                                            </Tippy>
                                        </div>

                                        <div className="flex flex-row gap-2">
                                            <Tippy content="Toggle View (Markdown / Premises)">
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        togglePremiseShow(row)
                                                    }
                                                >
                                                    {row.show_premises && (
                                                        <DocumentTextIcon className="size-5" />
                                                    )}

                                                    {!row.show_premises && (
                                                        <DnsIcon className="size-5" />
                                                    )}
                                                </IconButton>
                                            </Tippy>

                                            {[
                                                'failed',
                                                'processed',
                                                'extracted',
                                            ].includes(batchImport.status) && (
                                                <Tippy content="Parse this page again">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            handleReprocess(
                                                                row.page_number
                                                            )
                                                        }
                                                    >
                                                        <ArrowPathIcon className="size-5" />
                                                    </IconButton>
                                                </Tippy>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-md my-2 flex flex-row items-center gap-2 font-bold uppercase">
                                        <EyeIcon className="size-6" />
                                        PDF Page {row.pdf_page_number}
                                    </div>

                                    <div className="bg-white p-4 dark:bg-boxdark">
                                        {!row.show_premises && (
                                            <div
                                                className="markdown-container"
                                                dangerouslySetInnerHTML={{
                                                    __html: row.markdown,
                                                }}
                                            ></div>
                                        )}

                                        {row.show_premises && (
                                            <div className="flex flex-col gap-2">
                                                {row.premises?.length > 0 && (
                                                    <div className="flex justify-end">
                                                        <AcceptAllPremises
                                                            premisesId={row.premises.map(
                                                                (premise) =>
                                                                    premise.id
                                                            )}
                                                        />
                                                    </div>
                                                )}

                                                {row.premises.map((premise) => (
                                                    <ShowPremise
                                                        key={premise.id}
                                                        {...premise}
                                                    />
                                                ))}

                                                {!row.premises?.length && (
                                                    <span className="text-center">
                                                        No premises found.
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="bg-white">
                                        <img
                                            width="100%"
                                            height="100%"
                                            src={row.url}
                                            alt={row.page_number}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ShowBatchImport;
