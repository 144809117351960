import { MenuItem, Select, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FilterSource from '../FilterSource';
import Box from '@mui/material/Box';
import { ArrowForward } from '@mui/icons-material';
import { axiosPost } from '../../../../services/axios';

const OrderItems = [
    {
        value: 'asc',
        label: 'Alphabetical (a - z)',
    },
    {
        value: 'desc',
        label: 'Alphabetical (z - a)',
    },
    {
        value: 'year_desc',
        label: 'Year (newest first)',
    },
    {
        value: 'year_asc',
        label: 'Year (oldest first)',
    },
];

const SourceStyle = [
    {
        value: 'apa',
        label: 'APA Style',
    },
    {
        value: 'mla',
        label: 'MLA Style',
    },
];

export default function SourcesSearch(props) {
    const { programId } = props;
    const [keyword, setKeyword] = useState('');
    const [sources, setSources] = useState([]);
    const [sourceSort, setSourceSort] = useState('asc');
    const [styleType, setStyleType] = useState('apa');

    useEffect(() => {
        getData();
    }, [programId]);

    useEffect(() => {
        if (keyword != undefined) {
            getData();
        }
    }, [keyword, sourceSort]);

    const getData = async () => {
        const params = {
            programId,
            keyword,
            sort: sourceSort,
        };

        const ret = await axiosPost('/user/main/program/sources', params);
        if (ret.status == 200) {
            const sources = ret.data.sources;
            setSources(sources);
        }
    };

    return (
        <>
            <FilterSource setKeyword={setKeyword} />
            <Box className="mb-6 mt-3 flex items-center justify-end">
                <div className="flex-grow">Found {sources.length} sources</div>
                <Select
                    size="small"
                    onChange={(e) => {
                        setSourceSort(e.target.value);
                    }}
                    value={sourceSort}
                    sx={{
                        border: 1,
                        // backgroundColor: "white",
                        // color: 'black',
                        // '.MuiSvgIcon-root ': {
                        //     fill: "black !important",
                        // }
                    }}
                    inputProps={{
                        MenuProps: {
                            MenuListProps: {
                                sx: {
                                    backgroundColor: 'white',
                                    color: 'black',
                                },
                            },
                        },
                    }}
                >
                    {OrderItems.map((option) => {
                        return (
                            <MenuItem
                                className="text-black"
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
                <Select
                    size="small"
                    sx={{
                        ml: 2,
                        border: 1,
                    }}
                    inputProps={{
                        MenuProps: {
                            MenuListProps: {
                                sx: {
                                    backgroundColor: 'white',
                                    color: 'black',
                                },
                            },
                        },
                    }}
                    onChange={(e) => {
                        setStyleType(e.target.value);
                    }}
                    value={styleType}
                >
                    {SourceStyle.map((option) => {
                        return (
                            <MenuItem
                                className="text-black"
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>
            <Box>
                {sources.map((item, index) => {
                    return (
                        <div
                            className="mb-5 flex justify-between text-white"
                            key={index}
                        >
                            <div>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            styleType == 'apa'
                                                ? item.br_apa
                                                : item.br_mla,
                                    }}
                                ></p>
                            </div>
                            <Link
                                to={`/premises/source/${item.id}`}
                                target="_blank"
                            >
                                <IconButton sx={{ color: 'white' }}>
                                    <ArrowForward />
                                </IconButton>
                            </Link>
                        </div>
                    );
                })}
            </Box>
        </>
    );
}
