import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { axiosPost } from '@/services/axios';
import { toast } from 'react-toastify';

const SourceOverView = ({ id }) => {
    const [source, setSource] = useState();

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const getData = async () => {
        const params = {
            id: id,
        };
        const ret = await axiosPost('/admin/source/getData', params);
        if (ret.status == 200) {
            const source = ret.data.source;
            setSource(ret.data.source);
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <div
                        className="rich-content text-white"
                        style={{ overflowWrap: 'anywhere' }}
                        dangerouslySetInnerHTML={{
                            __html: source?.overview,
                        }}
                    ></div>
                </Grid>
            </Grid>
        </>
    );
};

export default SourceOverView;
