// reducers.js
const initialState = {
    eventData: '',
    setting: null,
    categories: [],
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'EMIT_EVENT':
            return {
                ...state,
                eventData: action.payload,
            };
        case 'SAVE_SETTING':
            return {
                ...state,
                setting: action.payload,
            };
        case 'SAVE_INIT':
            return {
                ...state,
                setting: action.payload.setting,
                categories: action.payload.categories,
            };
        default:
            return state;
    }
};

export default eventReducer;
