import React, { useEffect, useState, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Login from '../component/Login';
import Signup from '../component/Signup';
import { Navigate, Link, useParams, useNavigate } from 'react-router-dom';
import BackgroundImage from '../images/cover/sourcebank_bg.jpg';
import { Grid } from '@mui/material';
import ScrollToTop from '../component/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { axiosPost, fetchImage } from '../services/axios';
import { toast, ToastContainer } from 'react-toastify';

export default function VerifyEmail() {
    const navigate = useNavigate();
    const { token } = useParams();

    const verifyEmail = async () => {
        try {
            const params = {
                token,
            };
            const ret = await axiosPost('/verifyEmail', params);
            if (ret.status == 200) {
                toast.success(ret.data.message);
                navigate('/');
            } else {
                toast.error(ret.data.message);
            }
        } catch (err) {
            toast.error(err.response.data.message);
        }
    };

    const elementRef = useRef();

    const [trigger, setTrigger] = useState(false);

    let sTimer = null;
    useEffect(() => {
        const handleScroll = () => {
            console.log(elementRef.current.getBoundingClientRect().top);
            if (
                elementRef.current &&
                elementRef.current.getBoundingClientRect().top < -100
            ) {
                // User has scrolled down
                setTrigger(true);
            } else {
                setTrigger(false);
            }
        };
        sTimer = setInterval(() => {
            handleScroll();
        }, 500);
        return () => {
            if (sTimer) {
                clearInterval(sTimer);
            }
        };
    }, []);

    return (
        <main className="flex flex-col">
            <Grid container spacing={2}>
                <Grid item sm={3}></Grid>
                <Grid item sm={9}>
                    <section
                        style={{ minHeight: '100vh' }}
                        className="h-screen w-full overflow-auto px-4 pb-20 font-sans"
                    >
                        <div id="back-to-top-anchor"></div>
                        <div ref={elementRef} className="mt-32 w-full px-3">
                            <div className="mx-auto mb-8">
                                <div>
                                    <main className="flex flex-col items-center justify-center">
                                        <div className="w-full rounded-sm bg-transparent">
                                            <div className="flex flex-wrap items-center">
                                                <div className="w-full max-w-lg border border-white">
                                                    <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                                                        <h2 className="mb-2 text-2xl font-bold text-white dark:text-white">
                                                            Welcome to
                                                            SourceBank
                                                        </h2>
                                                        <p className="mb-9 font-bold text-white dark:text-white">
                                                            Verify Email Address
                                                        </p>
                                                        <div>
                                                            <button
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    verifyEmail();
                                                                }}
                                                                className="w-full cursor-pointer rounded-sm border border-white bg-white p-2 text-black transition hover:bg-opacity-90"
                                                            >
                                                                Verify
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </section>
                </Grid>
            </Grid>
        </main>
    );
}
