// actions.js
export const emitEvent = (data) => ({
    type: 'EMIT_EVENT',
    payload: data,
});

export const saveSetting = (data) => ({
    type: 'SAVE_SETTING',
    payload: data,
});

export const saveInitialize = (data) => ({
    type: 'SAVE_INIT',
    payload: data,
});
