import React, { useEffect, useState } from 'react';
import { Icon, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';

export default function TermsModal({
    title,
    content,
    openModal,
    setOpenModal,
}) {
    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="flex min-h-screen items-center px-4 py-8">
                    <div className="relative mx-auto w-full max-w-lg rounded-md bg-white p-4 text-black shadow-lg dark:bg-white dark:text-black">
                        <div className="">
                            <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                <h4 className="text-lg font-medium">{title}</h4>
                                <div
                                    className="rich-content mt-3 h-[70vh] overflow-y-auto overflow-x-hidden pt-2 text-black"
                                    style={{ overflowWrap: 'anywhere' }}
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="absolute right-0 top-0">
                            <button
                                className="w-full flex-1 rounded-md p-2.5 outline-none"
                                onClick={() => setOpenModal(false)}
                            >
                                <Close
                                    className="h-5 w-5 text-gray-700 hover:text-black-2"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
