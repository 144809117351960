import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';

import axios, { axiosPost } from '../services/axios';
import { useAuth } from '../context/AuthContext';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

export default function LearnMore({ changeCard }) {
    const setting = useSelector((state) => state.setting);

    const changePage = (page) => {
        changeCard(page);
    };

    return (
        <Grid container spacing={2}>
            <Grid item sm={5}>
                <section className="w-full overflow-auto px-4 font-sans md:h-screen md:pb-20">
                    <div id="back-to-top-anchor"></div>
                    <div className="mt-32 w-full px-3">
                        <div className="mx-auto mb-8">
                            <div className="w-full bg-transparent">
                                <div className="">
                                    <button
                                        className="mt-3 w-full max-w-lg cursor-pointer rounded-md border border-white bg-white p-2 text-black transition hover:bg-opacity-90"
                                        onClick={() => changePage('login')}
                                    >
                                        Sign In
                                    </button>

                                    <button
                                        className="mt-3 w-full max-w-lg cursor-pointer rounded-md border border-white bg-white p-2 capitalize text-black transition hover:bg-opacity-90"
                                        onClick={() => changePage('register')}
                                    >
                                        Create Account
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Grid>
            <Grid item sm={6}>
                <div className="mt-32">
                    <div className="animate-slideIn h-[70vh] overflow-y-auto overflow-x-hidden rounded-lg border border-white bg-black bg-opacity-40 p-2 backdrop-blur-[20px]">
                        <div
                            className="rich-content text-white"
                            style={{ overflowWrap: 'anywhere' }}
                            dangerouslySetInnerHTML={{
                                __html: setting?.learn,
                            }}
                        ></div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
