import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
    name: yup.string().required(),
    sort_order: yup.number().required().min(1),
});

const CreateEditModal = ({ setOpenModal, openModal, refresh, id, title }) => {
    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            // description: item?.description || "",
            name: '',
            sort_order: 0,
            intro_title: '',
            intro_description: '',
        },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const getData = async () => {
        const params = {
            id: id,
        };

        const ret = await axiosPost('/admin/faculty/getData', params);
        if (ret.status == 200) {
            const source = ret.data.source;
            const setValues = {
                name: source?.name,
                sort_order: source?.sort_order,
                intro_title: source?.intro_title,
                intro_description: source?.intro_description,
            };
            reset(setValues);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const onSubmit = async (_data) => {
        console.log('on submit', _data);
        let url = '/admin/faculty/store';
        if (id) {
            _data['id'] = id;
            url = '/admin/faculty/update';
        }
        const ret = await axiosPost(url, _data);
        if (ret.status == 200) {
            refresh();
            setOpenModal(false);
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <DialogTitle> {title}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant="h6"
                                    sx={{ mb: 2 }}
                                ></Typography>

                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Name</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.name && (
                                                <span className="text-rose-500">
                                                    {errors.name.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="sort_order"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Sort Order</label>
                                            <TextField
                                                type="number"
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.sort_order && (
                                                <span className="text-rose-500">
                                                    {errors.sort_order.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="intro_title"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Introduction Title</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.intro_title && (
                                                <span className="text-rose-500">
                                                    {errors.intro_title.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="intro_description"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>
                                                Introduction Description
                                            </label>
                                            <TextField
                                                multiline
                                                rows={5}
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.intro_description && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.intro_description
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateEditModal;
