import { Button, Grid, IconButton, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import PremiseBox from '../../../component/PremiseBox';
import { ArrowForward } from '@mui/icons-material';
import { CSVLink, CSVDownload } from 'react-csv';
import { axiosPost } from '@/services/axios';
import { Link } from 'react-router-dom';

const AntTabs = styled(Tabs)({
    borderBottom: 'none',
    '& .MuiTabs-indicator': {
        backgroundColor: '#ffffff',
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        fontSize: '16px',
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 0.55)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#ffffff',
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#ffffff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    })
);

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export default function GroupDetail({ refresh }) {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [groupId, setGroupId] = useState('');
    const [groupList, setGroupList] = useState([]);
    const [groupItem, setGroupItem] = useState();

    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [editRet, setEditRet] = useState(null);
    const [deleteRet, setDeleteRet] = useState(null);

    const [premises, setPremises] = useState([]);
    const [sources, setSources] = useState([]);
    const [csvData, setCsvData] = useState([]);

    const [premiseFilter, setPremiseFilter] = useState('all');
    const [sourceFilter, setSourceFilter] = useState('all');
    const [sourceSort, setSourceSort] = useState('asc');
    const [styleType, setStyleType] = useState('apa');

    const [totalPremise, setTotalPremise] = useState(0);
    const [totalSource, setTotalSource] = useState(0);

    useEffect(() => {
        if (refresh != 0) {
            getGroupList();
        }
    }, [refresh]);

    useEffect(() => {
        if (deleteRet != undefined) {
            getGroupList();
            if (deleteRet.id) {
                toast.error(deleteRet.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
                setGroupId('');
            }
        }
    }, [deleteRet]);

    useEffect(() => {
        if (editRet != undefined) {
            getGroupList();
            if (editRet.id) {
                toast.success(editRet.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        }
    }, [editRet]);

    useEffect(() => {
        getGroupList();
    }, []);

    useEffect(() => {
        if (groupId) {
            const item = groupList.find((element) => element.id == groupId);
            if (item) {
                setGroupItem(item);
                getDetail();
            }
        }
    }, [groupId, premiseFilter, sourceSort, sourceFilter]);

    useEffect(() => {
        if (sources.length > 0) {
            const tmp = [];
            sources.forEach((element, index) => {
                let item = {
                    ID: index + 1,
                    'BR APA': element.br_apa,
                };

                if (styleType != 'apa') {
                    item = {
                        ID: index + 1,
                        'BR MLA': element.br_mla,
                    };
                }
                tmp.push(item);
            });
            setCsvData(tmp);
        }
    }, [sources]);

    const getGroupList = async () => {
        const ret = await axiosPost('/user/group/getList');
        if (ret.status == 200) {
            setGroupList(ret.data.list);
            if (ret.data.list.length > 0) {
                const item = ret.data.list[0];
                setGroupId(item.id);
            }
        }
    };

    const getDetail = async () => {
        //
        try {
            const params = {
                group_id: groupId,
                premise_filter: premiseFilter,
                source_sort: sourceSort,
                source_filter: sourceFilter,
            };
            const ret = await axiosPost('/user/group/getDetail', params);
            if (ret.status == 200) {
                setPremises(ret.data.premises);
                setSources(ret.data.sources);
                setTotalPremise(ret.data.total_premises);
                setTotalSource(ret.data.total_sources);
            }
        } catch (err) {
            setPremises([]);
            setSources([]);
        }
    };

    const handleDelete = () => {
        const item = groupList.find((element) => element.id == groupId);
        if (item) {
            setGroupItem(item);
            setShowDelete(true);
        }
    };

    const handleUpdate = () => {
        const item = groupList.find((element) => element.id == groupId);
        if (item) {
            setGroupItem(item);
            setShowEdit(true);
        }
    };

    return (
        <>
            <div className="mb-2 text-[18pt] font-bold text-white">
                My SourceBank Lists
            </div>

            <div className="mb-6 text-[12pt] text-white">
                Saving premises you find to a SourceBank List will help you
                organise your source material and auto-generate your
                bibliography, which can be exported. Select your SourceBank list
                from the menu below (or create a new one).
            </div>

            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <div>
                        <Select
                            size="small"
                            fullWidth
                            value={groupId}
                            onChange={(e) => setGroupId(e.target.value)}
                            sx={{
                                border: 1,
                                // backgroundColor: "white",
                                // color: 'black',
                                // '.MuiSvgIcon-root ': {
                                //     fill: "black !important",
                                // }
                            }}
                            inputProps={{
                                MenuProps: {
                                    MenuListProps: {
                                        sx: {
                                            backgroundColor: 'white',
                                            color: 'black',
                                        },
                                    },
                                },
                            }}
                        >
                            {groupList.map((item, index) => (
                                <MenuItem value={item.id} key={index}>
                                    {item?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </Grid>
                <Grid item sm={6}>
                    <div className="flex flex-col items-end">
                        <div className="rounded border border-white p-2">
                            <div className="flex">
                                <div>
                                    Date Created:{' '}
                                    {groupItem
                                        ? dayjs(groupItem.created_at).format(
                                              'YYYY-MM-DD'
                                          )
                                        : '_'}
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    Date Updated:{' '}
                                    {groupItem
                                        ? dayjs(groupItem.updated_at).format(
                                              'YYYY-MM-DD'
                                          )
                                        : '_'}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 flex justify-end">
                        <div>
                            <button
                                className="mr-3 rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                onClick={handleUpdate}
                            >
                                Rename List
                            </button>

                            <button
                                className="rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                onClick={handleDelete}
                            >
                                Delete List
                            </button>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 0, mb: 2 }}>
                {groupId && (
                    <Grid item sm={12}>
                        <div className="mt-4 font-bold">
                            This SourceBank List has {totalPremise} Premises
                            from {totalSource} different Sources.
                        </div>
                    </Grid>
                )}
            </Grid>

            <Grid container sx={{ mt: 0 }}>
                <Grid item sm={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <AntTabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="ant example"
                            variant="fullWidth"
                        >
                            <AntTab label="Premises" />
                            <AntTab label="Bibliography" />
                        </AntTabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                        <Box className="mb-3 flex justify-end">
                            <Select
                                size="small"
                                onChange={(e) => {
                                    setPremiseFilter(e.target.value);
                                }}
                                value={premiseFilter}
                                sx={{
                                    border: 1,
                                    // backgroundColor: "white",
                                    // color: 'black',
                                    // '.MuiSvgIcon-root ': {
                                    //     fill: "black !important",
                                    // }
                                }}
                                inputProps={{
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                backgroundColor: 'white',
                                                color: 'black',
                                            },
                                        },
                                    },
                                }}
                            >
                                {[
                                    {
                                        value: 'all',
                                        label: 'All Premise Types',
                                    },
                                    {
                                        value: 'From Secondary Literature',
                                        label: 'From Secondary Literature',
                                    },
                                    {
                                        value: 'Empirical Finding',
                                        label: 'Empirical Finding',
                                    },
                                    {
                                        value: 'Other',
                                        label: 'Other',
                                    },
                                ].map((option) => {
                                    return (
                                        <MenuItem
                                            className="text-black"
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
                        <Box>
                            {premises &&
                                premises.map((premise, index) => (
                                    <PremiseBox
                                        key={index}
                                        premise={premise}
                                        index={index}
                                        showAddButton={false}
                                    />
                                ))}
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        <Box className="mb-3 flex justify-end">
                            <Select
                                size="small"
                                onChange={(e) => {
                                    setSourceSort(e.target.value);
                                }}
                                value={sourceSort}
                                sx={{
                                    border: 1,
                                    // backgroundColor: "white",
                                    // color: 'black',
                                    // '.MuiSvgIcon-root ': {
                                    //     fill: "black !important",
                                    // }
                                }}
                                inputProps={{
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                backgroundColor: 'white',
                                                color: 'black',
                                            },
                                        },
                                    },
                                }}
                            >
                                {[
                                    {
                                        value: 'asc',
                                        label: 'Alphabetical (a - z)',
                                    },
                                    {
                                        value: 'desc',
                                        label: 'Alphabetical (z - a)',
                                    },
                                    {
                                        value: 'year_desc',
                                        label: 'Year (newest first)',
                                    },
                                    {
                                        value: 'year_asc',
                                        label: 'Year (oldest first)',
                                    },
                                ].map((option) => {
                                    return (
                                        <MenuItem
                                            className="text-black"
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <Select
                                size="small"
                                sx={{
                                    ml: 2,
                                    border: 1,
                                    // backgroundColor: "white",
                                    // color: 'black',
                                    // '.MuiSvgIcon-root ': {
                                    //     fill: "black !important",
                                    // }
                                }}
                                inputProps={{
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                backgroundColor: 'white',
                                                color: 'black',
                                            },
                                        },
                                    },
                                }}
                                onChange={(e) => {
                                    setStyleType(e.target.value);
                                }}
                                value={styleType}
                            >
                                {[
                                    {
                                        value: 'apa',
                                        label: 'APA Style',
                                    },
                                    {
                                        value: 'mla',
                                        label: 'MLA Style',
                                    },
                                ].map((option) => {
                                    return (
                                        <MenuItem
                                            className="text-black"
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>

                            <button
                                className="ml-4 rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                disabled={groupId == '' ? true : false}
                            >
                                <CSVLink data={csvData}>
                                    Export Bibliography
                                </CSVLink>
                            </button>
                        </Box>
                        <Box>
                            {sources.map((item, index) => {
                                return (
                                    <div
                                        className="mb-5 flex justify-between text-white"
                                        key={index}
                                    >
                                        <div>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        styleType == 'apa'
                                                            ? item.br_apa
                                                            : item.br_mla,
                                                }}
                                            ></p>
                                        </div>
                                        <Link
                                            to={`/premises/source/${item.id}`}
                                        >
                                            <IconButton sx={{ color: 'white' }}>
                                                <ArrowForward />
                                            </IconButton>
                                        </Link>
                                    </div>
                                );
                            })}
                        </Box>
                    </CustomTabPanel>
                </Grid>
            </Grid>
            {showEdit && (
                <EditModal
                    item={groupItem}
                    openModal={showEdit}
                    setOpenModal={setShowEdit}
                    onUpdate={setEditRet}
                />
            )}

            {showDelete && (
                <DeleteModal
                    item={groupItem}
                    openModal={showDelete}
                    setOpenModal={setShowDelete}
                    onUpdate={setDeleteRet}
                />
            )}
        </>
    );
}
