import React from 'react';
import IOSSwitch from '../../../component/IOSSwitch';

const CustomCheckbox = ({ value, onChange, label }) => {
    return (
        <div className="flex items-center">
            <IOSSwitch
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
            />
            <label className="ml-2 block text-sm leading-5 text-gray-900">
                {label}
            </label>
        </div>
    );
};

export default CustomCheckbox;
