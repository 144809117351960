import Axios from 'axios';
import { api_url } from '../constant';
import { Navigate, Outlet } from 'react-router-dom';

const axios = Axios.create({
    baseURL: api_url,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

export const axiosPost = async (url, data, headers = {}) => {
    try {
        const resp = await axios.post(url, data, headers);
        return resp;
    } catch (error) {
        if (error.response.status === 401 || error.response.status === 419) {
            localStorage.removeItem('user');
            window.location.href = '/';
        } else {
            return error.response;
        }
    }
};

export const fetchImage = async (file_path) => {
    try {
        const response = await axios.post(
            `/image?filepath=${file_path}`,
            {
                filepath: file_path,
            },
            {
                responseType: 'blob',
            }
        );
        return URL.createObjectURL(response.data);
    } catch (error) {
        console.error('Error fetching file:', error);
    }
};

export default axios;
