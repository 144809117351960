import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TagCloud } from 'react-tagcloud';
import FilterProgram from '../FilterProgram';
import MostReferenceCopy from './MostReferenceCopy';
import MostQuoteCopy from './MostQuoteCopy';
import MostPopular from './MostPopular';
import SearchTerms from './SearchTerms';

export default function SubjectsThemesPremisesSearch(props) {
    const {
        subjectList,
        sources,
        sourcesQuoted,
        mostPopular,
        mostSearch,
        opacity,
        setKeyword,
        keyword,
    } = props;

    const [expanded, setExpanded] = useState(-1);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : -1);
    };

    return (
        <>
            <FilterProgram setKeyword={setKeyword} />
            <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item sm={6}>
                    <div className="rounded border border-white bg-white bg-opacity-25 px-2 py-2 backdrop-blur">
                        <div className="m-2 mt-0 border-b border-b-white text-[14pt] font-bold text-white">
                            {'Explore Programme Subjects'}
                        </div>

                        {subjectList.map((subject, index) => (
                            <Accordion
                                expanded={expanded === index}
                                onChange={handleChange(index)}
                                key={index}
                            >
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <div>
                                        <span className="font-bold">
                                            {subject?.name}
                                        </span>
                                        <span>
                                            {' '}
                                            ({subject.themes.length} Themes)
                                        </span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="mb-3 flex justify-center">
                                        <Link
                                            to={`/subject/${subject.id}?searchKey=${keyword}`}
                                        >
                                            <button className="btn rounded-sm border border-white bg-transparent p-1 px-4">
                                                Search This Subject
                                            </button>
                                        </Link>
                                    </div>

                                    <div className="mb-3">
                                        {subject.description}
                                    </div>

                                    <Grid container spacing={2}>
                                        <Grid item sm={12} key={'header'}>
                                            <Grid container spacing={2}>
                                                <Grid item sm={6}>
                                                    <div className="flex h-full items-center font-bold">
                                                        Themes
                                                    </div>
                                                </Grid>
                                                <Grid item sm={4}>
                                                    <div className="flex h-full items-center font-bold">
                                                        No. of Premises
                                                    </div>
                                                </Grid>
                                                <Grid item sm={2}></Grid>
                                            </Grid>
                                        </Grid>
                                        {subject?.themes.map(
                                            (theme, index2) => (
                                                <Grid item sm={12} key={index2}>
                                                    <Grid container spacing={2}>
                                                        <Grid item sm={6}>
                                                            <div className="flex h-full items-center">
                                                                {theme?.theme}
                                                            </div>
                                                        </Grid>
                                                        <Grid item sm={4}>
                                                            <div className="flex h-full items-center">
                                                                {
                                                                    theme?.premises_count
                                                                }
                                                            </div>
                                                        </Grid>
                                                        <Grid item sm={2}>
                                                            <Link
                                                                to={`/theme/${theme.theme_id}?searchKey=${keyword}`}
                                                            >
                                                                <IconButton
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    <ArrowForwardIcon />
                                                                </IconButton>
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </Grid>
                <Grid item sm={6}>
                    <MostReferenceCopy sources={sources} opacity={opacity} />
                    <div className="mt-3">
                        <MostQuoteCopy
                            sources={sourcesQuoted}
                            opacity={opacity}
                        />
                    </div>
                    <div className="mt-3">
                        <MostPopular premises={mostPopular} opacity={opacity} />
                    </div>
                    <div className="mt-3">
                        <SearchTerms data={mostSearch} opacity={opacity} />
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
