import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Sidenav, initTE } from 'tw-elements';
import {
    ArrowDownOnSquareIcon,
    BanknotesIcon,
    ChartPieIcon,
    ChevronDownIcon,
    CircleStackIcon,
    Cog8ToothIcon,
    ScaleIcon,
    TrashIcon,
    UserCircleIcon,
    LinkIcon,
} from '@heroicons/react/24/outline';
import { Backup, HistoryRounded } from '@mui/icons-material';
import { MenuItem, Select, Tooltip } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { CustomScroll } from 'react-custom-scroll';
import {
    AcademicCapIcon,
    BookOpenIcon,
    PlusIcon,
    MinusIcon,
} from '@heroicons/react/24/outline/index.js';
import { axiosPost } from '../services/axios';
import Spinner from './Spinner';
import SuggestImprovementModal from './SuggestImprovementModal';

const Sidebar = ({
    sidebarOpen,
    setSidebarOpen,
    logoImage,
    copyright,
    categories,
    selectedSubject = null,
    selectedProgram = null,
}) => {
    const navigate = useNavigate();
    initTE({ Sidenav });

    const [loading, setLoading] = useState(false);

    const [premises, setPremises] = useState([]);
    const [faculty, setFaculty] = useState('');
    const [programs, setPrograms] = useState([]);

    const { user } = useAuth();
    const location = useLocation();
    const { pathname } = location;

    const trigger = useRef(null);
    const sidebar = useRef(null);

    const invoice_id = 'new';
    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null
            ? false
            : storedSidebarExpanded === 'true'
    );

    // suggest modal
    const [openSuggestModal, setOpenSuggestModal] = useState(false);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            console.log('click outside');
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
        if (sidebarExpanded) {
            document.querySelector('body')?.classList.add('sidebar-expanded');
        } else {
            document
                .querySelector('body')
                ?.classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    // signout
    // logout user
    const handleLogout = async () => {
        try {
            setLoading(true);
            const resp = await axiosPost('/logout');
            setLoading(false);
            if (resp.status === 200) {
                localStorage.removeItem('user');
                window.location.href = '/';
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // menu list

    useEffect(() => {
        let groupedCategories = [];

        categories.map((category) => {
            const {
                id: s_id,
                program: {
                    name: program,
                    faculty: {
                        name: faculty,
                        sort_order: faculty_sort_order,
                        id: f_id,
                    },
                    sort_order: program_sort_order,
                    id: p_id,
                },
                name: subject,
                sort_order: subject_sort_order,
            } = category;

            // console.log(faculty);

            let index = groupedCategories.findIndex(
                (cat) => cat.faculty === faculty
            );
            if (index === -1) {
                groupedCategories.push({
                    id: f_id,
                    order: faculty_sort_order,
                    faculty,
                    sort_order: faculty_sort_order,
                    show: selectedSubject
                        ? selectedSubject.program.faculty.name === faculty
                        : false,
                });

                groupedCategories = groupedCategories.sort(function (a, b) {
                    var keyA = a.order,
                        keyB = b.order;
                    // Compare the 2 dates
                    if (keyA > keyB) return -1;
                    if (keyA < keyB) return 1;
                    return 0;
                });
                index = groupedCategories.findIndex((item) => item.id == f_id);
            }

            const showProgram = selectedProgram
                ? selectedProgram.faculty.name === faculty &&
                  selectedProgram.name === program
                : false;
            if (groupedCategories[index].programs) {
                let programIndex = groupedCategories[index].programs.findIndex(
                    (groupedProgram) => groupedProgram.program === program
                );
                if (programIndex === -1) {
                    groupedCategories[index].programs.push({
                        id: p_id,
                        order: program_sort_order,
                        program,
                        subjects: [
                            {
                                subject,
                                id: s_id,
                                order: subject_sort_order,
                            },
                        ],
                        show: showProgram,
                    });
                    groupedCategories[index].programs = groupedCategories[
                        index
                    ].programs.sort(function (a, b) {
                        var keyA = a.order,
                            keyB = b.order;
                        // Compare the 2 dates
                        if (keyA > keyB) return -1;
                        if (keyA < keyB) return 1;
                        return 0;
                    });
                } else {
                    groupedCategories[index].programs[
                        programIndex
                    ].subjects.push({
                        subject,
                        id: s_id,
                        order: subject_sort_order,
                    });

                    groupedCategories[index].programs[programIndex].subjects =
                        groupedCategories[index].programs[
                            programIndex
                        ].subjects.sort(function (a, b) {
                            var keyA = parseInt(a.order),
                                keyB = parseInt(b.order);
                            // Compare the 2 dates
                            if (keyA > keyB) return -1;
                            if (keyA < keyB) return 1;
                            return 0;
                        });

                    // console.log(groupedCategories[index].programs[programIndex].subjects);
                }
            } else {
                groupedCategories[index].programs = [
                    {
                        id: p_id,
                        order: program_sort_order,
                        program,
                        subjects: [
                            {
                                subject,
                                id: s_id,
                                order: subject_sort_order,
                            },
                        ],
                        show: showProgram,
                    },
                ];
                groupedCategories[index].programs = groupedCategories[
                    index
                ].programs.sort(function (a, b) {
                    var keyA = a.order,
                        keyB = b.order;
                    // Compare the 2 dates
                    if (keyA > keyB) return -1;
                    if (keyA < keyB) return 1;
                    return 0;
                });
            }
        });

        setPremises(groupedCategories);

        if (groupedCategories.length > 0) {
            let item = groupedCategories[0];
            let fid = item.id;
            let _fid = localStorage.getItem('menu_faculty_id');
            if (_fid != undefined) {
                fid = parseInt(_fid);
            }
            setFaculty(fid);
            window.location.href = `#/faculty/${fid}`;
        }
    }, [categories]);

    useEffect(() => {
        if (faculty != '') {
            let _cate = premises.find((item) => item.id == faculty);
            let _programs = _cate.programs;
            setPrograms(_programs);
            // console.log(_programs);
        }
    }, [faculty, premises]);

    const onChangeFaculty = (val) => {
        localStorage.setItem('menu_faculty_id', val);
        setFaculty(val);
        window.location.href = `#/faculty/${val}`;
    };

    const toggleListItem2 = (programIndex = undefined) => {
        const newPrograms = [...programs];

        if (typeof programIndex !== 'undefined') {
            newPrograms[programIndex].show = !newPrograms[programIndex].show;
        }
        setPrograms(newPrograms);
    };

    const openProgramPage = (program_id) => {
        console.log('goto program page');
        // window.location.href = route("program", program_id);
        navigate(`/programme/${program_id}`);
    };
    return (
        <aside
            ref={sidebar}
            className={`absolute left-0 top-0 z-[1035] flex h-screen w-[350px] flex-col overflow-y-hidden bg-[#10aa00] duration-300 ease-linear lg:static lg:translate-x-0 ${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
        >
            <nav
                id="sidenav-user"
                className="absolute left-0 top-0 z-[1035] flex h-full w-[350px] -translate-x-full flex-col justify-between overflow-hidden p-6 pb-1 data-[te-sidenav-hidden='false']:translate-x-0"
                data-te-sidenav-init
                data-te-sidenav-hidden="false"
                data-te-sidenav-position="absolute"
                data-te-sidenav-accordion="true"
            >
                <div>
                    {/* <!-- SIDEBAR HEADER --> */}
                    <div className="flex items-center justify-between">
                        <NavLink to="/welcome">
                            <img
                                className="mx-auto hidden h-10 w-auto dark:block"
                                src={logoImage}
                                alt="Logo"
                            />
                            <img
                                className="mx-auto h-10 w-auto dark:hidden"
                                src={logoImage}
                                alt="Logo"
                            />
                        </NavLink>

                        <button
                            ref={trigger}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            className="block lg:hidden"
                        >
                            <svg
                                className="fill-current"
                                width="20"
                                height="18"
                                viewBox="0 0 20 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                                    fill=""
                                />
                            </svg>
                        </button>
                    </div>
                    {/* <!-- SIDEBAR HEADER --> */}
                    <div className="mt-3 border border-white p-3">
                        <p className="font-bold text-white">
                            Select Your Faculty:
                        </p>
                        <Select
                            size="small"
                            className="w-full"
                            value={faculty}
                            onChange={(e) => onChangeFaculty(e.target.value)}
                            sx={{
                                mt: 2,
                                border: 1,
                                // backgroundColor: "white",
                                // color: 'black',
                                // '.MuiSvgIcon-root ': {
                                //     fill: "black !important",
                                // }
                            }}
                            inputProps={{
                                MenuProps: {
                                    MenuListProps: {
                                        sx: {
                                            backgroundColor: 'white',
                                            color: 'black',
                                        },
                                    },
                                },
                            }}
                        >
                            {premises.map((item) => (
                                <MenuItem key={Math.random()} value={item.id}>
                                    {item.faculty}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>

                <CustomScroll flex={1} className="mb-4 mt-3">
                    <div>
                        <div
                            className="mb-2 ml-0 mr-4 mt-2"
                            style={{ overflow: 'auto' }}
                        >
                            <ul className="ml-0 mt-1 text-white">
                                {programs.map((program, programIndex) => (
                                    <li
                                        key={`program-${programIndex}`}
                                        className="mb-1"
                                    >
                                        <div
                                            className={`flex cursor-pointer items-center gap-2.5 truncate whitespace-normal rounded-[5px] rounded-b-none border-b-1 px-2 py-2 text-[1.1rem] font-medium text-black outline-none transition duration-1000 ease-linear hover:bg-sky-100 hover:text-inherit hover:outline-none focus:bg-sky-100 focus:text-inherit focus:outline-none active:bg-sky-100 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10`}
                                        >
                                            <div
                                                className="flex"
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    toggleListItem2(
                                                        programIndex
                                                    );
                                                }}
                                            >
                                                {program.show == true ? (
                                                    <MinusIcon className="h-[20px] w-[20px] stroke-current" />
                                                ) : (
                                                    <PlusIcon className="h-[20px] w-[20px] stroke-current" />
                                                )}
                                                <AcademicCapIcon className="ml-2 h-[20px] w-[20px] stroke-current" />
                                            </div>
                                            <span
                                                onClick={async () => {
                                                    openProgramPage(
                                                        program?.id
                                                    );
                                                }}
                                            >
                                                {program.program}
                                            </span>
                                        </div>

                                        {program.show && (
                                            <ul className="ml-2 mt-1">
                                                {program.subjects.map(
                                                    (subject, index) => (
                                                        <li
                                                            key={`subject-${index}`}
                                                        >
                                                            <NavLink
                                                                to={`subject/${subject.id}`}
                                                                className={`group relative flex items-start gap-1 truncate whitespace-normal rounded-sm py-1 pl-2 text-black duration-300 ease-in-out hover:bg-sky-100 dark:text-white dark:hover:bg-white/10 ${
                                                                    pathname.includes(
                                                                        `subject/${subject.id}`
                                                                    ) &&
                                                                    'bg-sky-200 dark:bg-white/10'
                                                                }`}
                                                            >
                                                                <BookOpenIcon className="mr-2 mt-0.5 h-5 w-5 flex-shrink-0" />
                                                                {
                                                                    subject.subject
                                                                }
                                                            </NavLink>
                                                        </li>
                                                        // </div>
                                                    )
                                                )}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </CustomScroll>

                <div>
                    {user != undefined && (
                        <div className="grid grid-cols-2 gap-3 text-center font-bold">
                            {user.is_admin && (
                                <>
                                    <Link
                                        to="/add/source"
                                        className="block rounded border border-white px-2 py-1.5 text-white transition-colors hover:bg-white hover:text-[#10aa00]"
                                    >
                                        Add New Source
                                    </Link>
                                    <Link
                                        to="/add/premise"
                                        className="block rounded border border-white px-2 py-1.5 text-white transition-colors hover:bg-white hover:text-[#10aa00]"
                                    >
                                        Add New Premise
                                    </Link>
                                </>
                            )}

                            <>
                                <Link
                                    to={'/mysourcebank'}
                                    className="col-span-2 block rounded border border-white px-2 py-1.5 text-white transition-colors hover:bg-white hover:text-[#10aa00]"
                                >
                                    My SourceBank
                                </Link>
                            </>
                            <Link
                                to="/profile"
                                className="block rounded border border-white px-2 py-1.5 text-white transition-colors hover:bg-white hover:text-[#10aa00]"
                            >
                                My Account
                            </Link>
                            <div
                                onClick={(e) => {
                                    localStorage.removeItem('menu_faculty_id');
                                    handleLogout();
                                }}
                                className="flex cursor-pointer justify-center rounded border border-white px-2 py-1.5 text-white transition-colors hover:bg-white hover:text-[#10aa00]"
                            >
                                {loading && <Spinner />}
                                Sign Out
                            </div>
                            {user?.is_admin && (
                                <>
                                    <a
                                        href="/#/admin/dashboard"
                                        className="col-span-2 block rounded border border-white px-2 py-1.5 text-white transition-colors hover:bg-white hover:text-[#10aa00]"
                                    >
                                        Access Admin Dashboard
                                    </a>
                                </>
                            )}
                            {user?.is_admin && (
                                <>
                                    <div
                                        onClick={(e) =>
                                            setOpenSuggestModal(true)
                                        }
                                        className="col-span-2 block rounded border border-white px-2 py-1.5 text-white transition-colors hover:bg-white hover:text-[#10aa00]"
                                    >
                                        Suggest Improvements
                                    </div>
                                </>
                            )}

                            <div
                                className="col-span-2 px-2 py-1.5 text-left text-white"
                                style={{ fontSize: '0.8rem' }}
                            >
                                {copyright}
                            </div>
                        </div>
                    )}
                </div>
            </nav>
            <SuggestImprovementModal
                openModal={openSuggestModal}
                setOpenModal={setOpenSuggestModal}
            />
        </aside>
    );
};

export default Sidebar;
