import TopSectionProgram from './TopSectionProgram';
import {
    PencilSquareIcon,
    PlusIcon,
} from '@heroicons/react/24/outline/index.js';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import React, { useEffect, useState } from 'react';
import FilterProgram from './FilterProgram';
import { Link, useParams } from 'react-router-dom';
import { axiosPost } from '../../../services/axios';
import MostReferenceCopy from './partial/MostReferenceCopy';
import MostQuoteCopy from './partial/MostQuoteCopy';
import MostPopular from './partial/MostPopular';
import SearchTerms from './partial/SearchTerms';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SubjectsThemesPremisesSearch from './partial/SubjectsThemesPremisesSearch';
import SourcesSearch from './partial/SourcesSearch';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'transparent',
    color: 'white',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            <ArrowForwardIosSharpIcon
                sx={{ fontSize: '0.9rem', color: 'white' }}
            />
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .5)'
            : 'rgba(255, 255, 255, .13)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AntTabs = styled(Tabs)({
    borderBottom: 'none',
    '& .MuiTabs-indicator': {
        backgroundColor: '#ffffff',
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        fontSize: '16px',
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: 700,
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 0.55)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#ffffff',
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#ffffff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    })
);

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

export default function UserProgramPage({}) {
    const { id } = useParams();
    const [program, setProgram] = useState();
    const [tab, setTab] = React.useState(0);
    const [programId, setProgramId] = useState();

    const [subjectList, setSubjectList] = useState([]);
    const [countCategories, setCountCategories] = useState([]);
    const [sources, setSources] = useState([]);
    const [sourcesQuoted, setSourcesQuoted] = useState([]);
    const [mostPopular, setMostPopular] = useState([]);
    const [mostSearch, setMostSearch] = useState([]);

    const [sourceSort, setSourceSort] = useState('asc');

    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        getData();
        setProgramId(id);
    }, [id]);

    useEffect(() => {
        if (keyword != undefined) {
            getData();
        }
    }, [keyword]);

    useEffect(() => {
        setKeyword('');
    }, [tab]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    // Function to calculate sum of count values in themes array
    const sumCount = (themes) => {
        return themes.reduce((acc, theme) => acc + theme.premises_count, 0);
    };

    const getData = async () => {
        const params = {
            id,
            keyword,
            is_source: tab == 1 ? 1 : 0,
        };

        const ret = await axiosPost('/user/main/program', params);
        if (ret.status == 200) {
            const countCategories = ret.data.countCategories;
            const program = ret.data.program;
            const sources = ret.data.sources;
            const sourcesQuoted = ret.data.sources_quoted;
            const mostPopular = ret.data.most_popular;
            const mostSearch = ret.data.most_search;

            const subjects = ret.data.subjects;
            const sorted = [...subjects].sort(
                (a, b) => sumCount(b.themes) - sumCount(a.themes)
            );
            setCountCategories(countCategories);
            setProgram(program);
            setSources(sources);
            setSubjectList(sorted);
            setSourcesQuoted(sourcesQuoted);
            setMostPopular(mostPopular);
            setMostSearch(mostSearch);
        }
    };

    const [exparndIntro, setExpandIntro] = useState(true);

    const handleIntroChange = () => (event, newExpanded) => {
        setExpandIntro(newExpanded);
    };

    const opacity = 40;
    return (
        <div>
            <TopSectionProgram
                program={program}
                countCategories={countCategories}
            />

            <div className="mt-4">
                <Grid container spacing={2}>
                    <Grid item sm={12} className={'bg-opacity-' + opacity}>
                        <div
                            className={`bg-opacity-${opacity} rounded border border-white bg-black p-2 backdrop-blur`}
                        >
                            <Accordion
                                expanded={exparndIntro}
                                onChange={handleIntroChange()}
                            >
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <div className="m-2 mt-0 border-b border-b-white text-[16pt] font-bold text-white">
                                        {program?.intro_title}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div
                                        className="m-2 text-[14pt] text-white"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {program?.intro_description}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Grid>
                </Grid>

                <Box sx={{ width: '100%', marginTop: '10px' }}>
                    <div className="rounded border border-white bg-black bg-opacity-40 p-2 backdrop-blur">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <AntTabs
                                value={tab}
                                onChange={handleTabChange}
                                aria-label="ant example"
                                variant="fullWidth"
                            >
                                <AntTab label="Search Subjects, Themes and Premises" />
                                <AntTab label="Search by Sources" />
                            </AntTabs>
                        </Box>

                        <CustomTabPanel value={tab} index={0}>
                            <SubjectsThemesPremisesSearch
                                subjectList={subjectList}
                                sources={sources}
                                sourcesQuoted={sourcesQuoted}
                                mostPopular={mostPopular}
                                mostSearch={mostSearch}
                                setKeyword={setKeyword}
                                keyword={keyword}
                            />
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={1}>
                            <SourcesSearch programId={programId} />
                        </CustomTabPanel>
                    </div>
                </Box>
            </div>
        </div>
    );
}
