import React, { useState, useEffect, useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ToastContainer } from 'react-toastify';

export default function AdminGuestLayout() {
    const { user } = useAuth();

    // if user is logged in, redirect to profile page
    console.log(user);
    if (user != undefined) {
        return <Navigate to="/admin/dashboard" />;
    }
    return (
        <>
            <ToastContainer />

            <Outlet />
        </>
    );
}
