import React, { useEffect, useState } from 'react';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';

const schema = yup.object().shape({
    name: yup.string().required(),
    sort_order: yup.number().required().min(1),
    intro_title: yup.string().required(),
    intro_description: yup.string().required(),
});

const SearchTerms = ({ id }) => {
    const [mostSearch, setMostSearch] = useState([]);
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    useEffect(() => {
        // Clear previous timeout to avoid redundant API calls
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout to trigger search after 500ms (adjust as needed)
        const timeout = setTimeout(() => {
            getData();
        }, 1000); // Adjust delay time (in milliseconds) as needed

        // Update searchTimeout state to store the timeout ID
        setSearchTimeout(timeout);

        // Cleanup function to clear timeout when component unmounts or searchKey changes
        return () => clearTimeout(timeout);
    }, [page, rowsPerPage]);

    const getData = async () => {
        try {
            setLoading(true);
            const params = {
                id: id,
                page,
                pageSize: rowsPerPage,
            };

            const ret = await axiosPost(
                '/admin/program/getSearchTerms',
                params
            );
            setLoading(false);

            if (ret.status == 200) {
                const list = ret.data.data;
                const total = ret.data.total;
                setMostSearch(list);
                setTotal(total);
            } else {
                toast.error(ret.data.message);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {mostSearch.length > 0 && (
                <Box className="mb-2 mt-2">
                    <Typography variant="h6">Search Terms</Typography>
                </Box>
            )}

            {mostSearch.length > 0 && (
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Search Value</TableCell>
                                    <TableCell align="right">
                                        Search Count
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mostSearch.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.value}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.count}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </>
    );
};

export default SearchTerms;
