import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const schema = yup.object().shape({
    feedback_type: yup.string().required(),
    regarding: yup.string().required(),
    content: yup.string().required(),
});

const SuggestImprovementModal = ({ setOpenModal, openModal }) => {
    const { user } = useAuth();
    const location = useLocation();
    const { pathname } = location;

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            // description: item?.description || "",
            feedback_type: 'Current Page',
            regarding: 'Bug/Technical Issue',
            content: '',
        },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const onSubmit = async (_data) => {
        console.log('on submit', _data);

        _data['url'] = pathname;
        _data['user_id'] = user.id;

        let url = '/user/main/suggest/store';

        const ret = await axiosPost(url, _data);

        if (ret.status == 200) {
            const message = ret.data.message;
            toast.success(message);
            reset();
            setOpenModal(false);
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <DialogTitle> Suggest Improvements</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant="h6"
                                    sx={{ mb: 2 }}
                                ></Typography>
                                <div className="mb-3">
                                    <label>
                                        Page URL:{' '}
                                        <span className="ml-3">{pathname}</span>
                                    </label>
                                </div>

                                <Controller
                                    name="feedback_type"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>
                                                Is your suggestion regarding the
                                                page you are currently viewing?
                                            </label>

                                            <Select
                                                size="small"
                                                className="w-full"
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                <MenuItem
                                                    key={0}
                                                    value="Current Page"
                                                >
                                                    Current Page
                                                </MenuItem>
                                                <MenuItem
                                                    key={1}
                                                    value="System-Wide"
                                                >
                                                    System-Wide
                                                </MenuItem>
                                            </Select>
                                            {errors.feedback_type && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.feedback_type
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="regarding"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>
                                                What is your suggestion about?
                                            </label>

                                            <Select
                                                size="small"
                                                className="w-full"
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                <MenuItem
                                                    key={0}
                                                    value="Bug/Technical Issue"
                                                >
                                                    Bug/Technical Issue
                                                </MenuItem>
                                                <MenuItem
                                                    key={1}
                                                    value="Suggested Improvement"
                                                >
                                                    Suggested Improvement
                                                </MenuItem>
                                                <MenuItem
                                                    key={3}
                                                    value="Feature Request"
                                                >
                                                    Feature Request
                                                </MenuItem>
                                                <MenuItem
                                                    key={4}
                                                    value="Something Else"
                                                >
                                                    Something Else
                                                </MenuItem>
                                            </Select>
                                            {errors.regarding && (
                                                <span className="text-rose-500">
                                                    {errors.regarding.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="content"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>
                                                Please give details about your
                                                suggested improvements. Maximum
                                                500 characters.
                                            </label>

                                            <TextField
                                                id="outlined-error-helper-text"
                                                value={value}
                                                size="small"
                                                multiline
                                                rows={4}
                                                className="w-full"
                                                onChange={onChange}
                                            />
                                            {errors.content && (
                                                <span className="text-rose-500">
                                                    {errors.content.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Submit</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default SuggestImprovementModal;
