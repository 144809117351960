import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { axiosPost } from '../../../services/axios';
import Swal from 'sweetalert2';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward.js';
import MostReferenceCopy from '../Program/partial/MostReferenceCopy.jsx';
import MostQuoteCopy from '../Program/partial/MostQuoteCopy.jsx';
import { ArrowLeftIcon } from '@heroicons/react/24/outline/index.js';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { ViewColumnRounded, ViewHeadlineOutlined } from '@mui/icons-material';
import Counter from './Counter.jsx';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'transparent',
    color: 'white',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            <ArrowForwardIosSharpIcon
                sx={{ fontSize: '0.9rem', color: 'white' }}
            />
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .5)'
            : 'rgba(255, 255, 255, .13)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function UserFacultyPage({}) {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [faculty, setFaculty] = useState();
    const [programs, setPrograms] = useState([]);
    const [sources, setSources] = useState([]);
    const [expanded, setExpanded] = useState(-1);
    const [searchKey, setSearchKey] = useState('');
    const [counters, setCounters] = useState({});

    useEffect(() => {
        setLoading(true);
        debounceGetData(id, null);
    }, [id]);

    useEffect(() => {
        debounceGetData(id, searchKey);
    }, [searchKey]);

    const debounceGetData = useCallback(
        debounce(async (id, searchKey) => {
            const params = {
                id,
                searchKey: searchKey,
            };

            axiosPost('/user/main/faculty', params)
                .then((response) => {
                    const data = response.data;

                    setFaculty(data.faculty);
                    setPrograms(data.programs);
                    setSources(data.sources);
                    setCounters(data.counters);

                    setLoading(false);
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message,
                    });
                });
        }, 500),
        []
    );

    const [exparndIntro, setExpandIntro] = useState(true);

    const handleIntroChange = () => (event, newExpanded) => {
        setExpandIntro(newExpanded);
    };

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : -1);
    };

    const opacity = 40;
    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="mb-4 items-end justify-between md:flex">
                <div className="mr-4 flex-1">
                    <h1 className="text-4xl text-white">
                        <span className="font-bold">{faculty?.name}</span>
                    </h1>
                </div>

                <Counter counters={counters} />
            </div>

            <hr />

            <div className="my-4">
                {(faculty?.intro_title || faculty?.intro_description) && (
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={'bg-opacity-' + opacity}>
                            <div
                                className={`bg-opacity-${opacity} rounded border border-white bg-black p-2 backdrop-blur`}
                            >
                                <Accordion
                                    expanded={exparndIntro}
                                    onChange={handleIntroChange()}
                                >
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                    >
                                        <div className="m-2 mt-0 border-b border-b-white text-[16pt] font-bold text-white">
                                            {faculty?.intro_title}
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div
                                            className="m-2 text-[14pt] text-white"
                                            style={{ whiteSpace: 'pre-line' }}
                                        >
                                            {faculty?.intro_description}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </div>

            <div className={'flex w-full max-w-[500px] items-center gap-2'}>
                <div className={'rounded-md border border-white'}>
                    <IconButton onClick={() => window.history.back()}>
                        <ArrowLeftIcon className="h-6 w-6" />
                    </IconButton>
                </div>

                <TextField
                    type="text"
                    size="small"
                    InputProps={{
                        className: 'rounded mr-2 w-full bg-white',
                        style: { color: 'black' },
                    }}
                    placeholder="Search for Themes, Premises, Key Words…"
                    className="w-full"
                    onChange={(e) => setSearchKey(e.target.value)}
                    value={searchKey}
                />
            </div>

            <div className="mt-4 border-b border-b-white text-[14pt] font-bold text-white">
                Explore Faculty Programmes
            </div>

            <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item xs={12} lg={6}>
                    <div className={`grid grid-cols-1 gap-5`}>
                        {programs.map((program, index) => (
                            <div
                                className={`bg-opacity-${opacity} rounded border border-white bg-black px-2 py-2 backdrop-blur`}
                                key={index}
                            >
                                <div
                                    className={`rounded bg-[rgba(255,255,255,.5)] p-4 font-bold`}
                                >
                                    <Link to={`/programme/${program.id}`}>
                                        {program?.name} (
                                        {program?.subjects_count})
                                    </Link>
                                </div>
                                <div>
                                    {program?.subjects.map((subject, index) => (
                                        <Link
                                            to={`/subject/${subject.id}`}
                                            key={index}
                                            className="my-2 flex cursor-pointer justify-between gap-1 rounded bg-white bg-opacity-20 p-2"
                                        >
                                            <span>{subject.name}</span>
                                            <div
                                                className={
                                                    'flex min-w-max items-center gap-1 text-center text-xs font-extralight uppercase'
                                                }
                                            >
                                                <span>
                                                    {subject.themes_count} Theme
                                                    {subject.themes_count > 1
                                                        ? 's'
                                                        : ''}
                                                </span>
                                                <span>|</span>
                                                <span>
                                                    {subject.premises_count}{' '}
                                                    Premise
                                                    {subject.premises_count > 1
                                                        ? 's'
                                                        : ''}
                                                </span>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>

                <Grid item xs={12} lg={6} className={'space-y-5'}>
                    <MostReferenceCopy sources={sources} opacity={opacity} />
                    <MostQuoteCopy sources={sources} opacity={opacity} />
                </Grid>
            </Grid>
        </div>
    );
}
