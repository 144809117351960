import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import axios from '../services/axios';
import { useAuth } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from './Spinner';
import { Grid } from '@mui/material';

export default function Login({ changeCard }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { setUser, csrfToken } = useAuth();
    const [emailError, setEmailError] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');

    const changePage = (page) => {
        changeCard(page);
    };
    // login user
    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmailError('');
        setPasswordError('');
        setLoading(true);
        const { email, password } = e.target.elements;
        const body = {
            email: email.value,
            password: password.value,
        };
        await csrfToken();
        try {
            const resp = await axios.post('/login', body);
            setLoading(false);
            if (resp.status === 200) {
                setUser(resp.data.user);
                return <Navigate to="/welcome" />;
            }
        } catch (error) {
            setLoading(false);
            let resp = error.response;
            if (resp.status === 401) {
                console.log(resp.data.message);
                setEmailError(resp.data.message);
            }
            if (resp.status === 422) {
                if (resp.data.errors.email) {
                    setEmailError(resp.data.errors.email[0]);
                } else {
                    setEmailError('');
                }
                if (resp.data.errors.password) {
                    setPasswordError(resp.data.errors.password[0]);
                } else {
                    setPasswordError('');
                }
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item sm={5}>
                <section
                    style={{ minHeight: '100vh' }}
                    className="h-screen w-full overflow-auto px-4 pb-20 font-sans"
                >
                    <div id="back-to-top-anchor"></div>
                    <div className="mt-32 w-full px-3">
                        <div className="mx-auto mb-8">
                            <div>
                                <main className="flex flex-col items-center justify-center">
                                    <div className="w-full rounded-sm bg-transparent">
                                        <div className="flex flex-wrap items-center">
                                            <div className="w-full max-w-lg rounded-lg border-2 border-white bg-black bg-opacity-20 backdrop-blur-[20px]">
                                                <div className="w-full space-y-2 border-b border-white p-4 sm:p-8 sm:pb-2 xl:p-8 xl:pb-2">
                                                    <h2 className="mb-2 text-2xl font-bold text-white dark:text-white">
                                                        Welcome to SourceBank®
                                                    </h2>
                                                    <p className="mb-9 font-bold text-white dark:text-white">
                                                        The Literature Review
                                                        Resource for Serious
                                                        Academics.
                                                    </p>
                                                </div>
                                                <div className="w-full space-y-2 p-4 sm:p-8 sm:pt-2 xl:p-8 xl:pt-2">
                                                    <form
                                                        className="space-y-6"
                                                        action="#"
                                                        method="POST"
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <div>
                                                            <label
                                                                htmlFor="email"
                                                                className="block text-sm font-medium capitalize leading-6 text-white"
                                                            >
                                                                Email address
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    id="email"
                                                                    name="email"
                                                                    type="email"
                                                                    autoComplete="email"
                                                                    required
                                                                    className="w-full rounded-lg border border-white bg-transparent py-3 pl-6 pr-10 text-white outline-none focus:border-primary focus-visible:shadow-none"
                                                                />
                                                                {emailError && (
                                                                    <p className="text-sm text-danger">
                                                                        {
                                                                            emailError
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className="flex items-center justify-between">
                                                                <label
                                                                    htmlFor="password"
                                                                    className="block text-sm font-medium capitalize leading-6 text-white"
                                                                >
                                                                    Password
                                                                </label>
                                                                <div className="text-sm">
                                                                    <a
                                                                        href="#"
                                                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                                                    >
                                                                        {/* Forgot password? */}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2">
                                                                <input
                                                                    id="password"
                                                                    name="password"
                                                                    type="password"
                                                                    autoComplete="current-password"
                                                                    required
                                                                    className="w-full rounded-lg border border-white bg-transparent py-3 pl-6 pr-10 text-white outline-none focus:border-primary focus-visible:shadow-none"
                                                                />
                                                                {passwordError && (
                                                                    <p className="text-sm text-danger">
                                                                        {
                                                                            passwordError
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="">
                                                            <button
                                                                type="submit"
                                                                className="flex w-full cursor-pointer justify-center rounded-sm border border-white bg-white p-2 text-black transition hover:bg-opacity-90"
                                                            >
                                                                {loading && (
                                                                    <Spinner />
                                                                )}
                                                                Sign In
                                                            </button>
                                                        </div>
                                                    </form>
                                                    <div className="mt-6 flex justify-between">
                                                        <button
                                                            className="p-2 text-white"
                                                            onClick={(e) => {
                                                                navigate(
                                                                    'forgot-password'
                                                                );
                                                            }}
                                                        >
                                                            Reset Password
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <button
                                                className="mt-3 w-full max-w-lg rounded-sm border-white bg-white p-2 capitalize text-black"
                                                onClick={() =>
                                                    changePage('register')
                                                }
                                            >
                                                Create Account
                                            </button>

                                            <button
                                                className="mt-3 w-full max-w-lg rounded-sm border-white bg-white p-2 capitalize text-black"
                                                onClick={() =>
                                                    changePage('learn')
                                                }
                                            >
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </section>
            </Grid>
        </Grid>
    );
}
