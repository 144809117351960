import React, { useEffect, useState, useRef } from 'react';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    name: yup.string().required(),
});

export default function UpdateProfileInformation({
    user,
    mustVerifyEmail,
    status,
    refresh,
}) {
    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: user?.name,
            email: user?.email,
        },

        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (user) {
            const setValues = {
                name: user?.name,
                email: user?.email,
            };
            reset(setValues);
        }
    }, [user]);

    const onSubmit = async (data) => {
        let url = '/user/profile/updateProfile';

        const ret = await axiosPost(url, data);
        if (ret.status == 200) {
            toast.success(ret.data.message);
            refresh();
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <section className="">
            <header>
                <h2 className="text-lg font-medium">Profile Information</h2>

                <p className="mt-1 text-sm">
                    Update your account's profile information and email address.
                </p>
            </header>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-6 space-y-6">
                <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                        <div className="mb-3 w-full">
                            <label>Name</label>
                            <TextField
                                size="small"
                                className="mt-1 w-full"
                                value={value}
                                onChange={onChange}
                            />
                            {errors.name && (
                                <span className="text-rose-500">
                                    {errors.name.message}
                                </span>
                            )}
                        </div>
                    )}
                />

                <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                        <div className="mb-3 w-full">
                            <label>Email</label>
                            <TextField
                                size="small"
                                className="mt-1 w-full"
                                value={value}
                                onChange={onChange}
                            />
                            {errors.quote && (
                                <span className="text-rose-500">
                                    {errors.quote.message}
                                </span>
                            )}
                        </div>
                    )}
                />

                {mustVerifyEmail && user?.email_verified_at === null && (
                    <div>
                        <p className="mt-2 text-sm">
                            Your email address is unverified.
                            <Link
                                // href={route('verification.send')}
                                method="post"
                                as="button"
                                className="ml-1 rounded-md text-sm underline hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Click here to re-send the verification email.
                            </Link>
                        </p>

                        {status === 'verification-link-sent' && (
                            <div className="mt-2 text-sm font-medium text-green-600">
                                A new verification link has been sent to your
                                email address.
                            </div>
                        )}
                    </div>
                )}

                <div className="flex items-center gap-4">
                    <Button variant="contained" type="submit">
                        Save
                    </Button>
                </div>
            </form>
        </section>
    );
}
