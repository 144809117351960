import React, { useEffect, useState } from 'react';
import { FormControl, Modal, TextField } from '@mui/material';
import { axiosPost } from '@/services/axios';
import { toast } from 'react-toastify';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

export default function DeleteModal({ openModal, setOpenModal, refresh, ids }) {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const onChangePassword = (e) => {
        setPassword(e);
        setError(false);
    };

    const deleteAccount = async () => {
        if (password == '') {
            setError(true);
            return;
        }

        if (ids.length > 0) {
            const res = await axiosPost('/admin/suggest/delete', {
                id: ids,
                password,
            });
            if (res.status == 200) {
                refresh();
                setOpenModal(false);
            }
            if (res.status == 201) {
                toast.warn(res.data.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        }
    };

    const handleClose = () => {
        setOpenModal(false);
        setPassword('');
    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="flex min-h-screen items-center px-4 py-8">
                    <div className="relative mx-auto w-full max-w-lg rounded-md bg-white p-4 text-black shadow-lg dark:bg-gray-700 dark:text-white">
                        <div className="mt-3 sm:flex">
                            <div className="mx-auto flex h-12 w-12 flex-none items-center justify-center rounded-full">
                                <ExclamationTriangleIcon className="text-red-600" />
                            </div>
                            <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                <h4 className="text-lg font-medium">
                                    Are You Sure ?
                                </h4>
                                <p className="mt-2 text-[15px] leading-relaxed">
                                    Do you want to delete {ids.length}{' '}
                                    suggestion(s) ? This action can not be
                                    un-done.
                                </p>
                                <div className="mt-3">
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={password}
                                            onChange={(e) =>
                                                onChangePassword(e.target.value)
                                            }
                                            error={error}
                                            label={'Password'}
                                            type="password"
                                        />
                                    </FormControl>
                                </div>
                                <div className="mt-3 items-center gap-2 sm:flex">
                                    <button
                                        className="mt-2 w-full flex-1 rounded-md bg-danger p-2.5 text-white outline-none ring-red-600 ring-offset-2 focus:ring-2"
                                        onClick={deleteAccount}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        className="mt-2 w-full flex-1 rounded-md border border-gray-500 p-2.5 outline-none ring-indigo-600 ring-offset-2 focus:ring-2"
                                        onClick={() => handleClose()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
