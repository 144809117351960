import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
export default function UploadModal({ setOpenModal, reloadTable, setLoading }) {
    const [szSuccess, setSzSuccess] = useState(0);
    const [szUpdate, setSzUpdate] = useState(0);
    const [szFail, setSzFail] = useState(0);
    const [szTotal, setSzTotal] = useState(null);

    const [fileList, setFileList] = useState(null);
    const [shouldHighlight, setShouldHighlight] = useState(false);

    const [processing, setProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const preventDefaultHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        if (szTotal != undefined) {
            toast.success(<UploadMessage />, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    }, [szTotal]);

    const handleUpload = async () => {
        // const UPLOAD_URL = "YOUR URL HERE";

        const UPLOAD_URL = '/api/admin/premise/import';
        const data = new FormData();
        // for (let file of fileList) {
        //     data.append('file', file);
        // }
        data.append('file', fileList[0]);

        try {
            setLoading(true);
            setProcessing(true);
            const ret = await axios.post(UPLOAD_URL, data, {
                onUploadProgress(e) {
                    const progress = e.progress ?? 0;
                    console.log(e);
                    setProgress(progress * 100);
                    if (progress * 100 >= 100) {
                        setFileList(null);
                    }
                },
            });
            setLoading(false);
            setProcessing(false);
            if (ret.status == 200) {
                const _fail = ret.data.fail;
                setSzFail(_fail);
                setSzTotal(Math.random());
                reloadTable();
                setTimeout(() => {
                    setOpenModal(false);
                }, 500);
            }
        } catch (err) {
            setLoading(false);
            setProcessing(false);
            toast.error(err.response.data.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        }
    };
    const UploadMessage = () => {
        return (
            <div>
                <span style={{ fontWeight: 'bold' }}> Records Failed</span>:{' '}
                {szFail}
            </div>
        );
    };
    return (
        <>
            <div
                className={classNames({
                    'fixed inset-0 z-9999 overflow-y-auto': true,
                    'pointer-events-none': processing,
                })}
            >
                <div className="fixed inset-0 h-full w-full bg-black opacity-40"></div>
                <div className="flex min-h-screen items-center px-4 py-8">
                    <div className="relative mx-auto w-full max-w-lg rounded-md bg-white p-4 shadow-lg">
                        <div className="mt-3 text-black">
                            <div className="mt-2 space-y-2 sm:text-left">
                                <h4 className="text-center text-lg font-bold">
                                    Upload Premises
                                </h4>
                                <div
                                    className={classNames({
                                        relative: true,
                                        'h-70 w-full': true,
                                        'grid cursor-pointer place-content-center p-4': true,
                                        'bg-white-200 rounded-lg text-black hover:bg-sky-50': true,
                                        'border-2 border-dashed border-gray-200 hover:border-gray-500': true,
                                        'transition-colors': true,
                                        'border-gray-500 bg-sky-50':
                                            shouldHighlight,
                                        'bg-white-200 border-gray-200':
                                            !shouldHighlight,
                                    })}
                                    onDragOver={(e) => {
                                        preventDefaultHandler(e);
                                        setShouldHighlight(true);
                                    }}
                                    onDragEnter={(e) => {
                                        preventDefaultHandler(e);
                                        setShouldHighlight(true);
                                    }}
                                    onDragLeave={(e) => {
                                        preventDefaultHandler(e);
                                        setShouldHighlight(false);
                                    }}
                                    onDrop={(e) => {
                                        preventDefaultHandler(e);
                                        const files = Array.from(
                                            e.dataTransfer.files
                                        );
                                        const acceptType = [
                                            'application/vnd.ms-excel',
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                            'csv',
                                            'text/csv',
                                        ];
                                        let tmp = [];
                                        if (files.length > 0) {
                                            const element = files[0];
                                            if (
                                                acceptType.includes(
                                                    element.type
                                                )
                                            ) {
                                                tmp.push(element);
                                            }
                                        }
                                        // files.forEach(element => {
                                        //     if (acceptType.includes(element.type)) {
                                        //         tmp.push(element)
                                        //     };
                                        // });
                                        setFileList(tmp);
                                        setShouldHighlight(false);
                                        console.log(files);
                                    }}
                                >
                                    <div className="z-100 flex flex-col items-center">
                                        {!fileList ? (
                                            <>
                                                <CloudArrowUpIcon className="h-10 w-10" />
                                                <span>
                                                    <span>Choose a File</span>{' '}
                                                    or drag it here
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <p>Files to Upload</p>
                                                {fileList.map((file, i) => {
                                                    return (
                                                        <span key={i}>
                                                            {file.name}
                                                        </span>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={(e) => {
                                            const files = Array.from(
                                                e.target.files
                                            );
                                            setFileList(files);
                                            setShouldHighlight(false);
                                            console.log(files);
                                        }}
                                        className="absolute inset-0 z-10 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                                    />
                                </div>

                                <div className="mt-3 flex items-center gap-2">
                                    <button
                                        className={classNames({
                                            'mt-2 w-full flex-1 bg-primary p-2.5 text-white': true,
                                            'rounded-md outline-none ring-red-600 ring-offset-2 focus:ring-2': true,
                                            'pointer-events-none opacity-40':
                                                processing,
                                        })}
                                        onClick={() => handleUpload()}
                                    >
                                        {processing && (
                                            <div className="relative">
                                                <div className="absolute left-6 h-6 w-6 animate-spin rounded-full border-x-4 border-solid border-white border-t-transparent"></div>
                                            </div>
                                        )}
                                        Upload
                                    </button>
                                    <button
                                        className="mt-2 w-full flex-1 rounded-md border p-2.5 text-black outline-none ring-indigo-600 ring-offset-2 focus:ring-2"
                                        onClick={() => {
                                            setFileList(null);
                                            setOpenModal(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
