import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { fetchImage } from '@/services/axios';

const UploadImage = ({ imageUrl, type, height = 200 }) => {
    const [fileList, setFileList] = useState([]);
    const [shouldHighlight, setShouldHighlight] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (imageUrl != undefined) {
            LoadImage();
        }
    }, [imageUrl]);

    const LoadImage = async () => {
        let _preview = await fetchImage(imageUrl);
        setPreviewImage(_preview);
    };

    useEffect(() => {
        if (fileList.length > 0) {
            updateIamge();
        }
    }, [fileList]);

    const updateIamge = async () => {
        const data = new FormData();
        data.append('file', fileList[0]);
        data.append('type', type);
        const UPLOAD_URL = '/api/admin/setting/uploadImage';
        const ret = await axios.post(UPLOAD_URL, data);
        if (ret.status == 200) {
            toast.success('Saved Successfully!');
        }
    };

    const vheight = `h-[${height}px]`;
    return (
        <div className="mt-1">
            <div
                className={classNames({
                    relative: true,
                    'w-full': true,
                    'min-h-[100px]': true,
                    'grid cursor-pointer place-content-center': true,
                    'bg-white-200 rounded-lg text-black hover:bg-sky-50 hover:text-black dark:text-white dark:hover:text-black': true,
                    'border-2 border-dashed': true,
                    'transition-colors': true,
                    'bg-sky-50': shouldHighlight,
                    'bg-white-200': !shouldHighlight,
                    'border-danger': errors.file,
                    'border-gray-400': !errors.file,
                })}
                // style={{ height: `${vheight}px` }}
                onDragOver={(e) => {
                    preventDefaultHandler(e);
                    setShouldHighlight(true);
                }}
                onDragEnter={(e) => {
                    preventDefaultHandler(e);
                    setShouldHighlight(true);
                }}
                onDragLeave={(e) => {
                    preventDefaultHandler(e);
                    setShouldHighlight(false);
                }}
                onDrop={(e) => {
                    preventDefaultHandler(e);
                    const files = Array.from(e.dataTransfer.files);
                    const acceptType = ['image/*'];
                    let tmp = [];
                    files.forEach((element) => {
                        if (acceptType.includes(element.type)) {
                            tmp.push(element);
                        }
                    });
                    if (tmp.length > 0) {
                        let tmpPreviewImage = URL.createObjectURL(tmp[0]);
                        setPreviewImage(tmpPreviewImage);
                    }
                    setFileList(tmp);
                    setShouldHighlight(false);
                }}
            >
                <div className="z-100 flex flex-col items-center">
                    {previewImage && (
                        <div>
                            <img
                                className={`object-contain`}
                                src={previewImage}
                                alt=""
                            />
                        </div>
                    )}
                </div>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        const files = Array.from(e.target.files);
                        let tmpPreviewImage = URL.createObjectURL(
                            e.target.files[0]
                        );
                        setPreviewImage(tmpPreviewImage);
                        setFileList(files);
                        setShouldHighlight(false);
                    }}
                    className="absolute inset-0 z-10 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                />
            </div>
        </div>
    );
};

export default UploadImage;
