import { useEffect, useContext } from 'react';
import useLocalStorage from './useLocalStorage';
import { ColorModeContext } from '../App';
const useColorMode = () => {
    const [colorMode, setColorMode] = useLocalStorage('color-theme', 'dark');
    const _colorMode = useContext(ColorModeContext);

    useEffect(() => {
        const className = 'dark';
        const bodyClass = window.document.body.classList;

        colorMode === 'dark'
            ? bodyClass.add(className)
            : bodyClass.remove(className);
        _colorMode.toggleColorMode(colorMode);
    }, [colorMode]);

    return [colorMode, setColorMode];
};

export default useColorMode;
