import React, { useEffect, useState, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Navigate, Link } from 'react-router-dom';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../context/AuthContext';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import GroupDetail from './GroupDetail';
import { toast } from 'react-toastify';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'transparent',
    color: 'white',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            <ArrowForwardIosSharpIcon
                sx={{ fontSize: '0.9rem', color: 'white' }}
            />
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .5)'
            : 'rgba(255, 255, 255, .13)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const schema = yup.object().shape({
    name: yup.string().required(),
});

export default function MySourceBankPage() {
    const [refresh, setRefresh] = useState(0);

    const [expanded, setExpanded] = useState(-1);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : -1);
    };

    const [intro_list, setIntroList] = useState('');
    const { user } = useAuth();

    const setting = useSelector((state) => state.setting);

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: '',
        },

        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        console.log('on submit', data);
        let url = '/user/group/create';

        const ret = await axiosPost(url, data);
        if (ret.status == 200) {
            toast.success(ret.data.message);
            reset();
            setRefresh(Math.random());
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <div className="">
            {/* <!-- Hero --> */}
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <section className="w-full overflow-auto pb-2 font-sans">
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <div className="rounded border border-white bg-white bg-opacity-25 p-2 backdrop-blur">
                                    <div className="m-2 text-[18pt] font-bold text-white">{`Welcome to your personal SourceBank, ${user?.name}`}</div>

                                    <div
                                        className="m-2 text-[12pt] text-white"
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {setting?.user_list_intro}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <div className="mt-4 rounded border border-white bg-white bg-opacity-25 p-4 backdrop-blur">
                            <div className="mb-2 text-[18pt] font-bold text-white">
                                Create a New SourceBank List
                            </div>
                            <div className="mb-3 mt-3">
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    className="flex"
                                >
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <div className="mb-3 flex flex-1">
                                                <label className="mr-3">
                                                    Give your SourceBank List a
                                                    Name:
                                                </label>
                                                <TextField
                                                    size="small"
                                                    className="mt-1 w-full"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                                {errors.name && (
                                                    <span className="text-rose-500">
                                                        {errors.name.message}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                    <div className="space-y-6">
                                        <div className="flex items-center justify-end gap-4">
                                            <button
                                                className="ml-3 rounded border border-white bg-white px-2 py-1.5 text-[#10aa00] transition-colors hover:bg-[#10aa00] hover:text-white"
                                                type="submit"
                                            >
                                                Create New List
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            <Grid item sm={12}>
                                <div className="rounded border border-white bg-white bg-opacity-25 p-4 text-white backdrop-blur">
                                    <GroupDetail refresh={refresh} />
                                </div>
                            </Grid>
                        </Grid>
                    </section>
                </Grid>
            </Grid>
        </div>
    );
}
