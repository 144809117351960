import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';

import axios, { axiosPost } from '../services/axios';
import { useAuth } from '../context/AuthContext';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import TermsModal from './signup/TermsModal';
import Spinner from './Spinner';

export default function Signup({ changeCard }) {
    const setting = useSelector((state) => state.setting);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const [checked, setChecked] = useState(false);
    const { setUser, csrfToken } = useAuth();
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [emailConfirmError, setEmailConfirmError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [package_id, setPackageID] = useState('');
    const [packageIdError, setPackageIdError] = useState('');

    const [packages, setPackages] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPackages();
    }, []);

    const handleChange = (e) => {
        const checked = e.target.checked;
        setChecked(checked);
    };

    const getPackages = async () => {
        const ret = await axiosPost('/getPackages');
        if (ret.status == 200) {
            const packages = ret.data.data;
            setPackages(packages);
        }
    };

    const changePage = (page) => {
        changeCard(page);
    };

    // register user
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, cemail, password, cpassword } = e.target.elements;

        const fullUrl = `${window.location.origin}`;

        const body = {
            name: name.value,
            email: email.value,
            email_confirmation: cemail.value,
            password: password.value,
            password_confirmation: cpassword.value,
            package_id: package_id,
            fullUrl: fullUrl,
            // payment_method_id: paymentMethod?.id,
        };
        try {
            setLoading(true);
            const ret = await axios.post('/register', body);
            setLoading(false);
            if (ret.status === 200) {
                const url = ret.data.url;
                window.location.href = url;
            } else if (ret.status == 201) {
                // free
                const url = ret.data.url;
                window.location.href = url;
                toast.success('Email verification sent');
            }
        } catch (error) {
            setLoading(false);
            const resp = error.response;
            if (resp.status === 422) {
                if (resp.data.errors.name) {
                    setNameError(resp.data.errors.name[0]);
                } else {
                    setNameError('');
                }
                if (resp.data.errors.email) {
                    setEmailError(resp.data.errors.email[0]);
                } else {
                    setEmailError('');
                }
                if (resp.data.errors.password) {
                    setPasswordError(resp.data.errors.password[0]);
                } else {
                    setPasswordError('');
                }
            }
        }
    };

    const openTerms = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setTitle('Terms');
        setContent(setting?.terms ?? '');
        setOpenModal(true);
    };

    const openPrivacy = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setTitle('Privacy');
        setContent(setting?.privacy ?? '');
        setOpenModal(true);
    };

    return (
        <>
            <form className="" action="#" method="POST" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item sm={5}>
                        <section className="w-full overflow-auto px-4 font-sans md:h-screen md:pb-20">
                            <div id="back-to-top-anchor"></div>
                            <div className="mt-32 w-full px-3">
                                <div className="mx-auto mb-8">
                                    <div className="w-full bg-transparent">
                                        <div className="">
                                            <div
                                                className="w-full max-w-lg rounded-lg border-2 border-white bg-black bg-opacity-20"
                                                style={{
                                                    backdropFilter:
                                                        'blur(20px)',
                                                }}
                                            >
                                                <div className="w-full space-y-2 border-b border-white p-4 sm:p-8 sm:pb-2 xl:p-8 xl:pb-2">
                                                    <h2 className="mb-2 text-2xl font-bold text-white dark:text-white">
                                                        Welcome to SourceBank®
                                                    </h2>
                                                    <p className="mb-9 font-bold text-white dark:text-white">
                                                        The Literature Review
                                                        Resource for Serious
                                                        Academics.
                                                    </p>
                                                </div>
                                                <div className="w-full space-y-2 p-4 sm:p-8 sm:pt-2 xl:p-8 xl:pt-2">
                                                    <div>
                                                        <label
                                                            htmlFor="email"
                                                            className="block text-sm font-medium leading-6 text-white"
                                                        >
                                                            Name
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="name"
                                                                name="name"
                                                                type="text"
                                                                required
                                                                className="w-full rounded-lg border border-stroke bg-transparent py-1 pl-2 pr-10 text-white outline-none focus:border-primary focus-visible:shadow-none"
                                                            />
                                                            {nameError && (
                                                                <p className="text-sm text-danger">
                                                                    {nameError}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label
                                                            htmlFor="email"
                                                            className="block text-sm font-medium leading-6 text-white"
                                                        >
                                                            Email address
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="email"
                                                                name="email"
                                                                type="email"
                                                                autoComplete="email"
                                                                required
                                                                className="w-full rounded-lg border border-stroke bg-transparent py-1 pl-2 pr-10 text-white outline-none focus:border-primary focus-visible:shadow-none"
                                                            />
                                                            {emailError && (
                                                                <p className="text-sm text-danger">
                                                                    {emailError}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label
                                                            htmlFor="email"
                                                            className="block text-sm font-medium leading-6 text-white"
                                                        >
                                                            Confirm Email
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                id="cemail"
                                                                name="cemail"
                                                                type="email"
                                                                autoComplete="cemail"
                                                                required
                                                                className="w-full rounded-lg border border-stroke bg-transparent py-1 pl-2 pr-10 text-white outline-none focus:border-primary focus-visible:shadow-none"
                                                            />
                                                            {emailConfirmError && (
                                                                <p className="text-sm text-danger">
                                                                    {
                                                                        emailConfirmError
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className="flex items-center justify-between">
                                                            <label
                                                                htmlFor="password"
                                                                className="block text-sm font-medium leading-6 text-white"
                                                            >
                                                                Password
                                                            </label>
                                                        </div>
                                                        <div className="mt-2">
                                                            <input
                                                                id="password"
                                                                name="password"
                                                                type="password"
                                                                autoComplete="current-password"
                                                                required
                                                                className="w-full rounded-lg border border-stroke bg-transparent py-1 pl-2 pr-10 text-white outline-none focus:border-primary focus-visible:shadow-none"
                                                            />
                                                            {passwordError && (
                                                                <p className="text-sm text-danger">
                                                                    {
                                                                        passwordError
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className="flex items-center justify-between">
                                                            <label
                                                                htmlFor="password"
                                                                className="block text-sm font-medium leading-6 text-white"
                                                            >
                                                                Confirm Password
                                                            </label>
                                                        </div>
                                                        <div className="mt-2">
                                                            <input
                                                                name="cpassword"
                                                                id="cpassword"
                                                                type="password"
                                                                autoComplete="current-password"
                                                                required
                                                                className="w-full rounded-lg border border-stroke bg-transparent py-1 pl-2 pr-10 text-white outline-none focus:border-primary focus-visible:shadow-none"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <button
                                                className="mt-3 w-full max-w-lg cursor-pointer rounded-md border border-white bg-white p-2 text-black transition hover:bg-opacity-90"
                                                onClick={() =>
                                                    changePage('login')
                                                }
                                            >
                                                Sign In
                                            </button>

                                            <button
                                                className="mt-3 w-full max-w-lg cursor-pointer rounded-md border border-white bg-white p-2 capitalize text-black transition hover:bg-opacity-90"
                                                onClick={() =>
                                                    changePage('learn')
                                                }
                                            >
                                                Learn more
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Grid>
                    <Grid item sm={6}>
                        <div className="pt-32">
                            <h3 className="mb-3 text-xl font-bold capitalize text-white">
                                Choose your subscription package
                            </h3>

                            <div className="h-[50vh] overflow-y-auto overflow-x-hidden p-2">
                                <Grid container spacing={3}>
                                    {packages.map(
                                        ({ id, name, price, term }) => {
                                            const isSelected =
                                                id === package_id;

                                            return (
                                                <Grid item sm={12} key={id}>
                                                    <div
                                                        key={id}
                                                        className={
                                                            'animate-slideIn cursor-pointer rounded border-2 border-white bg-black bg-opacity-40 p-5 text-center text-white backdrop-blur' +
                                                            (isSelected &&
                                                                ' border-indigo-500 focus:ring-indigo-500')
                                                        }
                                                        onClick={() =>
                                                            setPackageID(id)
                                                        }
                                                    >
                                                        <p className="mb-4 text-lg font-bold">
                                                            {name}
                                                        </p>
                                                        <p className="mb-3 text-4xl font-bold">
                                                            £{price}
                                                        </p>
                                                        <p>{term} days</p>
                                                        <button
                                                            type="button"
                                                            className={
                                                                'mt-4 rounded border border-white px-2 py-1 ' +
                                                                (isSelected &&
                                                                    'border-indigo-500 bg-indigo-500')
                                                            }
                                                        >
                                                            {isSelected
                                                                ? 'Selected'
                                                                : 'Select'}
                                                        </button>
                                                    </div>
                                                </Grid>
                                            );
                                        }
                                    )}
                                </Grid>
                                {packageIdError && (
                                    <p className="mt-2">{packageIdError}</p>
                                )}
                            </div>

                            <div className="mt-3">
                                <button
                                    className="mr-5 p-2 text-white underline"
                                    onClick={(e) => {
                                        openTerms(e);
                                    }}
                                >
                                    Terms
                                </button>
                                <button
                                    className="mr-5 p-2 text-white underline"
                                    onClick={(e) => {
                                        openPrivacy(e);
                                    }}
                                >
                                    Privacy
                                </button>
                            </div>
                            <div className="ml-2 mr-4 mt-3 rounded-lg border border-white bg-black bg-opacity-40 p-2">
                                <FormControlLabel
                                    className="text-white"
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={
                                        setting?.register_checkbox_text ?? ''
                                    }
                                />
                            </div>

                            <div className="mb-10 flex justify-end md:mb-2">
                                <button
                                    type="submit"
                                    disabled={!checked || loading}
                                    className="ml-2 mr-4 mt-3 flex w-full max-w-lg cursor-pointer justify-center rounded-md border border-white bg-white p-2 text-black transition hover:bg-opacity-90 disabled:cursor-not-allowed disabled:bg-opacity-50"
                                >
                                    {loading && <Spinner />}
                                    Create Account
                                </button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </form>
            {openModal && (
                <TermsModal
                    title={title}
                    content={content}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
            )}
        </>
    );
}
