import React, { useState, useEffect, useRef } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { axiosPost } from '@/services/axios';
import dayjs from 'dayjs';
import UploadImage from './UploadImage';
import RichEditor from '../../../component/richeditor/RichEditor';

const schema = yup.object().shape({
    email: yup.string().email(),
});

const SettingPage = () => {
    const [loading, setLoading] = useState(false);
    const [setting, setSetting] = useState();

    // Terms
    const termsRef = useRef(null);

    const onTermsRefUpdate = (ref) => {
        termsRef.current = ref;
    };

    const getTerms = () => {
        if (termsRef.current.current) {
            return termsRef.current.current?.editor?.getHTML() ?? '';
        }
        return '';
    };

    // privacy
    const privacyRef = useRef(null);

    const onPrivacyRefUpdate = (ref) => {
        privacyRef.current = ref;
    };

    const getPrivacy = () => {
        if (privacyRef.current.current) {
            return privacyRef.current.current?.editor?.getHTML() ?? '';
        }
        return '';
    };

    // learn
    const learnRef = useRef(null);

    const onLearnRefUpdate = (ref) => {
        learnRef.current = ref;
    };

    const getLearn = () => {
        if (learnRef.current.current) {
            return learnRef.current.current?.editor?.getHTML() ?? '';
        }
        return '';
    };

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            copyright: '',
            email: '',
            sender: '',
            mail_host: '',
            mail_port: '',
            mail_username: '',
            mail_password: '',
            mail_encrypt: '',
            stripe_key: '',
            stripe_secret: '',
            user_list_intro: '',
            register_checkbox_text: '',
        },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        try {
            const ret = await axiosPost('/admin/setting/getData');
            if (ret.status == 200) {
                const data = ret.data.data;
                setSetting(data);

                const setValues = {
                    copyright: data?.copyright ? data?.copyright : '',
                    email: data?.email ? data?.email : '',
                    sender: data?.sender ? data?.sender : '',
                    mail_host: data?.mail_host ? data?.mail_host : '',
                    mail_port: data?.mail_port ? data?.mail_port : '',
                    mail_username: data?.mail_username
                        ? data?.mail_username
                        : '',
                    mail_password: data?.mail_password
                        ? data?.mail_password
                        : '',
                    mail_encrypt: data?.mail_encrypt ? data?.mail_encrypt : '',
                    stripe_key: data?.stripe_key ? data?.stripe_key : '',
                    stripe_secret: data?.stripe_secret
                        ? data?.stripe_secret
                        : '',
                    user_list_intro: data?.user_list_intro
                        ? data?.user_list_intro
                        : '',
                    register_checkbox_text: data?.register_checkbox_text
                        ? data?.register_checkbox_text
                        : '',
                };
                reset(setValues);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onSubmit = async (_data) => {
        const _terms = getTerms();
        const _privacy = getPrivacy();
        const _learn = getLearn();

        _data['learn'] = _learn;
        _data['privacy'] = _privacy;
        _data['terms'] = _terms;

        let url = '/admin/setting/update';
        const ret = await axiosPost(url, _data);
        if (ret.status == 200) {
            toast.success(ret.data.message);
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative mx-auto max-w-screen-lg p-4 dark:bg-transparent">
                <div className="flex justify-between">
                    <Typography variant="h5">Settings</Typography>
                </div>
                <div className="">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                            <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                                <Typography variant="h6">
                                    Front-End Branding
                                </Typography>
                            </Box>
                            <Box className="p-5 px-5">
                                <Grid container spacing={2}>
                                    <Grid item sm={6}>
                                        <label>Logo Image</label>
                                        <UploadImage
                                            imageUrl={setting?.logo_img}
                                            type="logo"
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <label>Main bg image</label>
                                        <UploadImage
                                            imageUrl={setting?.main_bg_image}
                                            type="main"
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Controller
                                            name="copyright"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>Copyright</label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.copyright && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors.copyright
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Controller
                                            name="register_checkbox_text"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>
                                                        Package Description
                                                    </label>
                                                    <TextField
                                                        multiline
                                                        rows={2}
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.register_checkbox_text && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors
                                                                    .register_checkbox_text
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                            <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                                <Typography variant="h6">
                                    Mailer Settings
                                </Typography>
                            </Box>
                            <Box className="p-5 px-5">
                                <Grid container spacing={2}>
                                    <Grid item sm={6}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>System Email</label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.email && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors.email
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <Controller
                                            name="sender"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>Sender Name</label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.sender && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors.sender
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <Controller
                                            name="mail_host"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>Mail host</label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.mail_host && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors.mail_host
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <Controller
                                            name="mail_port"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>Mail port</label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.mail_port && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors.mail_port
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <Controller
                                            name="mail_username"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>Mail username</label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.mail_username && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors
                                                                    .mail_username
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <Controller
                                            name="mail_password"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>Mail password</label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.mail_password && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors
                                                                    .mail_password
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <Controller
                                            name="mail_encrypt"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>Mail encrypt</label>
                                                    <TextField
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.mail_encrypt && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors
                                                                    .mail_encrypt
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                            <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                                <Typography variant="h6">
                                    Stripe Integration Settings
                                </Typography>
                            </Box>
                            <Box className="p-5 px-5">
                                <Grid container spacing={2}>
                                    <Grid item sm={6}>
                                        <Controller
                                            name="stripe_key"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>Stripe Key</label>
                                                    <TextField
                                                        type="password"
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.stripe_key && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors
                                                                    .stripe_key
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Controller
                                            name="stripe_secret"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>Stripe Secret</label>
                                                    <TextField
                                                        type="password"
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.stripe_secret && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors
                                                                    .stripe_secret
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                            <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                                <Typography variant="h6">
                                    User Lists Page
                                </Typography>
                            </Box>
                            <Box className="p-5 px-5">
                                <Grid container spacing={2}>
                                    <Grid item sm={6}>
                                        <Controller
                                            name="user_list_intro"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { value, onChange },
                                            }) => (
                                                <div className="mb-3 w-full">
                                                    <label>
                                                        User List Intro
                                                    </label>
                                                    <TextField
                                                        multiline
                                                        rows={8}
                                                        size="small"
                                                        className="mt-1 w-full"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                    {errors.user_list_intro && (
                                                        <span className="text-rose-500">
                                                            {
                                                                errors
                                                                    .user_list_intro
                                                                    .message
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                            <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                                <Typography variant="h6">
                                    Learn More Content
                                </Typography>
                            </Box>
                            <Box className="p-5 px-5">
                                <Grid container spacing={2}>
                                    <Grid item sm={12} className="rich-content">
                                        {setting && (
                                            <RichEditor
                                                content={setting?.learn}
                                                onInputRefUpdate={
                                                    onLearnRefUpdate
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                            <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                                <Typography variant="h6">Terms</Typography>
                            </Box>
                            <Box className="p-5 px-5">
                                <Grid container spacing={2}>
                                    <Grid item sm={12} className="rich-content">
                                        {setting && (
                                            <RichEditor
                                                content={setting?.terms}
                                                onInputRefUpdate={
                                                    onTermsRefUpdate
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                            <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                                <Typography variant="h6">Privacy</Typography>
                            </Box>
                            <Box className="p-5 px-5">
                                <Grid container spacing={2}>
                                    <Grid item sm={12} className="rich-content">
                                        {setting && (
                                            <RichEditor
                                                content={setting?.privacy}
                                                onInputRefUpdate={
                                                    onPrivacyRefUpdate
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box className="mt-5 flex justify-end">
                            <Button
                                variant="contained"
                                type="submit"
                                color="warning"
                                style={{
                                    color: 'white',
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SettingPage;
