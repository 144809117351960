import React, { useEffect } from 'react';
import { Zoom, Fab } from '@mui/material';
import { ArrowUpIcon } from '@heroicons/react/24/outline';

const ScrollToTop = ({ trigger }) => {
    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor'
        );
        console.log(anchor);
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div
                onClick={handleClick}
                role="presentation"
                style={{ position: 'fixed', bottom: 10, right: 10 }}
            >
                <Fab
                    color="primary"
                    size="large"
                    aria-label="scroll back to top"
                >
                    <ArrowUpIcon className="h-6 w-6" />
                </Fab>
            </div>
        </Zoom>
    );
};

export default ScrollToTop;
