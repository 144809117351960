import React, { useEffect, useState, useRef } from 'react';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';

const schema = yup.object().shape({
    current_password: yup.string().required(),
    password: yup.string().required(),
    password_confirmation: yup.string().required(),
});

export default function UpdatePasswordForm({ className = '' }) {
    const passwordInput = useRef();
    const currentPasswordInput = useRef();

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            current_password: '',
            password: '',
            password_confirmation: '',
        },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        let url = '/user/profile/passwordUpdate';

        const ret = await axiosPost(url, data);
        if (ret.status == 200) {
            toast.success(ret.data.message);
            refresh();
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <section className={className}>
            <header>
                <h2 className="text-lg font-medium">Update Password</h2>

                <p className="mt-1 text-sm">
                    Ensure your account is using a long, random password to stay
                    secure.
                </p>
            </header>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-6 space-y-6">
                <Controller
                    name="current_password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                        <div className="mb-3 w-full">
                            <label>Current Password</label>
                            <TextField
                                type="password"
                                size="small"
                                className="mt-1 w-full"
                                value={value}
                                onChange={onChange}
                            />
                            {errors.current_password && (
                                <span className="text-rose-500">
                                    {errors.current_password.message}
                                </span>
                            )}
                        </div>
                    )}
                />

                <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                        <div className="mb-3 w-full">
                            <label>Password</label>
                            <TextField
                                type="password"
                                size="small"
                                className="mt-1 w-full"
                                value={value}
                                onChange={onChange}
                            />
                            {errors.password && (
                                <span className="text-rose-500">
                                    {errors.password.message}
                                </span>
                            )}
                        </div>
                    )}
                />

                <Controller
                    name="password_confirmation"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                        <div className="mb-3 w-full">
                            <label>Confirm Password</label>
                            <TextField
                                type="password"
                                size="small"
                                className="mt-1 w-full"
                                value={value}
                                onChange={onChange}
                            />
                            {errors.password_confirmation && (
                                <span className="text-rose-500">
                                    {errors.password_confirmation.message}
                                </span>
                            )}
                        </div>
                    )}
                />

                <div className="flex items-center gap-4">
                    <Button variant="contained" type="submit">
                        Save
                    </Button>
                </div>
            </form>
        </section>
    );
}
