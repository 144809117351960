import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ThemeActivity from './ThemeActivity';
import QuoteActivity from './QuoteActivity';
import ReferenceActivity from './ReferenceActivity';

import { axiosPost } from '@/services/axios';
import dayjs from 'dayjs';
import SearchActivity from './SearchActivity';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { api_url } from '../../../../constant';

const Activity = ({ user_id }) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        try {
            const params = {
                id: user_id,
            };
            const ret = await axiosPost('/admin/user/getData', params);
            if (ret.status == 200) {
                const user = ret.data.user;
                setUser(user);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const openExportSearchHistory = () => {
        let url =
            api_url + `/admin/user/export/search_history?user_id=${user_id}`;
        window.open(url, '_blank');
    };

    return (
        <div className="">
            <Box className="mt-4">
                <div className="flex justify-between">
                    <Typography sx={{ mb: 2 }}>Search Terms</Typography>
                    <Tooltip title="Export User Search Terms">
                        <IconButton onClick={(e) => openExportSearchHistory()}>
                            <FileDownloadIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <SearchActivity user_id={user_id} />
            </Box>

            <Box className="mt-4">
                <Typography sx={{ mb: 2 }}>Theme Clicked</Typography>
                <ThemeActivity user_id={user_id} />
            </Box>

            <Box className="mt-4">
                <Typography sx={{ mb: 2 }}>Quote Copied</Typography>
                <QuoteActivity user_id={user_id} />
            </Box>

            <Box className="mt-4">
                <Typography sx={{ mb: 2 }}>Source Reference Copied</Typography>
                <ReferenceActivity user_id={user_id} />
            </Box>
        </div>
    );
};

export default Activity;
