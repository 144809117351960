import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline/index.js';
import Swal from 'sweetalert2';

const CreateModal = ({
    setOpenModal,
    openModal,
    refresh,
    title,
    setLoading,
}) => {
    const [fileList, setFileList] = useState(null);
    const [shouldHighlight, setShouldHighlight] = useState(false);

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        fileList.map((file, i) => {
            formData.append(`files[${i}]`, file);
        });

        setOpenModal(false);
        setLoading(true);

        const ret = await axiosPost('/admin/batch-import/store', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (ret.status !== 200) {
            return toast.error(ret.data.message);
        }

        setLoading(false);

        let response = ret.data;

        if (response.already_exists.length > 0) {
            Swal.fire({
                title: 'This file already exists',
                html:
                    `
                    <div class="p-4">
                        <ul class="text-left list-disc">` +
                    response.already_exists
                        .map((file) => '<li>' + file.trim() + '</li>')
                        .join('') +
                    `</ul>
                    </div>
                `,
                icon: 'warning',
                confirmButtonText: 'OK',
            });
        }

        if (!response.data.length) return;

        refresh();
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <form onSubmit={onSubmit} className="p-4">
                    <DialogTitle> ({title})</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <div
                                    className={classNames({
                                        relative: true,
                                        'h-70 w-full': true,
                                        'grid cursor-pointer place-content-center p-4': true,
                                        'bg-white-200 rounded-lg text-black hover:bg-sky-50': true,
                                        'border-2 border-dashed border-gray-200 hover:border-gray-500': true,
                                        'transition-colors': true,
                                        'border-gray-500 bg-sky-50':
                                            shouldHighlight,
                                        'bg-white-200 border-gray-200':
                                            !shouldHighlight,
                                    })}
                                    onDragOver={(e) => {
                                        preventDefaultHandler(e);
                                        setShouldHighlight(true);
                                    }}
                                    onDragEnter={(e) => {
                                        preventDefaultHandler(e);
                                        setShouldHighlight(true);
                                    }}
                                    onDragLeave={(e) => {
                                        preventDefaultHandler(e);
                                        setShouldHighlight(false);
                                    }}
                                    onDrop={(e) => {
                                        preventDefaultHandler(e);
                                        const files = Array.from(
                                            e.dataTransfer.files
                                        );

                                        const acceptType = ['application/pdf'];

                                        let tmp = [];

                                        if (files.length > 0) {
                                            const element = files[0];
                                            if (
                                                acceptType.includes(
                                                    element.type
                                                )
                                            ) {
                                                tmp.push(element);
                                            }
                                        }

                                        setFileList(tmp);
                                        setShouldHighlight(false);
                                        console.log(files);
                                    }}
                                >
                                    <div
                                        className={
                                            'z-100 flex flex-col items-center dark:text-gray-500'
                                        }
                                    >
                                        {!fileList ? (
                                            <>
                                                <CloudArrowUpIcon className="h-10 w-10" />
                                                <span>
                                                    <span>Choose a File</span>{' '}
                                                    or drag it here
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <p>Files to Upload</p>
                                                {fileList.map((file, i) => {
                                                    return (
                                                        <span key={i}>
                                                            {file.name}
                                                        </span>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        accept={'application/pdf'}
                                        multiple={true}
                                        onChange={(e) => {
                                            const files = Array.from(
                                                e.target.files
                                            );
                                            setFileList(files);
                                            setShouldHighlight(false);
                                        }}
                                        className="absolute inset-0 z-10 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateModal;
