import TopSection from '../components/TopSection';
import Filters from '../components/Filters';
import { PencilSquareIcon } from '@heroicons/react/24/outline/index.js';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { axiosPost } from '../../../services/axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../context/AuthContext';
import { Backdrop, Button, CircularProgress, Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import SourceOverView from './component/SourceOverView';
import PremiseBox from '../../../component/PremiseBox';

const AntTabs = styled(Tabs)({
    borderBottom: 'none',
    '& .MuiTabs-indicator': {
        backgroundColor: '#ffffff',
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        fontSize: '16px',
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 0.55)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#ffffff',
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#ffffff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    })
);

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const Keywords = ({ keywords }) => {
    let val = keywords.join(', ');
    return <span>{val}</span>;
};

export default function PremiseListForSourcePage() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const { id } = useParams();
    const { user } = useAuth();

    const [source, setSource] = useState();
    const [premises, setPremises] = useState([]);

    const [keyword, setKeyword] = useState('');
    const [pageSort, setPageSort] = useState('');
    const [type, setType] = useState();

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);

    const [last, setLast] = useState(false);

    const [sourceKeywords, setSourceKeywords] = useState('');

    useEffect(() => {
        getData();
    }, [id]);

    useEffect(() => {
        if (!loading) {
            setPage(0);
            getThemeData(0);
        }
    }, [id, keyword, pageSort, type]);

    useEffect(() => {
        if (page > 0) {
            getThemeData(page);
        }
    }, [page]);

    const getData = async () => {
        try {
            const params = {
                id: id,
                keyword,
                pageSort,
                type,
            };
            const ret = await axiosPost('/user/main/premiseList', params);
            if (ret.status == 200) {
                const source = ret.data.source;
                const keywords = source.keywords;
                const sourceKeywords = keywords.join(', ');
                setSourceKeywords(sourceKeywords);
                setSource(source);
            } else {
                toast.warn(ret.data.message);
            }
        } catch (err) {
            toast.warn(err.response.data.message);
        }
    };

    const getThemeData = async (_page) => {
        setLoading(true);
        const params = {
            id,
            keyword,
            pageSort,
            type,
            page: _page,
        };

        const ret = await axiosPost('/user/main/premiseListData', params);
        setLoading(false);
        if (ret.status == 200) {
            const _premises = ret.data.premises;
            const last = ret.data.last;

            if (_page == 0) {
                setPremises([..._premises]);
            } else {
                setPremises([...premises, ..._premises]);
            }
            setLast(last);
        }
    };

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="mt-4">
                <div className="m-2 mb-6 text-white">
                    <div className="text-2xl">
                        <span className="font-bold">Title:</span>{' '}
                        <span className="text-lg"> {source?.title}</span>
                    </div>
                    <div className="text-2xl">
                        <span className="font-bold">Year of Publication:</span>{' '}
                        <span className="text-lg">
                            {' '}
                            {source?.year_of_publication}
                        </span>
                    </div>
                    <div className="text-2xl">
                        <span className="font-bold">Key Words:</span>{' '}
                        <span className="text-lg"> {sourceKeywords}</span>
                    </div>

                    <div className="mt-3 text-2xl">
                        <p className="font-bold">Bibliographic Reference: </p>
                        <p
                            className="text-lg"
                            dangerouslySetInnerHTML={{ __html: source?.br_apa }}
                        ></p>{' '}
                    </div>
                    <hr className="mt-4" />
                </div>

                <div className="border-2 border-white bg-black bg-opacity-40 p-6 backdrop-blur">
                    <Grid container sx={{ mt: 0 }}>
                        <Grid item sm={12}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <AntTabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    aria-label="ant example"
                                    variant="fullWidth"
                                >
                                    <AntTab label="Premise List" />
                                    <AntTab label="Source Overview" />
                                </AntTabs>
                            </Box>
                            <CustomTabPanel value={tabValue} index={0}>
                                <Filters
                                    term="premises"
                                    showSort={false}
                                    showSourceType={false}
                                    showPageSort={true}
                                    showType={true}
                                    setPageSort={setPageSort}
                                    setType={setType}
                                    setKeyword={setKeyword}
                                />
                                {premises &&
                                    premises.map((premise, index) => (
                                        <PremiseBox
                                            key={index}
                                            premise={premise}
                                            showAddButton={true}
                                            showInvestButton={false}
                                        />
                                    ))}

                                <div className="flex justify-center">
                                    {last == false && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => {
                                                setPage((page) => page + 1);
                                            }}
                                        >
                                            {loading ? 'Loading' : 'Load more'}
                                        </Button>
                                    )}
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={1}>
                                <Box className="mb-3 flex justify-end">
                                    <SourceOverView id={id} />
                                </Box>
                            </CustomTabPanel>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
