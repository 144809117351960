import { IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function MostQuoteCopy({ sources, opacity }) {
    return (
        <div
            className={`bg-opacity-${opacity} rounded border border-white bg-black px-2 py-2 backdrop-blur`}
        >
            <div className="m-2 mt-0 border-b border-b-white text-[14pt] font-bold text-white">
                {'Top 5 Quoted Sources'}
            </div>
            {sources.map((item, index) => {
                return (
                    <div
                        className="mb-4 ml-2 flex justify-between text-white"
                        key={index}
                    >
                        <p className="w-5">{index + 1}.</p>
                        <div className="flex-1">
                            <p
                                style={{ overflowWrap: 'anywhere' }}
                                dangerouslySetInnerHTML={{
                                    __html: item.br_apa,
                                }}
                            ></p>
                        </div>
                        <Link to={`/premises/source/${item.id}`}>
                            <IconButton sx={{ color: 'white' }}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
}
