import React, { useEffect, useState, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Navigate, Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

export default function Welcome() {
    const [value, setValue] = useState('register');
    const [home, setHome] = useState(0);

    return (
        <div className="">
            {/* <!-- Hero --> */}
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <section className="w-full overflow-auto rounded border-2 border-white bg-white bg-opacity-25 px-4 pb-20 font-sans backdrop-blur">
                        <div className="mt-32 w-full px-3">
                            <div className="mx-auto mb-8">
                                <h1 className="mt-16 text-3xl font-bold text-white">
                                    Welcome to SourceBank®
                                </h1>
                                <h2 className="mt-4 text-xl font-bold text-white">
                                    The Literature Review Resource for Serious
                                    Academics
                                </h2>
                            </div>
                        </div>
                    </section>
                </Grid>
            </Grid>
        </div>
    );
}
