import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Header from '../../component/FHeader';
import Footer from '../../component/Footer';
import { axiosPost } from '../../services/axios';
import { useAuth } from '../../context/AuthContext';
import Logo from '../../images/logo/logo.svg';
import { ToastContainer, toast } from 'react-toastify';

export default function Login() {
    const { setUser, csrfToken } = useAuth();
    const [emailError, setEmailError] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');

    // login user
    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmailError('');
        setPasswordError('');
        const { email, password } = e.target.elements;
        const body = {
            email: email.value,
            password: password.value,
        };
        await csrfToken();
        try {
            const resp = await axiosPost('/login', body);
            if (resp.status === 200) {
                setUser(resp.data.user);
                return <Navigate to="/admin/dashboard" />;
            }
            if (resp.status === 401) {
                setEmailError(resp.data.message);
            }
            if (resp.status === 422) {
                if (resp.data.errors.email) {
                    setEmailError(resp.data.errors.email[0]);
                } else {
                    setEmailError('');
                }
                if (resp.data.errors.password) {
                    setPasswordError(resp.data.errors.password[0]);
                } else {
                    setPasswordError('');
                }
            }
        } catch (error) {}
    };

    return (
        <div className="h-screen bg-[#000]">
            <main className="flex h-screen flex-col items-center justify-center">
                <div className="w-full rounded-sm">
                    <div className="mx-auto max-w-lg flex-wrap items-center rounded-lg bg-black text-white shadow-default">
                        <div className="border-stroke dark:border-strokedark">
                            <div className="w-full p-7.5">
                                <h3 className="mb-3 text-center text-2xl font-bold">
                                    Source Bank
                                </h3>
                                <h2 className="mb-9 text-center text-2xl font-bold">
                                    Sign In
                                </h2>
                                <form
                                    className="space-y-6"
                                    action="#"
                                    method="POST"
                                    onSubmit={handleSubmit}
                                >
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium leading-6"
                                        >
                                            Email address
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                className="w-full rounded-lg border border-stroke bg-transparent py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                            />
                                            {emailError && (
                                                <p className="text-sm text-danger">
                                                    {emailError}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium leading-6"
                                            >
                                                Password
                                            </label>
                                            <div className="text-sm">
                                                <a
                                                    href="#"
                                                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                                                >
                                                    {/* Forgot password? */}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="w-full rounded-lg border border-stroke bg-transparent py-3 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                            />
                                            {passwordError && (
                                                <p className="text-sm text-danger">
                                                    {passwordError}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-3 text-white transition hover:bg-opacity-90"
                                        >
                                            Sign in
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ToastContainer />
        </div>
    );
}
