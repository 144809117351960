import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from '../services/axios';
import { useAuth } from '../context/AuthContext';
import Header from '../component/admin/Header';
import Sidebar from '../component/admin/Sidebar';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import FocusTrap from 'focus-trap-react';
import { fetchImage, axiosPost } from '@/services/axios';
import { useDispatch, useSelector } from 'react-redux';
import { saveInitialize } from '../redux/actions';

export default function AdminProtectedLayout() {
    const { user, setUser, receiveNotification, setReceiveNotification } =
        useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(1);
    const [bgImage, setBgImage] = useState(localStorage.getItem('bg_image'));
    const [logoImage, setLogoImage] = useState(
        localStorage.getItem('logo_image')
    );

    const [notification, setNotification] = useState({ title: '', body: '' });
    const [activeFocusTab, setActiveFocusTab] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            console.log('tracking....');
            track();
        }, 30000); // Interval set to 1 second (1000ms)

        return () => clearInterval(interval);
    }, []);

    const track = async () => {
        console.log('tracking::::', user);
        if (user != undefined) {
            await axiosPost('/track');
        }
    };

    useEffect(() => {
        setActiveFocusTab(true);
        return () => {
            setActiveFocusTab(false);
        };
    });
    // check if user is logged in or not from server
    useEffect(() => {
        // if user is not logged in, redirect to login page

        (async () => {
            try {
                const resp = await axios.get('/user');
                if (resp.status === 200) {
                    setUser(resp.data.user);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('user');
                    window.location.href = '/';
                }
            }
        })();
    }, []);

    useEffect(() => {
        if (user) {
            setLoading(0);
        } else {
            return <Navigate to="/" />;
        }
    }, [user]);

    const dispatch = useDispatch();
    // if user is logged in, redirect to profile page

    useEffect(() => {
        getSetting();
    }, []);

    const getSetting = async () => {
        const ret = await axiosPost('/getInitialize');
        if (ret.status == 200) {
            dispatch(saveInitialize(ret.data));
            const setting = ret.data.setting;
            const bgImage = setting?.main_bg_image;
            if (bgImage) {
                getBGImage(bgImage);
            }

            const logoImage = setting?.logo_img;
            if (logoImage) {
                getLogoImage(logoImage);
            }
        }
    };

    const getBGImage = async (url) => {
        const _image = await fetchImage(url);
        setBgImage(_image);
        localStorage.setItem('bg_image', _image);
    };

    const getLogoImage = async (url) => {
        const _image = await fetchImage(url);
        setLogoImage(_image);
        localStorage.setItem('logo_image', _image);
    };

    /*

    This is used for pusher notification

    useEffect(() => {
        const beamsClient = new PusherPushNotifications.Client({
            instanceId: "f9d48613-ba14-442e-a6ca-b3efef54b946",
        });

        beamsClient
            .start()
            .then(() => beamsClient.addDeviceInterest("shiva"))
            .then(() => console.log("Successfully registered and subscribed!"))
            .catch(console.error);
    }, []);

    const UploadMessage = () => {
        let body = notification.body;
        let data = body.data;
        return (
            <div>
                <div className="mb-1">{notification.title}</div>
                {data.map((item, idx) => (
                    <div key={idx} className="">
                        {item}
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (notification.title != "") {
            let body = notification.body;
            let id = body.id;
            toast.success(<UploadMessage />, {
                toastId: id,
                position: "bottom-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [notification]);

    useEffect(() => {
        if (user != undefined) {
            window.Pusher.logToConsole = true;

            var pusher = new window.Pusher("56c7849d47468677b681", {
                cluster: "us3",
            });

            var channel = pusher.subscribe(`channel`);
            channel.bind("mevent", function (data) {
                setReceiveNotification(data);
                setNotification(data);
            });
        }
    }, [user]);
    */
    return (
        <>
            <FocusTrap active={false}>
                <div className="text-black dark:bg-boxdark-2 dark:text-gray-100">
                    {/* <!-- ===== Page Wrapper Start ===== --> */}
                    <div className="flex h-screen overflow-hidden">
                        {/* <!-- ===== Sidebar Start ===== --> */}
                        <Sidebar
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                            logoImage={logoImage}
                        />
                        {/* <!-- ===== Sidebar End ===== --> */}

                        {/* <!-- ===== Content Area Start ===== --> */}
                        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                            {/* <!-- ===== Header Start ===== --> */}
                            <Header
                                logoImage={logoImage}
                                sidebarOpen={sidebarOpen}
                                setSidebarOpen={setSidebarOpen}
                            />
                            {/* <!-- ===== Header End ===== --> */}

                            {/* <!-- ===== Main Content Start ===== --> */}
                            <main>
                                <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                                    <Outlet />
                                </div>
                            </main>
                            {/* <!-- ===== Main Content End ===== --> */}
                        </div>
                        {/* <!-- ===== Content Area End ===== --> */}
                    </div>
                    {/* <!-- ===== Page Wrapper End ===== --> */}
                </div>
            </FocusTrap>
            {loading == 1 && (
                <div className="absolute top-0 z-99999 m-auto flex h-screen w-full flex-1 flex-col items-center overflow-y-auto bg-black">
                    <CircularProgress className="m-auto" />
                </div>
            )}
            <ToastContainer />
        </>
    );
}
