import { Grid } from '@mui/material';
import { CountUp } from 'countup.js';
import React, { useEffect, useRef } from 'react';
import { BookOpenIcon } from '@heroicons/react/24/outline/index.js';
export default function TopSectionProgram({ countCategories, program = null }) {
    const subjectCountUpRef = useRef(null);
    const themeCountUpRef = useRef(null);
    const premiseCountUpRef = useRef(null);

    let anim = {};

    useEffect(() => {
        initCountUp();
    }, [countCategories]);

    async function initCountUp() {
        const countUps = [
            {
                ref: subjectCountUpRef,
                anim: 'subject',
            },
            {
                ref: themeCountUpRef,
                anim: 'theme',
            },
            {
                ref: premiseCountUpRef,
                anim: 'premise',
            },
        ];

        for (const index in countUps) {
            const countUp = countUps[index];

            const to = countCategories[`${countUp.anim}s_count`]
                ? countCategories[`${countUp.anim}s_count`]
                : 0;
            anim[countUp.anim] = new CountUp(countUp.ref.current, to);

            if (!anim[countUp.anim].error) {
                anim[countUp.anim].start();
            } else {
                console.error(anim[countUp.anim].error);
            }
        }
    }

    return (
        <>
            <div className="mb-4 md:flex">
                <div className="mr-4 flex-1">
                    <h3 className="text-xl text-white">
                        <span className="font-bold">{'Faculty'}: </span>
                        {program?.faculty?.name}
                    </h3>
                    <h1 className="text-4xl text-white">
                        <span className="font-bold">{'Programme'}: </span>
                        {program?.name}
                    </h1>
                </div>
                <div className="mt-3 flex flex-col md:items-end">
                    <div className="rounded border border-white p-2 text-white">
                        <p className="w-48"></p>
                        <div className="flex">
                            <span>
                                <BookOpenIcon className="mr-2 mt-0.5 h-5 w-5 flex-shrink-0" />
                            </span>{' '}
                            Subjects:
                            <span className="ml-2" ref={subjectCountUpRef}>
                                0
                            </span>
                        </div>
                        <span className="mr-2 inline-block h-4 w-4 border border-white bg-[#0010aa] align-middle"></span>{' '}
                        Themes:
                        <span className="ml-2" ref={themeCountUpRef}>
                            0
                        </span>
                        <br />
                        <span className="mr-2 inline-block h-4 w-4 border border-white bg-[#10aa00] align-middle"></span>{' '}
                        Premises:
                        <span className="ml-2" ref={premiseCountUpRef}>
                            0
                        </span>
                    </div>
                </div>
            </div>

            <hr />
        </>
    );
}
