const { VITE_APP_ENV, VITE_APP_URL_LOCAL, VITE_APP_URL_PRODUCT } = import.meta
    .env;

export const server_url =
    VITE_APP_ENV === 'local' ? VITE_APP_URL_LOCAL : VITE_APP_URL_PRODUCT;
export const api_url = server_url + 'api';
export const commonWordList = [
    'a',
    'an',
    'the',
    'I',
    'you',
    'he',
    'she',
    'it',
    'we',
    'they',
    'me',
    'and',
    'but',
    'or',
    'so',
    'yet',
    'not',
    'yes',
    'if',
    'only',
    'in',
    'on',
    'at',
    'for',
    'to',
    'from',
    'with',
    'by',
    'as',
    'of',
    'about',
    'between',
    'through',
    'is',
    'are',
    'was',
    'were',
    'have',
    'has',
    'had',
    'do',
    'does',
    'did',
    'be',
    'been',
    'being',
    'good',
    'bad',
    'nice',
    'great',
    'small',
    'big',
    'large',
];
