import { IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { TagCloud } from 'react-tagcloud';

const customRenderer = (tag, size, color) => {
    return (
        <span
            key={tag.value}
            style={{
                color: 'white',
                fontSize: size,
                verticalAlign: 'middle',
                textTransform: 'capitalize',
                display: 'inline-block',
            }}
            className={`d-inline-block m-2`}
        >
            {tag.value}
        </span>
    );
};

export default function SearchTerms({ data, opacity }) {
    return (
        <div
            className={
                'bg-opacity- rounded border border-white bg-black px-2 py-2 backdrop-blur' +
                opacity
            }
        >
            <div className="m-2 mt-0 border-b border-b-white text-[14pt] font-bold text-white">
                {'Most Searched For Terms'}
            </div>
            <div className="mt-3 text-xs">
                {data.map((item) => (
                    <span
                        key={item.value}
                        style={{
                            lineHeight: '1.2rem',
                            display: 'inline-block',
                            marginRight: '10px',
                        }}
                    >
                        <span
                            style={{ textTransform: 'capitalize' }}
                            className="mx-2"
                        >
                            {item.value}
                        </span>
                        <span
                            style={{
                                padding: '2px 10px 2px 10px',
                                borderRadius: '15px',
                                background: '#6db166',
                            }}
                        >
                            {item.count}
                        </span>
                    </span>
                ))}
            </div>

            <div className="my-5">
                <TagCloud
                    minSize={20}
                    maxSize={55}
                    tags={data}
                    onClick={(tag) => alert(`'${tag.count}' was selected!`)}
                    renderer={customRenderer}
                    className="text-center"
                />
            </div>
        </div>
    );
}
