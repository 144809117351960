import React, { useEffect, useState, useRef } from 'react';
import { Button, Grid, IconButton, InputLabel, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export default function NameField({
    inputName,
    label,
    setData,
    errors,
    required = false,
    value,
}) {
    const [names, setNames] = useState(
        value && value.length ? value : [{ first: '', last: '' }]
    );

    useEffect(() => {
        const tmp = value && value.length ? value : [{ first: '', last: '' }];
        setNames(tmp);
    }, [value]);
    const addName = () => {
        setNames([...names, { first: '', last: '' }]);
    };

    const removeName = (index) => {
        let updatedNames = names?.filter((n, i) => i !== index);

        setData(
            inputName,
            updatedNames.length ? updatedNames : [{ first: '', last: '' }]
        );
    };

    const editName = (index, type, value) => {
        const newNames = [...names];
        newNames[index][type] = value;
        setNames(newNames);
        setData(inputName, newNames);
    };

    return (
        <div className="mb-3">
            <div>
                {names.map((name, index) => (
                    <Grid
                        container
                        spacing={1}
                        key={`name-${index}`}
                        className="mb-3"
                    >
                        <Grid item sm={5}>
                            <div>
                                <label
                                    htmlFor={`${inputName}_first_${index}`}
                                >{`${label} First Name`}</label>

                                <TextField
                                    size="small"
                                    id={`${inputName}_first_${index}`}
                                    className="mt-1 w-full"
                                    value={name.first}
                                    onChange={(e) =>
                                        editName(index, 'first', e.target.value)
                                    }
                                    autoComplete="first_name"
                                />
                                {errors[inputName] && (
                                    <span className="text-rose-500">
                                        {errors[inputName]}
                                    </span>
                                )}
                            </div>
                        </Grid>
                        <Grid item sm={5}>
                            <div>
                                <label
                                    htmlFor={`${inputName}_last_${index}`}
                                >{`${label} Last Name`}</label>
                                <TextField
                                    size="small"
                                    id={`${inputName}_last_${index}`}
                                    className="mt-1 w-full"
                                    value={name.last}
                                    onChange={(e) =>
                                        editName(index, 'last', e.target.value)
                                    }
                                    autoComplete="last_name"
                                />

                                {errors[inputName] && (
                                    <span className="text-rose-500">
                                        {errors[inputName]}
                                    </span>
                                )}
                            </div>
                        </Grid>
                        <Grid item sm={1}>
                            <IconButton
                                onClick={() => removeName(index)}
                                sx={{ mt: 3 }}
                            >
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </div>
            <Button className="mt-3" onClick={addName}>
                <AddIcon fontSize="inherit" /> Add{' '}
            </Button>
        </div>
    );
}
