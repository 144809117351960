import PremiseBox from '../../../component/PremiseBox';
import React, { useEffect, useState } from 'react';
import TopSection from '../components/TopSection';
import Filters from '../components/Filters';
import { CSSTransition } from 'react-transition-group';
import {
    PencilSquareIcon,
    PlusIcon,
} from '@heroicons/react/24/outline/index.js';
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { axiosPost } from '../../../services/axios';
import { useAuth } from '../../../context/AuthContext';

export default function UserThemePage({}) {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const searchKey = searchParams.get('searchKey');

    const [loading, setLoading] = useState(false);

    const [countCategories, setCountCategories] = useState([]);
    const [theme, setTheme] = useState();
    const [subject, setSubject] = useState();
    const [premises, setPremises] = useState([]);

    const [page, setPage] = useState(0);

    const [total, setTotal] = useState(0);
    const [last, setLast] = useState(false);

    const [type, setType] = useState();
    const [pageSort, setPageSort] = useState();
    const [keyword, setKeyword] = useState(searchKey);

    useEffect(() => {
        getData();
    }, [id]);

    useEffect(() => {
        if (!loading) {
            setPage(0);
            getThemeData(0);
        }
    }, [id, keyword, pageSort, type]);

    useEffect(() => {
        if (page > 0) {
            getThemeData(page);
        }
    }, [page]);

    const getData = async () => {
        const params = {
            id,
        };

        const ret = await axiosPost('/user/main/theme', params);
        if (ret.status == 200) {
            const subject = ret.data.subject;
            const theme = ret.data.theme;

            setTheme(theme);
            setSubject(subject);
        }
    };

    const getThemeData = async (_page) => {
        setLoading(true);
        const params = {
            id,
            keyword,
            sort: pageSort,
            filter: type,
            page: _page,
        };

        const ret = await axiosPost('/user/main/themeData', params);
        setLoading(false);
        if (ret.status == 200) {
            const _premises = ret.data.premises;
            const total = ret.data.total;
            const last = ret.data.last;
            const countCategories = ret.data.countCategories;

            if (_page == 0) {
                setPremises([..._premises]);
            } else {
                setPremises([...premises, ..._premises]);
            }
            setTotal(total);
            setLast(last);
            setCountCategories(countCategories);
        }
    };

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <TopSection
                subject={subject}
                countCategories={countCategories}
                showSource={true}
            />

            <div className="mt-4">
                <Filters
                    showSort={false}
                    showType={true}
                    setType={setType}
                    showSourceType={false}
                    showPageSort={false}
                    // setPageSort={setPageSort}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    searchPlaceHolder={'Search Premises, Key Words and Quotes…'}
                />
                <div className="m-2 text-white">
                    <div className="text-[14pt]">
                        <span className="font-bold">Theme:</span> {theme?.name}{' '}
                    </div>
                </div>
                {premises &&
                    premises.map((premise, index) => (
                        <PremiseBox
                            key={index}
                            premise={premise}
                            index={index}
                        />
                    ))}
            </div>

            <div className="flex justify-center">
                {last == false && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            setPage((page) => page + 1);
                        }}
                    >
                        {loading ? 'Loading' : 'Load more'}
                    </Button>
                )}
            </div>
        </div>
    );
}
