import DeleteUserForm from './Partials/DeleteUserForm';
import UpdatePasswordForm from './Partials/UpdatePasswordForm';
import UpdateProfileInformationForm from './Partials/UpdateProfileInformationForm';
import SourceBankAccess from './Partials/SourceBankAccess';
import React, { useEffect, useState } from 'react';
import { axiosPost } from '../../../services/axios';

export default function UserEditProfile() {
    const [user, setUser] = useState();
    const [mustVerifyEmail, setMustVerifyEmail] = useState(false);
    const [status, setStaus] = useState(false);
    const [subscription, setSubscription] = useState();
    const [expiredSubscriptions, setExpiredSubscriptions] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const ret = await axiosPost('/user/profile/getData');
        if (ret.status == 200) {
            const mustVerifyEmail = ret.data.mustVerifyEmail;
            const status = ret.data.status;
            const subscription = ret.data.subscription;
            const expiredSubscriptions = ret.data.expiredSubscriptions;
            const user = ret.data.user;

            setMustVerifyEmail(mustVerifyEmail);
            setStaus(status);
            setSubscription(subscription);
            setExpiredSubscriptions(expiredSubscriptions);
            setUser(user);
        }
    };

    return (
        <div className="container">
            <h2 className="text-xl font-semibold leading-tight">Profile</h2>

            <div className="py-12">
                <div className="mx-auto space-y-6">
                    <div className="bg-black-2/75 p-4 text-white shadow sm:rounded-lg sm:p-8">
                        <UpdateProfileInformationForm
                            mustVerifyEmail={mustVerifyEmail}
                            status={status}
                            className="max-w-xl"
                            user={user}
                            refresh={getData}
                        />
                    </div>

                    <div className="bg-black-2/75 p-4 text-white shadow sm:rounded-lg sm:p-8">
                        <SourceBankAccess
                            subscription={subscription}
                            expiredSubscriptions={expiredSubscriptions}
                            className="max-w-xl"
                        />
                    </div>

                    <div className="bg-black-2/75 p-4 text-white shadow sm:rounded-lg sm:p-8">
                        <UpdatePasswordForm className="max-w-xl" />
                    </div>

                    <div className="bg-black-2/75 p-4 text-white shadow sm:rounded-lg sm:p-8">
                        <DeleteUserForm className="max-w-xl" />
                    </div>
                </div>
            </div>
        </div>
    );
}
