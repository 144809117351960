import * as React from 'react';

import { createBrowserRouter, createHashRouter } from 'react-router-dom';
import ProtectedLayout from './layout/ProtectedLayout';
import GuestLayout from './layout/GuestLayout';
import AdminGuestLayout from './layout/AdminGuestLayout';
import AdminProtectedLayout from './layout/AdminProtectedLayout';

import Home from './pages/Home';
import Login from './pages/auth/Login';

import DashboardPage from './pages/admin/DashboardPage';
import SourcesPage from './pages/admin/Sources/SourcesPage';
import FacultyPage from './pages/admin/Faculty/FacultyPage';
import ProgramPage from './pages/admin/Program';
import ThemePage from './pages/admin/Theme';
import PremisePage from './pages/admin/Premise';
import SubjectPage from './pages/admin/Subject';
import UnallocatedPremisePage from './pages/admin/UnallocatedPremisePage';
import UserPage from './pages/admin/User';
import UserViewPage from './pages/admin/User/UserViewPage';
import PackagePage from './pages/admin/Package';
import NotificationPage from './pages/admin/Notification/NotificationPage';
import SettingPage from './pages/admin/Setting/SettingPage';
import Welcome from './pages/user/Welcome';
import UserCreateSourcePage from './pages/user/Source/UserCreateSourcePage';
import UserAddPremisePage from './pages/user/Premise/UserAddPremisePage';
import MySourceBankPage from './pages/user/MySourceBank/MySourceBankPage';
import UserProgramPage from './pages/user/Program/UserProgramPage';
import UserEditProfile from './pages/user/Profile/UserEditProfile';
import UserSubjectPage from './pages/user/Subject/UserSubjectPage';
import UserThemePage from './pages/user/Theme/UserThemePage';
import PremiseListForSourcePage from './pages/user/Premise/PremiseListForSourcePage';
import VerifyEmail from './pages/VerifyEmail';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import UserSuggestPage from './pages/admin/UserSuggest/UserSuggestPage';
import BatchImportPage from './pages/admin/BatchImport/BatchImportPage.jsx';
import ShowBatchImport from './pages/admin/BatchImport/ShowBatchImport.jsx';
import UserFacultyPage from './pages/user/Faculty/UserFacultyPage.jsx';

const router = createHashRouter([
    {
        path: '/',
        element: <GuestLayout />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/verify-email/:token',
                element: <VerifyEmail />,
            },
            {
                path: '/forgot-password',
                element: <ForgetPassword />,
            },
            {
                path: '/reset-password/:token',
                element: <ResetPassword />,
            },
        ],
    },
    // user router
    {
        element: <ProtectedLayout />,
        children: [
            {
                path: '/welcome',
                element: <Welcome />,
            },
            {
                path: '/add/source',
                element: <UserCreateSourcePage />,
            },
            {
                path: '/add/premise',
                element: <UserAddPremisePage />,
            },
            {
                path: '/edit/premise/:id',
                element: <UserAddPremisePage />,
            },
            {
                path: '/premises/source/:id',
                element: <PremiseListForSourcePage />,
            },
            {
                path: '/mysourcebank',
                element: <MySourceBankPage />,
            },
            {
                path: '/faculty/:id',
                element: <UserFacultyPage />,
            },
            {
                path: '/programme/:id',
                element: <UserProgramPage />,
            },
            {
                path: '/subject/:id',
                element: <UserSubjectPage />,
            },
            {
                path: '/profile',
                element: <UserEditProfile />,
            },
            {
                path: '/theme/:id',
                element: <UserThemePage />,
            },
        ],
    },
    {
        path: '/admin',
        element: <AdminGuestLayout />,
        children: [
            {
                path: 'login',
                element: <Login />,
            },
        ],
    },
    // admin router
    {
        path: '/admin',
        element: <AdminProtectedLayout />,
        children: [
            {
                path: 'dashboard',
                element: <DashboardPage />,
            },
            {
                path: 'sources',
                element: <SourcesPage />,
            },
            {
                path: 'batch-import',
                element: <BatchImportPage />,
            },
            {
                path: 'batch-import/:id',
                element: <ShowBatchImport />,
            },
            {
                path: 'faculty',
                element: <FacultyPage />,
            },
            {
                path: 'programme',
                element: <ProgramPage />,
            },
            {
                path: 'theme',
                element: <ThemePage />,
            },
            {
                path: 'subject',
                element: <SubjectPage />,
            },
            {
                path: 'premise',
                element: <PremisePage />,
                children: [
                    {
                        path: ':source_id',
                        element: <PremisePage />,
                    },
                ],
            },
            {
                path: 'unallocated-premises',
                element: <UnallocatedPremisePage />,
            },
            {
                path: 'users',
                element: <UserPage />,
            },
            {
                path: 'users/:id/view',
                element: <UserViewPage />,
            },
            {
                path: 'package',
                element: <PackagePage />,
            },
            {
                path: 'notification',
                element: <NotificationPage />,
            },
            {
                path: 'setting',
                element: <SettingPage />,
            },
            {
                path: 'suggestions',
                element: <UserSuggestPage />,
            },
        ],
    },
]);

export default router;
