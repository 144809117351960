import React, { useRef, useState } from 'react';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Button, Modal, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteModal from './DeleteModal';

export default function DeleteUserForm({ className = '' }) {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const confirmUserDeletion = () => {
        setOpenDeleteModal(true);
    };

    return (
        <section className={`space-y-6 ${className}`}>
            <header>
                <h2 className="text-lg font-medium">Delete Account</h2>

                <p className="mt-1 text-sm">
                    Once your account is deleted, all of its resources and data
                    will be permanently deleted. Before deleting your account,
                    please download any data or information that you wish to
                    retain.
                </p>
            </header>

            <Button
                variant="contained"
                color="error"
                onClick={confirmUserDeletion}
            >
                Delete Account
            </Button>

            {openDeleteModal && (
                <DeleteModal
                    openModal={openDeleteModal}
                    setOpenModal={setOpenDeleteModal}
                />
            )}
        </section>
    );
}
