import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

const schema = yup.object().shape({
    faculty_id: yup.string().required(),
    program_id: yup.object().json(),
    subject_id: yup.object().json(),
    theme_id: yup.object().json(),
    source_id: yup.object().json(),
    premise: yup.string().required(),
    premise_type: yup.string().required(),
    quote: yup.string().required(),
    quote_page_range: yup.string().required(),
});

const formatSelect = (data, label, value) => {
    if (!data) {
        return [];
    }

    const newData = [];

    data.map((datum) => {
        newData.push({
            value: datum[value],
            label: datum[label],
        });
    });

    return newData;
};

const CreateEditModal = ({ setOpenModal, openModal, refresh, id, title }) => {
    const timer = useRef(0);

    const [isPLoading, setPLoading] = useState(false);
    const [isSLoading, setSLoading] = useState(false);
    const [isTLoading, setTLoading] = useState(false);
    const [faculties, setFaculties] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [themes, setThemes] = useState([]);

    const [init, setInit] = useState(false);
    const [initF, setInitF] = useState(false);
    const [initP, setInitP] = useState(false);
    const [initS, setInitS] = useState(false);
    const [initT, setInitT] = useState(false);

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            faculty_id: '',
            program_id: null,
            subject_id: null,
            theme_id: null,
            source_id: null,
            premise: '',
            premise_type: 'From Secondary Literature',
            quote: '',
            quote_page_range: '',
        },

        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const faculty_id = watch('faculty_id');
    const program_id = watch('program_id');
    const subject_id = watch('subject_id');
    const theme_id = watch('theme_id');

    useEffect(() => {
        if (id == undefined) {
            setInit(true);
        }

        if (initF && initP && initS && initT) {
            setInit(true);
        }
    }, [id, initF, initP, initS, initT]);

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    useEffect(() => {
        getFaculties();
    }, []);

    useEffect(() => {
        setPrograms([]);
        setSubjects([]);
        setThemes([]);

        if (faculty_id) {
            getPrograms();
            setInitF(true);
        }

        if (init) {
            setValue('program_id', null);
            setValue('subject_id', null);
            setValue('theme_id', null);
        }
    }, [faculty_id]);

    useEffect(() => {
        if (init) {
            setValue('subject_id', null);
            setValue('theme_id', null);
        }
        setSubjects([]);
        setThemes([]);

        if (program_id) {
            getSubjects();
            setInitP(true);
        }
    }, [program_id]);

    useEffect(() => {
        if (init) {
            setValue('theme_id', null);
        }
        setThemes([]);

        if (subject_id) {
            getThemes();
            setInitS(true);
        }
    }, [subject_id]);

    useEffect(() => {
        if (theme_id) {
            setInitT(true);
        }
    }, [theme_id]);

    const getFaculties = async () => {
        const ret = await axiosPost('/admin/faculty/getList');
        if (ret.status == 200) {
            const faculties = ret.data.data;
            setFaculties(faculties);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getPrograms = async () => {
        const params = {
            faculty_id: faculty_id,
        };
        const ret = await axiosPost('/admin/program/getList', params);
        if (ret.status == 200) {
            const programs = ret.data.data;
            const tmp = formatSelect(programs, 'name', 'id');
            setPrograms(tmp);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getSubjects = async () => {
        const params = {
            program_id: program_id?.value,
        };
        const ret = await axiosPost('/admin/subject/getList', params);
        if (ret.status == 200) {
            const subjects = ret.data.data;
            const tmp = formatSelect(subjects, 'name', 'id');
            setSubjects(tmp);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getThemes = async () => {
        const params = {
            subject_id: subject_id?.value,
        };
        const ret = await axiosPost('/admin/theme/getList', params);
        if (ret.status == 200) {
            const themes = ret.data.data;
            const tmp = formatSelect(themes, 'name', 'id');
            setThemes(tmp);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getData = async () => {
        const params = {
            id: id,
        };

        const ret = await axiosPost('/admin/premise/getData', params);
        if (ret.status == 200) {
            const premise = ret.data.source;
            const setValues = {
                faculty_id: premise?.faculty_id,
                program_id: premise?.program
                    ? {
                          value: premise.program?.id,
                          label: premise.program.name,
                      }
                    : null,
                subject_id: premise?.subject
                    ? {
                          value: premise.subject?.id,
                          label: premise.subject.name,
                      }
                    : null,
                source_id: premise && {
                    value: premise.source_id,
                    label: `${premise.source.author_name[0].last}, ${premise.source.author_name[0].first} (${premise.source.year_of_publication}) | ${premise.source.title}`,
                },
                theme_id: premise?.theme
                    ? { value: premise.theme?.id, label: premise.theme.name }
                    : null,
                premise: premise?.premise,
                premise_type: premise?.type,
                quote: premise?.quote,
                quote_page_range: premise?.quote_page_range,
            };
            reset(setValues);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const handleCreateProgramme = async (inputValue) => {
        setPLoading(true);
        const data = {
            faculty_id: faculty_id,
            name: inputValue,
            all: true,
        };
        const ret = await axiosPost('/admin/program/store', data);
        setPLoading(false);
        if (ret.status == 200) {
            const programs = ret.data.programs;
            const tmp = formatSelect(programs, 'name', 'id');
            setPrograms(tmp);

            const program = ret.data.program;

            const program_val = {
                value: program.id,
                label: program.name,
            };
            setValue('program_id', program_val);
        }
    };

    const handleCreateSubject = async (inputValue) => {
        setSLoading(true);
        const data = {
            program_id: program_id?.value,
            name: inputValue,
            all: true,
        };
        const ret = await axiosPost('/admin/subject/store', data);
        setSLoading(false);
        if (ret.status == 200) {
            const subjects = ret.data.subjects;
            const tmp = formatSelect(subjects, 'name', 'id');
            setSubjects(tmp);

            const subject = ret.data.subject;

            const subject_val = {
                value: subject.id,
                label: subject.name,
            };
            setValue('subject_id', subject_val);
        }
    };

    const handleCreateTheme = async (inputValue) => {
        setTLoading(true);
        const data = {
            subject_id: subject_id?.value,
            name: inputValue,
            all: true,
        };
        const ret = await axiosPost('/admin/theme/store', data);
        setTLoading(false);
        if (ret.status == 200) {
            const themes = ret.data.themes;
            const tmp = formatSelect(themes, 'name', 'id');
            setThemes(tmp);

            const theme = ret.data.theme;

            const theme_val = {
                value: theme.id,
                label: theme.name,
            };

            setValue('theme_id', theme_val);
        }
    };

    const filterSources = (sources, inputValue) => {
        return sources.map((source) => {
            const name = source.author_name.find(
                (authorName) =>
                    authorName.first.includes(inputValue) ||
                    authorName.last.includes(inputValue)
            );

            return {
                value: source.id,
                label: `${name.last}, ${name.first} (${source.year_of_publication}) | ${source.title}`,
            };
        });
    };

    const loadSourceOptions = (inputValue, callback) => {
        if (inputValue) {
            clearTimeout(timer.current);
            timer.current = setTimeout(async () => {
                const ret = await axiosPost('/admin/premise/search/sources', {
                    keyword: inputValue,
                });
                const data = ret.data;
                callback(filterSources(data.data, inputValue));
            }, 2000);
        }
    };

    const transform = (data) => {
        return {
            ...data,
            program_id: data.program_id?.value,
            subject_id: data.subject_id?.value,
            theme_id: data.theme_id?.value,
            source_id: data.source_id?.value,
            type: data.premise_type,
        };
    };
    const onSubmit = async (_data) => {
        const data = transform(_data);
        console.log('on submit', data);
        let url = '/admin/premise/store';
        if (id) {
            data['id'] = id;
            url = '/admin/premise/update';
        }
        const ret = await axiosPost(url, data);
        if (ret.status == 200) {
            refresh();
            setOpenModal(false);
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <DialogTitle>Premise Information {title}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant="h6"
                                    sx={{ mb: 2 }}
                                ></Typography>

                                <Controller
                                    name="faculty_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Faculty (F)</label>
                                            <Select
                                                fullWidth
                                                size="small"
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                {faculties.map((item) => (
                                                    <MenuItem
                                                        key={Math.random()}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.faculty_id && (
                                                <span className="text-rose-500">
                                                    {errors.faculty_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="program_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-9 w-full">
                                            <label>Programme (P)</label>
                                            <CreatableSelect
                                                isClearable
                                                isLoading={isPLoading}
                                                value={value}
                                                onChange={onChange}
                                                onCreateOption={
                                                    handleCreateProgramme
                                                }
                                                options={programs}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                            {errors.program_id && (
                                                <span className="text-rose-500">
                                                    {errors.program_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="subject_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-9 w-full">
                                            <label>Subject (S)</label>
                                            <CreatableSelect
                                                isClearable
                                                isLoading={isSLoading}
                                                value={value}
                                                onChange={onChange}
                                                onCreateOption={
                                                    handleCreateSubject
                                                }
                                                options={subjects}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                            {errors.subject_id && (
                                                <span className="text-rose-500">
                                                    {errors.subject_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="source_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-9 w-full">
                                            <label>
                                                Bibliographic Reference
                                            </label>

                                            <AsyncSelect
                                                id="source_id"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                loadOptions={loadSourceOptions}
                                                placeholder="Enter author name"
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                            {errors.source_id && (
                                                <span className="text-rose-500">
                                                    {errors.source_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="theme_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-9 w-full">
                                            <label>Theme (T)</label>
                                            <CreatableSelect
                                                isClearable
                                                isLoading={isTLoading}
                                                value={value}
                                                onChange={onChange}
                                                onCreateOption={
                                                    handleCreateTheme
                                                }
                                                options={themes}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                            {errors.theme_id && (
                                                <span className="text-rose-500">
                                                    {errors.theme_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="premise"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Premise</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.premise && (
                                                <span className="text-rose-500">
                                                    {errors.premise.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="premise_type"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Premise Type</label>
                                            <Select
                                                fullWidth
                                                size="small"
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                {[
                                                    'From Secondary Literature',
                                                    'Empirical Finding',
                                                    'Other',
                                                ].map((item) => (
                                                    <MenuItem
                                                        key={Math.random()}
                                                        value={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.premise_type && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.premise_type
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="quote"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Quote (Q)</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.quote && (
                                                <span className="text-rose-500">
                                                    {errors.quote.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="quote_page_range"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Quote Page Range</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.quote_page_range && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.quote_page_range
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateEditModal;
