import React, { useEffect, useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline/index.js';
import Spinner from './Spinner';
import PremiseGroupModal from './PremiseGroupModal';
import { ToastContainer, toast } from 'react-toastify';
import GroupCreateModal from './GroupCreateModal';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { axiosPost } from '../services/axios';
import { Grid } from '@mui/material';

const Keywords = ({ keywords }) => {
    let val = '';
    if (keywords != undefined) {
        val = keywords.join(', ');
    }

    return <span>{val}</span>;
};

export default function PremiseBox({
    index,
    premise,
    showAddButton = true,
    showInvestButton = true,
    ...props
}) {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [showGroup, setShowGroup] = useState(false);
    const [editRet, setEditRet] = useState(null);

    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [createRet, setCreateRet] = useState(null);

    useEffect(() => {
        if (message != '') {
            setShow(true);
            setTimeout(() => {
                setMessage('');
                setShow(false);
            }, 3000);
        }
    }, [message]);

    useEffect(() => {
        if (editRet != undefined) {
            const action = editRet.action;
            if (action == 'OpenCreateModal') {
                setShowCreateGroup(true);
                return;
            }
            if (editRet.id) {
                toast.success(editRet.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        }
    }, [editRet]);

    useEffect(() => {
        if (createRet != undefined) {
            if (editRet.id) {
                openAddGroup();
            }
        }
    }, [createRet]);

    function handleClick() {
        setLoading(true);
    }

    const copy = (premise, type, e) => {
        const blurQuote = user.blurQuote;
        const blurQuoteTxt = user.blurQuoteTxt
            ? user.blurQuoteTxt
            : 'You should subscribe to copy quote?';
        const blurPageRange = user.blurPageRange;
        const blurRangeTxt = user.blurRangeTxt;
        if (type === 'quote') {
            if (user.is_admin == 0 && blurQuote == 1) {
                toast.warn(blurQuoteTxt);
                return;
            }
        }
        const value = type === 'quote' ? premise.quote : premise.source.br_apa;

        navigator.clipboard.writeText(value);

        const target = e.target;
        const orjText = target.textContent;
        target.textContent = 'Copied!';
        setTimeout(() => (target.textContent = orjText), 500);

        axiosPost('/user/main/copy', {
            id: premise.id,
            type,
        });
    };

    const openAddGroup = () => {
        setShowGroup(true);
    };

    return (
        <div
            key={`premise-${index}`}
            className="group relative mb-5 rounded border border-[#10aa00] text-white"
        >
            {show && <FlashMessages2 message={message} />}
            <h4 className="bg-[#10aa00] p-2 text-xl font-bold text-white">
                {premise.premise}
            </h4>
            <div className="bg-white p-2 pt-4 text-black">
                <ul className="">
                    <li className="mb-2">
                        <strong>Premise Type:</strong>{' '}
                        {premise.type === 'From Secondary Literature'
                            ? 'Referencing Secondary Literature'
                            : premise.type}
                    </li>
                    <li className="mb-2">
                        <p>
                            <strong>Quote:</strong>
                        </p>
                        {user.is_admin == 0 && user.blurQuote == 1 ? (
                            <p className="font-bold text-[#002a6d]">
                                {user.blurQuoteTxt}
                            </p>
                        ) : (
                            <p className="italic">“{premise.quote}”</p>
                        )}
                    </li>
                    <li className="mb-2">
                        <p>
                            <strong>Page Range: </strong>
                        </p>
                        {user.is_admin == 0 && user.blurPageRange == 1 ? (
                            <p>
                                <span className="font-bold text-[#002a6d]">
                                    {user.blurRangeTxt}
                                </span>
                            </p>
                        ) : (
                            <p>{premise.quote_page_range}</p>
                        )}
                    </li>
                    <li className="mb-2">
                        <p>
                            <strong>Key Words: </strong>
                        </p>
                        <p>
                            <Keywords keywords={premise?.source?.keywords} />
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Source:</strong>
                        </p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: premise?.source?.br_apa,
                            }}
                        ></p>
                    </li>
                </ul>

                <div className="mt-3 sm:flex sm:space-x-2">
                    <div>
                        <button
                            onClick={(e) => copy(premise, 'quote', e)}
                            className="mr-2 mt-3 w-full rounded border border-indigo-400 bg-indigo-200 px-2 py-1 text-center text-sm text-indigo-700 transition-colors hover:bg-indigo-400 hover:text-white"
                        >
                            Copy Source Quote
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={(e) =>
                                copy(premise, 'source_reference', e)
                            }
                            className="mr-2 mt-3 w-full rounded border border-indigo-400 bg-indigo-200 px-2 py-1 text-center text-sm text-indigo-700 transition-colors hover:bg-indigo-400 hover:text-white"
                        >
                            Copy Source Reference
                        </button>
                    </div>

                    {showInvestButton && (
                        <div>
                            <button
                                disabled={loading}
                                className="mr-2 mt-3 w-full rounded border border-indigo-400 bg-indigo-200 px-2 py-1 text-center text-sm text-indigo-700 transition-colors hover:bg-indigo-400 hover:text-white"
                                onClick={(e) => handleClick()}
                            >
                                <span className="flex justify-center">
                                    {loading && <Spinner />}
                                    {loading ? (
                                        <>Investigate Source</>
                                    ) : (
                                        <Link
                                            to={`/premises/source/${premise.source_id}`}
                                        >
                                            Investigate Source
                                        </Link>
                                    )}
                                </span>
                            </button>
                        </div>
                    )}

                    {showAddButton && (
                        <div>
                            <button
                                onClick={(e) => openAddGroup(premise)}
                                className="mr-2 mt-3 w-full rounded border border-indigo-400 bg-indigo-200 px-2 py-1 text-center text-sm text-indigo-700 transition-colors hover:bg-indigo-400 hover:text-white"
                            >
                                Add to My SourceBank
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {user.is_admin && (
                <div className="absolute bottom-0 right-2 hidden group-hover:block">
                    <Link
                        to={`/edit/premise/${premise.id}`}
                        className="inline-block rounded border border-black bg-white p-1 text-[#0010aa] transition-transform duration-200 hover:translate-y-0.5"
                    >
                        <PencilSquareIcon className="h-5 w-5" />
                    </Link>
                </div>
            )}
            {showGroup && (
                <PremiseGroupModal
                    item={premise}
                    openModal={showGroup}
                    setOpenModal={setShowGroup}
                    onUpdate={setEditRet}
                />
            )}

            {showCreateGroup && (
                <GroupCreateModal
                    openModal={showCreateGroup}
                    setOpenModal={setShowCreateGroup}
                    onUpdate={setCreateRet}
                />
            )}
        </div>
    );
}
