import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { axiosPost } from '@/services/axios';
import dayjs from 'dayjs';
import AccessHistory from './partial/AccessHistory';
import Activity from './partial/Activity';
import UserSubscription from './partial/UserSubscription';
import { api_url } from '../../../constant';
import GroupHistory from './partial/GroupHistory';

const UserViewPage = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();
    const [active, setActive] = useState();
    const [expired, setExpired] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        try {
            const params = {
                id,
            };
            const ret = await axiosPost('/admin/user/getData', params);
            if (ret.status == 200) {
                const user = ret.data.user;
                setUser(user);
                setActive(ret.data.active);
                setExpired(ret.data.expired);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const openExportAccessHistory = () => {
        let url = api_url + `/admin/user/export/access_history?user_id=${id}`;
        window.open(url, '_blank');
    };

    const openExportGroupHistory = () => {
        let url = api_url + `/admin/user/export/group_history?user_id=${id}`;
        window.open(url, '_blank');
    };

    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative mx-auto w-full max-w-screen-lg p-4 dark:bg-transparent">
                <div className="flex justify-between">
                    <Typography variant="h5">View User</Typography>
                </div>
                <div>
                    <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                        <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                            <Typography variant="h6">User Profile</Typography>
                        </Box>
                        <Box className="p-5 px-5">
                            <Grid container spacing={2}>
                                <Grid item sm={4}>
                                    <div>Is Admin</div>
                                    <div>
                                        {user?.is_admin == 1 ? (
                                            <CheckCircleOutlineIcon color="success" />
                                        ) : (
                                            <HighlightOffIcon color="error" />
                                        )}
                                    </div>
                                </Grid>
                                <Grid item sm={4}>
                                    <div>Name</div>
                                    <Typography>{user?.name}</Typography>
                                </Grid>
                                <Grid item sm={4}>
                                    <div>Email</div>
                                    <Typography>{user?.email}</Typography>
                                </Grid>
                                <Grid item sm={4}>
                                    <div>Created At</div>
                                    <Typography>
                                        {user?.created_at &&
                                            dayjs(user?.created_at).format(
                                                'MMM D, YYYY H:m:s'
                                            )}
                                    </Typography>
                                </Grid>
                                <Grid item sm={4}>
                                    <div>Date last sign on</div>
                                    <Typography>
                                        {user?.lastLoginAt &&
                                            dayjs(user?.lastLoginAt).format(
                                                'MMM D, YYYY H:m:s'
                                            )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                        <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                            <Typography variant="h6">Subscriptions</Typography>
                        </Box>
                        <Box className="p-5 px-5">
                            <UserSubscription
                                user_id={id}
                                active={active}
                                expired={expired}
                            />
                        </Box>
                    </Box>

                    <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                        <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                            <div className="flex justify-between">
                                <Typography variant="h6">
                                    Access History
                                </Typography>
                                <Tooltip title="Export User Access History">
                                    <IconButton
                                        onClick={(e) =>
                                            openExportAccessHistory()
                                        }
                                    >
                                        <FileDownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Box>

                        <Box className="p-5 px-5">
                            <AccessHistory user_id={id} />
                        </Box>
                    </Box>

                    <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                        <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                            <div className="flex justify-between">
                                <Typography sx={{ mb: 2 }}>
                                    User SourceBank Lists
                                </Typography>
                                <Tooltip title="Export SourceBank List">
                                    <IconButton
                                        onClick={(e) =>
                                            openExportGroupHistory()
                                        }
                                    >
                                        <FileDownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Box>
                        <Box className="p-5 px-5">
                            <GroupHistory user_id={id} />
                        </Box>
                    </Box>

                    <Box className="mt-5 rounded-lg bg-gray-200 dark:bg-gray-700">
                        <Box className="border-b-1 border-b-gray-500 p-5 py-2">
                            <Typography variant="h6">Activity</Typography>
                        </Box>
                        <Box className="p-5 px-5">
                            <Activity user_id={id} />
                        </Box>
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default UserViewPage;
