import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

const CreateEditModal = ({ setOpenModal, openModal, refresh, id, title }) => {
    const [data, setData] = useState();
    const [status, setStatus] = useState('Pending');

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const getData = async () => {
        const params = {
            id: id,
        };

        const ret = await axiosPost('/admin/suggest/getData', params);
        if (ret.status == 200) {
            const source = ret.data.source;
            setStatus(source?.status);
            setData(source);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const onSubmit = async () => {
        const _data = {};
        _data['id'] = id;
        _data['status'] = status;
        const url = '/admin/suggest/update';
        const ret = await axiosPost(url, _data);
        if (ret.status == 200) {
            refresh();
            setOpenModal(false);
        } else {
            toast.error(ret.data.message);
        }
    };

    const StatusCell = ({ status }) => {
        let color = '';

        switch (status) {
            case 'Pending':
                color = '#ab6f28';
                break;
            case 'Resolved':
                color = '#03b903';
                break;
            case 'Rejected':
                color = '#ff0000';
                break;

            default:
                break;
        }
        if (status == 'Pending') {
        } else {
        }
        return (
            <div
                className="rounded-full px-2 py-1 text-center"
                style={{ backgroundColor: color }}
            >
                {status}
            </div>
        );
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <DialogTitle> Update Suggestion Status </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <Typography
                                variant="h6"
                                sx={{ mb: 2 }}
                            ></Typography>

                            <div className="mb-3 w-full">
                                <label className="font-bold">Status</label>
                                {data?.status == 'Pending' ? (
                                    <Select
                                        size="small"
                                        className="w-full"
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={status}
                                        onChange={(e) =>
                                            setStatus(e.target.value)
                                        }
                                    >
                                        <MenuItem key={0} value="Pending">
                                            Pending
                                        </MenuItem>
                                        <MenuItem key={1} value="Resolved">
                                            Resolve
                                        </MenuItem>
                                        <MenuItem key={2} value="Rejected">
                                            Reject
                                        </MenuItem>
                                    </Select>
                                ) : (
                                    <StatusCell status={status} />
                                )}
                            </div>
                        </Grid>
                        <Grid item sm={6}>
                            <label className="font-bold">User Name</label>
                            <p className="mt-1">{data?.user?.name}</p>
                        </Grid>
                        <Grid item sm={6}>
                            <label className="font-bold">URL</label>
                            <p className="mt-1">{data?.url}</p>
                        </Grid>
                        <Grid item sm={6}>
                            <label className="font-bold">Feedback Type</label>
                            <p className="mt-1">{data?.feedback_type}</p>
                        </Grid>
                        <Grid item sm={6}>
                            <label className="font-bold">Regarding</label>
                            <p className="mt-1">{data?.regarding}</p>
                        </Grid>

                        <Grid item sm={12}>
                            <label className="font-bold">Comment</label>
                            <p className="mt-1">{data?.content}</p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleCloseConfirm();
                        }}
                    >
                        Cancel
                    </Button>
                    {data?.status == 'Pending' && (
                        <Button onClick={(e) => onSubmit()}>Save</Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateEditModal;
