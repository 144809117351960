import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import User1 from '../../images/user/user-01.png';
import { axiosPost } from '../../services/axios';

const DashboardPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { user } = useAuth();

    useEffect(() => {}, []);

    const handleClick = (video_id) => {
        navigate(`/video/${video_id}`);
    };
    const handleLogout = async () => {
        try {
            const resp = await axiosPost('/logout');
            if (resp.status === 200) {
                localStorage.removeItem('user');
                window.location.href = '/';
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative mx-auto w-full bg-white p-4 dark:bg-transparent">
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Dashboard
                </Typography>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={6}>
                        <div className="flex justify-between rounded-lg bg-gray-200 p-6 dark:bg-gray-800">
                            <div className="flex">
                                <div>
                                    <Avatar alt={user?.name} src={User1} />
                                </div>
                                <div className="ml-3">
                                    <div>Welcome</div>
                                    <div>{user?.name}</div>
                                </div>
                            </div>
                            <Button onClick={(e) => handleLogout()}>
                                <LogoutIcon /> Sign Out
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default DashboardPage;
