import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    TextareaAutosize,
    Tooltip,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

const schema = yup.object().shape({
    faculty_id: yup.string().required(),
    program_id: yup.object().json().nullable(),
    subject_id: yup.object().json().nullable(),
    theme_id: yup.object().json().nullable(),
});

const formatSelect = (data, label, value) => {
    if (!data) {
        return [];
    }

    const newData = [];

    data.map((datum) => {
        newData.push({
            value: datum[value],
            label: datum[label],
        });
    });

    return newData;
};

const CreateEditModal = ({ setOpenModal, openModal, refresh, id, nextItemHandle}) => {
    const timer = useRef(0);

    const [isPLoading, setPLoading] = useState(false);
    const [isSLoading, setSLoading] = useState(false);
    const [isTLoading, setTLoading] = useState(false);
    const [faculties, setFaculties] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [themes, setThemes] = useState([]);
    const [temporaryData, setTemporaryData] = useState({});
    const [premiseContent, setPremiseContent] = useState('');

    const [init, setInit] = useState(false);
    const [initF, setInitF] = useState(false);
    const [initP, setInitP] = useState(false);
    const [initS, setInitS] = useState(false);
    const [initT, setInitT]=useState(false);

    const [sessionValues, setSessionValues]=useState({
        faculty_id: null,
        program_id: null,
        subject_id: null,
        theme_id: null,
    });

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            faculty_id: '',
            program_id: null,
            subject_id: null,
            theme_id: null,
        },

        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const faculty_id = watch('faculty_id');
    const program_id = watch('program_id');
    const subject_id = watch('subject_id');
    const theme_id = watch('theme_id');

    const form = useRef(null);

    useEffect(() => {
        if (id == undefined) {
            setInit(true);
        }

        if (initF && initP && initS && initT) {
            setInit(true);
        }
    }, [id, initF, initP, initS, initT]);

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    useEffect(() => {
        getFaculties();
        getSessionValues();
    }, []);

    useEffect(() => {
        setPrograms([]);
        setSubjects([]);
        setThemes([]);

        if (faculty_id) {
            getPrograms();
            setInitF(true);
        }

        if (init) {
            setValue('program_id', null);
            setValue('subject_id', null);
            setValue('theme_id', null);
        }
    }, [faculty_id]);

    useEffect(() => {
        if (init) {
            setValue('subject_id', null);
            setValue('theme_id', null);
        }
        setSubjects([]);
        setThemes([]);

        if (program_id) {
            getSubjects();
            setInitP(true);
        }
    }, [program_id]);

    useEffect(() => {
        if (init) {
            setValue('theme_id', null);
        }
        setThemes([]);

        if (subject_id) {
            getThemes();
            setInitS(true);
        }
    }, [subject_id]);

    useEffect(() => {
        if (theme_id) {
            setInitT(true);
        }
    }, [theme_id]);

    const getSessionValues=() => {
        setSessionValues({
            faculty_id: getSessionValue('faculty_id'),
            program_id: getSessionValue('program_id'),
            subject_id: getSessionValue('subject_id'),
            theme_id: getSessionValue('theme_id'),
        });
    }

    const getSessionValue=(field) => {
        let temp=localStorage.getItem(field);
        if (temp=="undefined") {
            temp = null;
        }
        return temp ? Number(temp) : null;
    }

    const getPreviousValueName=(list, field, customSelect = false) => {
        let id=sessionValues[field];
        let object = list.find((item) => (customSelect ? item[customSelect] : item.id) == id)
        return (customSelect ? object?.label : object?.name) ?? 'No previous value or conditions not met';
    };

    const previousButtonDisable=(field) => {
        switch (field) {
            case 'faculty_id':
                return !sessionValues.faculty_id;
            case 'program_id':
                return faculty_id !== sessionValues.faculty_id || !sessionValues.program_id;
            case 'subject_id':
                return program_id?.value !== sessionValues.program_id || !sessionValues.subject_id;
            case 'theme_id':
                return subject_id?.value !== sessionValues.subject_id||!sessionValues.theme_id;
            default:
                return true;
        }
    };

    const getFaculties = async () => {
        const ret = await axiosPost('/admin/faculty/getList');
        if (ret.status == 200) {
            const faculties = ret.data.data;
            setFaculties(faculties);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getPrograms = async () => {
        const params = {
            faculty_id: faculty_id,
        };
        const ret = await axiosPost('/admin/program/getList', params);
        if (ret.status == 200) {
            const programs = ret.data.data;
            const tmp = formatSelect(programs, 'name', 'id');
            setPrograms(tmp);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getSubjects = async () => {
        const params = {
            program_id: program_id?.value,
        };
        const ret = await axiosPost('/admin/subject/getList', params);
        if (ret.status == 200) {
            const subjects = ret.data.data;
            const tmp = formatSelect(subjects, 'name', 'id');
            setSubjects(tmp);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getThemes = async () => {
        const params = {
            subject_id: subject_id?.value,
        };
        const ret = await axiosPost('/admin/theme/getList', params);
        if (ret.status == 200) {
            const themes = ret.data.data;
            const tmp = formatSelect(themes, 'name', 'id');
            setThemes(tmp);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getData = async () => {
        const params = {
            id: id,
        };

        const ret = await axiosPost('/admin/premise/getData', params);
        if (ret.status == 200) {
            const premise = ret.data.source;
            const setValues = {
                faculty_id: premise?.faculty_id,
                program_id: premise?.program
                    ? {
                          value: premise.program?.id,
                          label: premise.program.name,
                      }
                    : null,
                subject_id: premise?.subject
                    ? {
                          value: premise.subject?.id,
                          label: premise.subject.name,
                      }
                    : null,
                theme_id: premise?.theme
                    ? { value: premise.theme?.id, label: premise.theme.name }
                    : null,
            };
            reset(setValues);
            setTemporaryData(premise?.temporary_data);
            setPremiseContent(premise?.premise);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const handleCreateProgramme = async (inputValue) => {
        setPLoading(true);
        const data = {
            faculty_id: faculty_id,
            name: inputValue,
            all: true,
        };
        const ret = await axiosPost('/admin/program/store', data);
        setPLoading(false);
        if (ret.status == 200) {
            const programs = ret.data.programs;
            const tmp = formatSelect(programs, 'name', 'id');
            setPrograms(tmp);

            const program = ret.data.program;

            const program_val = {
                value: program.id,
                label: program.name,
            };
            setValue('program_id', program_val);
        }
    };

    const handleCreateSubject = async (inputValue) => {
        setSLoading(true);
        const data = {
            program_id: program_id?.value,
            name: inputValue,
            all: true,
        };
        const ret = await axiosPost('/admin/subject/store', data);
        setSLoading(false);
        if (ret.status == 200) {
            const subjects = ret.data.subjects;
            const tmp = formatSelect(subjects, 'name', 'id');
            setSubjects(tmp);

            const subject = ret.data.subject;

            const subject_val = {
                value: subject.id,
                label: subject.name,
            };
            setValue('subject_id', subject_val);
        }
    };

    const handleCreateTheme = async (inputValue) => {
        setTLoading(true);
        const data = {
            subject_id: subject_id?.value,
            name: inputValue,
            all: true,
        };
        const ret = await axiosPost('/admin/theme/store', data);
        setTLoading(false);
        if (ret.status == 200) {
            const themes = ret.data.themes;
            const tmp = formatSelect(themes, 'name', 'id');
            setThemes(tmp);

            const theme = ret.data.theme;

            const theme_val = {
                value: theme.id,
                label: theme.name,
            };

            setValue('theme_id', theme_val);
        }
    };

    const handleUsePreviousValue = async (e, fieldName, optionsArray, customSelect = false) => {
        e.preventDefault();
        e.stopPropagation();
        let id=sessionValues[fieldName];
        if (!customSelect) {
            setValue(fieldName, id);
            return;
        }
        let option=optionsArray.find((item) => item[customSelect]==id);
        setValue(fieldName, option);
    };

    const transform = (data) => {
        return {
            ...data,
            program_id: data.program_id?.value,
            subject_id: data.subject_id?.value,
            theme_id: data.theme_id?.value,
            source_id: data.source_id?.value,
            type: data.premise_type,
        };
    };

    const onSubmit = async (_data) => {
        const data = transform(_data);
        let url = '/admin/premise/store';
        if (id) {
            data['id'] = id;
            url = '/admin/premise/update';
        }
        try {
            const ret = await axiosPost(url, data);
            if (ret.status === 200) {
                let respData = ret.data?.data;
                localStorage.setItem('faculty_id', respData.faculty_id);
                localStorage.setItem('program_id', respData.program_id);
                localStorage.setItem('subject_id', respData.subject_id);
                localStorage.setItem('theme_id', respData.theme_id);
                getSessionValues();
                refresh();
                return true;
            } else {
                toast.error(ret.data.message);
                return false;
            }
        } catch (error) {
            toast.error('An error occurred');
            return false;
        }
    };

    const saveNext = async (data) => {
        const success = await onSubmit(data);
        if (success) {
            nextItemHandle(id);
        }
    };

    const handleSaveExit = async (data) => {
        const success=await onSubmit(data);
        if (success) {
            setOpenModal(false);
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
                sx={{
                    '& .MuiDialog-paper': {
                        overflow: 'visible',
                    },
                }}
            >
                <form className="p-4" ref={form}>
                    <DialogTitle>Unallocated Premise Update</DialogTitle>
                    <DialogContent sx={{ overflow: 'visible' }}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant="h6"
                                    sx={{ mb: 2 }}
                                ></Typography>

                                <label>Premise</label>
                                <Typography className="!mb-9 rounded border border-meta-9 border-opacity-25 px-4 py-2">
                                    {premiseContent ?? <i>No data</i>}
                                </Typography>

                                <Controller
                                    name="faculty_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div
                                            className="mb-9 w-full"
                                            style={{ position: 'relative' }}
                                        >
                                            <label>Faculty (F)</label>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                             }}>

                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={value}
                                                    onChange={onChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                position:
                                                                    'absolute',
                                                                top: '100%',
                                                                left: 0,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {faculties.map((item) => (
                                                        <MenuItem
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>


                                                <Tooltip title={getPreviousValueName(faculties, 'faculty_id')}>
                                                    <span>
                                                        <Button disabled={previousButtonDisable('faculty_id') }
                                                            onClick={(e) => handleUsePreviousValue(e, 'faculty_id', faculties)}
                                                        >
                                                            <Typography className="!text-xs w-20">
                                                                Use Previous
                                                            </Typography>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </Box>
                                            {errors.faculty_id && (
                                                <span className="text-rose-500">
                                                    {errors.faculty_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="program_id"
                                    control={control}
                                    rules={{
                                        required: temporaryData?.program
                                            ? false
                                            : true,
                                    }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div
                                            className="mb-9 w-full"
                                            style={{ position: 'relative' }}
                                        >
                                            <label>Programme (P)</label>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                             }}>
                                                <CreatableSelect
                                                    isClearable
                                                    isLoading={isPLoading}
                                                    value={value}
                                                    placeholder={
                                                        <div>
                                                            {temporaryData?.program ??
                                                                'Select...'}
                                                        </div>
                                                    }
                                                    onChange={onChange}
                                                    onCreateOption={
                                                        handleCreateProgramme
                                                    }
                                                    options={programs}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            background:
                                                                'transparent',
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            position: 'absolute',
                                                            zIndex: 1300,
                                                        }),
                                                        container: (provided) => ({
                                                            ...provided,
                                                            width: '100%',
                                                        }),
                                                    }}
                                                    classNames={{
                                                        singleValue: () =>
                                                            'text-black dark:text-white',
                                                        menuList: () =>
                                                            'text-black',
                                                        input: () =>
                                                            'text-black dark:text-white',
                                                    }}
                                                />

                                                <Tooltip title={getPreviousValueName(programs, 'program_id', 'value')}>
                                                    <span>
                                                        <Button disabled={previousButtonDisable('program_id') }
                                                            onClick={(e) => handleUsePreviousValue(e, 'program_id', programs, 'value')}
                                                        >
                                                            <Typography className="!text-xs w-20">
                                                                Use Previous
                                                            </Typography>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </Box>
                                            {errors.program_id && (
                                                <span className="text-rose-500">
                                                    {errors.program_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="subject_id"
                                    control={control}
                                    rules={{
                                        required: temporaryData?.subject
                                            ? false
                                            : true,
                                    }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div
                                            className="mb-9 w-full"
                                            style={{ position: 'relative' }}
                                        >
                                            <label>Subject (S)</label>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                             }}>
                                                <CreatableSelect
                                                    isClearable
                                                    isLoading={isSLoading}
                                                    value={value}
                                                    onChange={onChange}
                                                    onCreateOption={
                                                        handleCreateSubject
                                                    }
                                                    placeholder={
                                                        <div>
                                                            {temporaryData?.subject ??
                                                                'Select...'}
                                                        </div>
                                                    }
                                                    options={subjects}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            background:
                                                                'transparent',
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            position: 'absolute',
                                                            zIndex: 1300,
                                                        }),
                                                        container: (provided) => ({
                                                            ...provided,
                                                            width: '100%',
                                                        }),
                                                    }}
                                                    classNames={{
                                                        singleValue: () =>
                                                            'text-black dark:text-white',
                                                        menuList: () =>
                                                            'text-black',
                                                        input: () =>
                                                            'text-black dark:text-white',
                                                    }}
                                                />

                                                <Tooltip title={getPreviousValueName(subjects, 'subject_id', 'value')}>
                                                    <span>
                                                        <Button disabled={previousButtonDisable('subject_id') }
                                                            onClick={(e) => handleUsePreviousValue(e, 'subject_id', subjects, 'value')}
                                                        >
                                                            <Typography className="!text-xs w-20">
                                                                Use Previous
                                                            </Typography>
                                                        </Button>
                                                    </span>
                                                </Tooltip>

                                            </Box>
                                            {errors.subject_id && (
                                                <span className="text-rose-500">
                                                    {errors.subject_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="theme_id"
                                    control={control}
                                    rules={{
                                        required: temporaryData?.theme
                                            ? false
                                            : true,
                                    }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div
                                            className="mb-9 w-full"
                                            style={{ position: 'relative' }}
                                        >
                                            <label>Theme (T)</label>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                             }}>
                                                <CreatableSelect
                                                    isClearable
                                                    isLoading={isTLoading}
                                                    value={value}
                                                    onChange={onChange}
                                                    onCreateOption={
                                                        handleCreateTheme
                                                    }
                                                    placeholder={
                                                        <div>
                                                            {temporaryData?.theme ??
                                                                'Select...'}
                                                        </div>
                                                    }
                                                    options={themes}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            background:
                                                                'transparent',
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            position: 'absolute',
                                                            zIndex: 1300,
                                                        }),
                                                        container: (provided) => ({
                                                            ...provided,
                                                            width: '100%',
                                                        }),
                                                    }}
                                                    classNames={{
                                                        singleValue: () =>
                                                            'text-black dark:text-white',
                                                        menuList: () =>
                                                            'text-black',
                                                        input: () =>
                                                            'text-black dark:text-white',
                                                    }}
                                                />
                                                <Tooltip title={getPreviousValueName(themes, 'theme_id', 'value')}>
                                                    <span>
                                                        <Button disabled={previousButtonDisable('theme_id')}
                                                            onClick={(e) => handleUsePreviousValue(e, 'theme_id', themes, 'value')}
                                                        >
                                                            <Typography className="!text-xs w-20">
                                                                Use Previous
                                                            </Typography>
                                                        </Button>
                                                    </span>

                                                </Tooltip>

                                            </Box>

                                            {errors.theme_id && (
                                                <span className="text-rose-500">
                                                    {errors.theme_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Typography className="text-sm" sx={{ my: 2 }}>
                            Grayed-out values in the fields above are temporary
                            data. Once saved, they will be created and inserted
                            into the Premise row.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit(saveNext)}>Save and Next</Button>
                        <Button onClick={handleSubmit(handleSaveExit)}>Save and Close</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateEditModal;
