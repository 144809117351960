import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import TablePagination from '@mui/material/TablePagination';

import { axiosPost } from '@/services/axios';
import dayjs from 'dayjs';

const headCells = [
    {
        id: 'groups.name',
        numeric: false,
        sortable: true,
        label: 'List Name',
    },
    {
        id: 'premise_view.premise',
        numeric: true,
        sortable: true,
        label: 'Premise',
    },
    {
        id: 'premise_view.subjects',
        numeric: false,
        sortable: true,
        label: 'Subject',
    },
    {
        id: 'premise_view.faculty',
        numeric: false,
        sortable: true,
        label: 'Faculty',
    },
    {
        id: 'premise_view.source_title',
        numeric: false,
        sortable: true,
        label: 'Source',
    },
    {
        id: 'premise_groups.created_at',
        numeric: false,
        sortable: true,
        label: 'Date Added to List',
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white">
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        align={headCell?.align}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <div>{headCell.label}</div>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const GroupHistory = ({ user_id }) => {
    const [loading, setLoading] = useState(false);

    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_at');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        // Clear previous timeout to avoid redundant API calls
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout to trigger search after 500ms (adjust as needed)
        const timeout = setTimeout(() => {
            getData();
        }, 1000); // Adjust delay time (in milliseconds) as needed

        // Update searchTimeout state to store the timeout ID
        setSearchTimeout(timeout);

        // Cleanup function to clear timeout when component unmounts or searchKey changes
        return () => clearTimeout(timeout);
    }, [page, rowsPerPage, orderBy, order]);

    const getData = async () => {
        setLoading(true);
        try {
            const params = {
                page,
                pageSize: rowsPerPage,
                id: user_id,
                orderBy,
                order,
            };

            const ret = await axiosPost('/admin/user/getGroupHistory', params);
            if (ret.status == 200) {
                const list = ret.data.data;
                const total = ret.data.total;
                setRows(list);
                setTotal(total);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="">
            <div className="">
                <TableContainer
                    component={Paper}
                    className="rounded-lg border border-gray-500"
                >
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="rounded-lg bg-gray-100 text-black dark:bg-gray-700 dark:text-white"
                    >
                        <TableHead>
                            <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white"></TableRow>
                        </TableHead>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.premise}</TableCell>
                                        <TableCell>{row.subjects}</TableCell>
                                        <TableCell>{row.faculty}</TableCell>
                                        <TableCell>
                                            {row.source_title}
                                        </TableCell>
                                        <TableCell>
                                            {row?.created_at
                                                ? dayjs(row?.created_at).format(
                                                      'MMM D, YYYY H:m:s'
                                                  )
                                                : ''}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

export default GroupHistory;
