import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import TablePagination from '@mui/material/TablePagination';

import { axiosPost } from '@/services/axios';
import CreateModal from './CreateModal.jsx';
import { toast } from 'react-toastify';
import { ArrowPathIcon } from '@heroicons/react/24/outline/index.js';
import { TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete.js';

const headCells = [
    {
        id: 'id',
        numeric: false,
        sortable: true,
        label: 'Batch ID',
    },
    {
        id: 'file_name',
        numeric: true,
        sortable: true,
        label: 'File Name',
    },
    {
        id: 'status',
        numeric: true,
        sortable: false,
        label: 'Status',
    },
    {
        id: '',
        numeric: false,
        sortable: false,
        label: '',
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white">
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <div>{headCell.label}</div>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const BatchImportPage = () => {
    const [loading, setLoading] = useState(true);

    const [selected, setSelected] = React.useState([]);

    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_at');

    const [openModal, setOpenModal] = useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
        // Clear previous timeout to avoid redundant API calls
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout to trigger search after 500ms (adjust as needed)
        const timeout = setTimeout(() => {
            getData();
        }, 1000); // Adjust delay time (in milliseconds) as needed

        // Update searchTimeout state to store the timeout ID
        setSearchTimeout(timeout);

        // Cleanup function to clear timeout when component unmounts or searchKey changes
        return () => clearTimeout(timeout);
    }, [page, rowsPerPage, orderBy, order]);

    const getData = async () => {
        setLoading(true);

        try {
            const params = {
                page: page + 1,
                per_page: rowsPerPage,
                orderBy,
                order,
            };

            const ret = await axiosPost('/admin/batch-import/getList', params);

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            setLoading(false);
            setRows(ret.data.data);
            setTotal(ret.data.total);
        } catch (err) {
            setLoading(false);
        }
    };

    const onCreated = () => {
        toast.success('Successfully created!');
        getData();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleReprocess = async (batch_id) => {
        setLoading(true);
        try {
            const ret = await axiosPost('/admin/batch-import/reprocess', {
                id: batch_id,
            });

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            setLoading(false);
            toast.success('Successfully added reprocessing to the queue!');
            getData();
        } catch (err) {
            setLoading(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (batch_id) => {
        const newTab = window.open(
            `/#/admin/batch-import/${batch_id}`,
            '_blank'
        );
        newTab.focus();
    };

    const handleDelete = async (batch_id) => {
        let swalResponse = await Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this batch?',
            icon: 'warning',
            showCancelButton: true,
        });

        if (!swalResponse.isConfirmed) {
            return;
        }

        setLoading(true);
        try {
            const ret = await axiosPost('/admin/batch-import/delete', {
                id: batch_id,
            });

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            setLoading(false);
            toast.success('Successfully deleted!');
            getData();
        } catch (err) {
            setLoading(false);
        }
    };

    const handleDeleteSelected = async () => {
        let swalResponse = await Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete selected batches?',
            icon: 'warning',
            showCancelButton: true,
        });

        if (!swalResponse.isConfirmed) {
            return;
        }

        setLoading(true);

        try {
            const ret = await axiosPost('/admin/batch-import/delete', {
                id: selected,
            });

            if (ret.status !== 200) {
                return toast.error(ret.data.message);
            }

            setLoading(false);
            toast.success('Successfully deleted!');
            getData();
        } catch (err) {
            setLoading(false);
        }

        setSelected([]);
    };

    const EnhancedTableToolbar = (props) => {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(
                                theme.palette.primary.main,
                                theme.palette.action.activatedOpacity
                            ),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    ></Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton onClick={(e) => handleDeleteSelected()}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <></>
                    // <Tooltip title="Download Users">
                    //     <IconButton onClick={(e) => handleExport()}>
                    //         <FileDownloadIcon />
                    //     </IconButton>
                    // </Tooltip>
                )}
            </Toolbar>
        );
    };

    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative mx-auto w-full p-4 dark:bg-transparent">
                <div className="flex justify-between">
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Batch Imports
                    </Typography>
                    <div className="flex">
                        <Button
                            variant="contained"
                            color="warning"
                            sx={{ mr: 2 }}
                            style={{
                                color: 'white',
                            }}
                            onClick={(e) => setOpenModal(true)}
                        >
                            New Batch
                        </Button>
                    </div>
                </div>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={12}></Grid>
                </Grid>

                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="rounded-lg bg-gray-50 text-black dark:bg-gray-800 dark:text-white"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        key={index}
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={(event) =>
                                                    handleClick(event, row.id)
                                                }
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            #{row.id}
                                        </TableCell>
                                        <TableCell
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {row.file_name}
                                        </TableCell>
                                        <TableCell
                                            className={'capitalize'}
                                            onClick={(event) =>
                                                handleRowClick(row.id)
                                            }
                                        >
                                            {row.status}
                                        </TableCell>
                                        <TableCell>
                                            <div className="flex gap-2">
                                                {[
                                                    'failed',
                                                    'processed',
                                                    'queued',
                                                    'extracted',
                                                ].includes(row.status) && (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            handleReprocess(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        <ArrowPathIcon className="size-5" />
                                                    </IconButton>
                                                )}

                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        handleDelete(row.id)
                                                    }
                                                >
                                                    <TrashIcon className="size-5" />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {openModal && (
                    <CreateModal
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        refresh={onCreated}
                        title={'Create Batch'}
                        setLoading={setLoading}
                    />
                )}
            </div>
        </div>
    );
};

export default BatchImportPage;
