import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TablePagination from '@mui/material/TablePagination';

import { axiosPost } from '@/services/axios';
import dayjs from 'dayjs';

const ThemeActivity = ({ user_id }) => {
    const [loading, setLoading] = useState(false);

    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);

    useEffect(() => {
        // Clear previous timeout to avoid redundant API calls
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout to trigger search after 500ms (adjust as needed)
        const timeout = setTimeout(() => {
            getData();
        }, 1000); // Adjust delay time (in milliseconds) as needed

        // Update searchTimeout state to store the timeout ID
        setSearchTimeout(timeout);

        // Cleanup function to clear timeout when component unmounts or searchKey changes
        return () => clearTimeout(timeout);
    }, [page, rowsPerPage]);

    const getData = async () => {
        setLoading(true);
        try {
            const params = {
                page,
                pageSize: rowsPerPage,
                type: 'theme',
                id: user_id,
            };
            const ret = await axiosPost('/admin/user/getActivity', params);
            if (ret.status == 200) {
                const list = ret.data.data;
                const total = ret.data.total;
                setRows(list);
                setTotal(total);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="">
            <div className="">
                <TableContainer
                    component={Paper}
                    className="rounded-lg border border-gray-500"
                >
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="rounded-lg bg-gray-100 text-black dark:bg-gray-700 dark:text-white"
                    >
                        <TableHead>
                            <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white">
                                <TableCell>Theme</TableCell>
                                <TableCell>Count</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{row?.name}</TableCell>
                                        <TableCell>{row.count}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

export default ThemeActivity;
