import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Input,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { html } from '@codemirror/lang-html';
import {
    basicLight,
    basicLightInit,
    basicDark,
    basicDarkInit,
} from '@uiw/codemirror-theme-basic';

const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    from_name: yup.string().required(),
    from: yup.string().email().required(),
    content: yup.string().required(),
});

const CreateEditModal = ({ setOpenModal, openModal, refresh, id, title }) => {
    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: '',
            description: '',
            from_name: '',
            from: '',
            to: '',
            cc: '',
            reply_to: '',
            subject: '',
            content: '',
        },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const onChangeContent = React.useCallback((val, viewUpdate) => {
        setValue('content', val);
    }, []);

    const getData = async () => {
        const params = {
            id: id,
        };

        const ret = await axiosPost('/admin/notification/getData', params);
        if (ret.status == 200) {
            const data = ret.data.data;
            const setValues = {
                name: data?.name,
                description: data?.description,
                from_name: data?.from_name,
                from: data?.from,
                to: data?.to,
                cc: data?.cc,
                reply_to: data?.reply_to,
                subject: data?.subject,
                content: data?.content,
            };
            reset(setValues);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const onSubmit = async (_data) => {
        console.log('on submit', _data);
        let url = '/admin/notification/store';
        if (id) {
            _data['id'] = id;
            url = '/admin/notification/update';
        }
        const ret = await axiosPost(url, _data);
        if (ret.status == 200) {
            refresh();
            setOpenModal(false);
        } else {
            toast.error(ret.data.message);
        }
    };

    // Define your extra tags
    const extraTags = ['first-name', 'last-name'];

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <DialogTitle> {title}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant="h6"
                                    sx={{ mb: 2 }}
                                ></Typography>

                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Notification Name</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.name && (
                                                <span className="text-rose-500">
                                                    {errors.name.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Event trigger</label>
                                            <Select
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                <MenuItem
                                                    key={0}
                                                    value={
                                                        'User Successfully Completes Subscription'
                                                    }
                                                >
                                                    User Successfully Completes
                                                    Subscription
                                                </MenuItem>
                                                <MenuItem
                                                    key={1}
                                                    value={
                                                        'User Fails to Finish Subscription'
                                                    }
                                                >
                                                    User Fails to Finish
                                                    Subscription
                                                </MenuItem>
                                                <MenuItem
                                                    key={2}
                                                    value={
                                                        'User Subscription is due for Renewal (7 days before renewal)'
                                                    }
                                                >
                                                    User Subscription is due for
                                                    Renewal (7 days before
                                                    renewal)
                                                </MenuItem>
                                            </Select>
                                            {errors.description && (
                                                <span className="text-rose-500">
                                                    {errors.description.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="from_name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>From Name</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.description && (
                                                <span className="text-rose-500">
                                                    {errors.description.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="from"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>From</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.description && (
                                                <span className="text-rose-500">
                                                    {errors.description.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="to"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Email to</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.description && (
                                                <span className="text-rose-500">
                                                    {errors.description.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="cc"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Carbon Copy (CC)</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.description && (
                                                <span className="text-rose-500">
                                                    {errors.description.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="reply_to"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Reply-to address</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.description && (
                                                <span className="text-rose-500">
                                                    {errors.description.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="subject"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Subject line</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.description && (
                                                <span className="text-rose-500">
                                                    {errors.description.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="content"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <Tooltip title="possible custom tags: [user-name], [user-email], [package-name], [package-term], [package-price]">
                                                <label>Content</label>
                                            </Tooltip>

                                            <CodeMirror
                                                value={value}
                                                onChange={onChangeContent}
                                                height="200px"
                                                theme={basicLight}
                                                extensions={[
                                                    html({
                                                        extraTags: extraTags,
                                                    }),
                                                ]}
                                            />

                                            {errors.content && (
                                                <span className="text-rose-500">
                                                    {errors.content.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateEditModal;
