import React, { useEffect, useState, useRef } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import Link from '@mui/material/Link';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NameField from './Partial/NameField';
import CreatableSelect from 'react-select/creatable';
import { Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import RichEditor from '../../../component/richeditor/RichEditor';

const formatSelect = (data) => {
    if (!data) {
        return [];
    }
    const newData = [];

    data.map((datum) => {
        newData.push({
            value: datum,
            label: datum,
        });
    });
    return newData;
};

const schema = yup.object().shape({
    source_type: yup.string().required(),
    year_of_publication: yup.number().required().min(1),
    type_of_publication: yup.string().required(),
    title: yup.string().required(),
    book_title: yup.string().required(),
    publisher_name: yup.string().required(),
    publisher_city: yup.string().required(),
    conference_title: yup.string().required(),

    volume_number: yup.string().required(),
    issue_number: yup.string().required(),
    page_range: yup.string().required(),
    issn: yup.string().required(),
    isbn: yup.string().required(),
    doi: yup.string().required(),
    url: yup.string().required(),

    journal_name: yup.string().required(),
    keywords: yup.array().required(),
});

const UserCreateSourcePage = ({}) => {
    const navigate = useNavigate();
    const [isCopied, setIsCopied] = useState(false);

    const [source, setSource] = useState();
    const [options, setOptions] = useState([]);
    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            // description: item?.description || "",
            source_type: 'Empirical',
            year_of_publication: '',
            type_of_publication: 'Book (Full)',
            title: '',
            book_title: '',
            publisher_name: '',
            publisher_city: '',
            conference_title: '',
            journal_name: '',
            volume_number: '',
            issue_number: '',
            page_range: '',
            issn: '',
            isbn: '',
            doi: '',
            url: '',
            keywords: [],
            philosophy: [],
            approach: [],
            strategy: [],
            method: [],
            time_horizon: [],
            sample_size: '',
            sample_description: [],
            data_collection_method: [],
            data_analysis_method: [],
            research_question: [],
            hypothesis: [],
            related_theory: [],
        },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const author_name = watch('author_name');
    const book_editor_name = watch('book_editor_name');

    useEffect(() => {
        console.log(author_name);
    }, [author_name]);

    const copy = (value) => {
        navigator.clipboard.writeText(value);

        setIsCopied(true);

        setTimeout(() => setIsCopied(false), 500);
    };

    const transform = (data) => {
        return {
            ...data,
            keywords: data.keywords?.map((item) => item.value),
            philosophy: data.philosophy?.map((item) => item.value),
            approach: data.approach?.map((item) => item.value),
            strategy: data.strategy?.map((item) => item.value),
            method: data.method?.map((item) => item.value),
            time_horizon: data.time_horizon?.map((item) => item.value),
            sample_description: data.sample_description?.map(
                (item) => item.value
            ),
            data_collection_method: data.data_collection_method?.map(
                (item) => item.value
            ),
            data_analysis_method: data.data_analysis_method?.map(
                (item) => item.value
            ),
            research_question: data.research_question?.map(
                (item) => item.value
            ),
            hypothesis: data.hypothesis?.map((item) => item.value),
            related_theory: data.related_theory?.map((item) => item.value),
        };
    };

    // privacy
    const overviewRef = useRef(null);

    const onOverviewRefUpdate = (ref) => {
        overviewRef.current = ref;
    };

    const getOverview = () => {
        if (overviewRef.current.current) {
            return overviewRef.current.current?.editor?.getHTML() ?? '';
        }
        return '';
    };

    const onSubmit = async (_data) => {
        const _overview = getOverview();

        const data = transform(_data);
        data['overview'] = _overview;

        let url = '/admin/source/store';
        const ret = await axiosPost(url, data);
        if (ret.status == 200) {
            toast.success(ret.data.message);
            navigate('/welcome');
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <>
            <Box maxWidth="md" className="bg-black/40 backdrop-blur-[20px]">
                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <DialogTitle> Add Source </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Bibliographic Reference
                                </Typography>
                                <Controller
                                    name="source_type"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Source Type (ST)</label>
                                            <Select
                                                fullWidth
                                                className="w-full"
                                                id="demo-select-small"
                                                value={value}
                                                size="small"
                                                onChange={onChange}
                                            >
                                                {[
                                                    'Empirical',
                                                    'Theoretical',
                                                    'Empirical and Theoretical',
                                                    'Other',
                                                ].map((item, index) => (
                                                    <MenuItem
                                                        key={item}
                                                        value={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.source_type && (
                                                <span className="text-rose-500">
                                                    {errors.source_type.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <NameField
                                    inputName="author_name"
                                    label="Author"
                                    value={author_name}
                                    setData={setValue}
                                    errors={errors}
                                    required={true}
                                />

                                <Controller
                                    name="year_of_publication"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Year of Publication</label>
                                            <TextField
                                                type="number"
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.year_of_publication && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors
                                                            .year_of_publication
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="type_of_publication"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Type of Publication</label>
                                            <Select
                                                fullWidth
                                                className="w-full"
                                                id="type_of_publication"
                                                value={value}
                                                size="small"
                                                onChange={onChange}
                                            >
                                                {[
                                                    'Book (Full)',
                                                    'Book (Chapter)',
                                                    'Chapter (Edited Volume)',
                                                    'Journal Article',
                                                    'Conference Paper',
                                                    'Other',
                                                ].map((item, index) => (
                                                    <MenuItem
                                                        key={item}
                                                        value={item}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.type_of_publication && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors
                                                            .type_of_publication
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Title</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.title && (
                                                <span className="text-rose-500">
                                                    {errors.title.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="book_title"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Book Title</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.book_title && (
                                                <span className="text-rose-500">
                                                    {errors.book_title.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <NameField
                                    inputName="book_editor_name"
                                    label="Book Editor"
                                    value={book_editor_name}
                                    setData={setValue}
                                    errors={errors}
                                    required={true}
                                />

                                <Controller
                                    name="publisher_name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Publisher Name</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.publisher_name && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.publisher_name
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="publisher_city"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Publisher City</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.publisher_city && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.publisher_city
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="conference_title"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Conference Title</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.conference_title && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.conference_title
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="journal_name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Journal Name</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.journal_name && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.journal_name
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="volume_number"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Volume Number</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.volume_number && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.volume_number
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="issue_number"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Issue Number</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.issue_number && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.issue_number
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="page_range"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Page Range</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.page_range && (
                                                <span className="text-rose-500">
                                                    {errors.page_range.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="issn"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>ISSN</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.issn && (
                                                <span className="text-rose-500">
                                                    {errors.issn.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="isbn"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>ISBN</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.isbn && (
                                                <span className="text-rose-500">
                                                    {errors.isbn.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="doi"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>DOI</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.doi && (
                                                <span className="text-rose-500">
                                                    {errors.doi.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="url"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>URL</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.url && (
                                                <span className="text-rose-500">
                                                    {errors.url.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <div className="mt-5">
                                    <Typography variant="h6">
                                        Source Details
                                    </Typography>
                                </div>
                                <Controller
                                    name="keywords"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Keywords</label>
                                            <CreatableSelect
                                                isMulti
                                                id="keywords"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                required
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                            {errors.keywords && (
                                                <span className="text-rose-500">
                                                    {errors.keywords.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="philosophy"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Philosophy</label>
                                            <CreatableSelect
                                                isMulti
                                                id="philosophy"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                            {errors.philosophy && (
                                                <span className="text-rose-500">
                                                    {errors.philosophy.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="approach"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Approach</label>
                                            <CreatableSelect
                                                isMulti
                                                id="approach"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                            {errors.approach && (
                                                <span className="text-rose-500">
                                                    {errors.approach.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="strategy"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Strategy</label>
                                            <CreatableSelect
                                                isMulti
                                                id="strategy"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                            {errors.strategy && (
                                                <span className="text-rose-500">
                                                    {errors.strategy.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="method"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Method</label>
                                            <CreatableSelect
                                                isMulti
                                                id="method"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="time_horizon"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Time Horizon</label>
                                            <CreatableSelect
                                                isMulti
                                                id="time_horizon"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="sample_size"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Sample Size</label>
                                            <TextField
                                                type="number"
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.sample_size && (
                                                <span className="text-rose-500">
                                                    {errors.sample_size.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="sample_description"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Sample Description</label>
                                            <CreatableSelect
                                                isMulti
                                                id="sample_description"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="data_collection_method"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>
                                                Data Collection Method
                                            </label>
                                            <CreatableSelect
                                                isMulti
                                                id="data_collection_method"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="data_analysis_method"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Data Analysis Method</label>
                                            <CreatableSelect
                                                isMulti
                                                id="data_analysis_method"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="research_question"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Research Question</label>
                                            <CreatableSelect
                                                isMulti
                                                id="research_question"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="hypothesis"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Hypothesis</label>
                                            <CreatableSelect
                                                isMulti
                                                id="hypothesis"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="related_theory"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-9 w-full">
                                            <label>Related Theory</label>
                                            <CreatableSelect
                                                isMulti
                                                id="related_theory"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                        </div>
                                    )}
                                />

                                <Box className="mt-5">
                                    <Box className="border-b-gray-500 py-2">
                                        <Typography>Overview</Typography>
                                    </Box>
                                    <Box className="">
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                sm={12}
                                                className="rich-content"
                                            >
                                                <RichEditor
                                                    content={''}
                                                    onInputRefUpdate={
                                                        onOverviewRefUpdate
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                                {source && source.br_apa && (
                                    <div>
                                        <table className="w-full border-separate border-spacing-0 text-sm font-medium">
                                            <caption className="mb-2 caption-top">
                                                Bibliographic Reference
                                            </caption>
                                            <tbody>
                                                <tr>
                                                    <th className="rounded-tl-md border border-b-0 border-gray-300 p-2">
                                                        APA
                                                    </th>
                                                    <td
                                                        className="cursor-pointer rounded-tr-md border-r border-t border-gray-300 p-2 hover:bg-gray-100 hover:text-blue-600"
                                                        onClick={() =>
                                                            copy(source.br_apa)
                                                        }
                                                    >
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: source.br_apa,
                                                            }}
                                                        ></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="rounded-bl-md border border-gray-300 p-2">
                                                        MLA
                                                    </th>
                                                    <td
                                                        className="cursor-pointer rounded-br-md border border-l-0 border-gray-300 p-2 hover:bg-gray-100 hover:text-blue-600"
                                                        onClick={() =>
                                                            copy(source.br_mla)
                                                        }
                                                    >
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: source.br_mla,
                                                            }}
                                                        ></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="flex">
                                            <p className="mt-2 text-sm">
                                                Click on the reference to copy
                                                it.
                                            </p>
                                            <Transition
                                                show={isCopied}
                                                enterFrom="opacity-0"
                                                leaveTo="opacity-0"
                                                className="transition ease-in-out"
                                            >
                                                <p className="ml-1 mt-2 text-sm">
                                                    Copied.
                                                </p>
                                            </Transition>
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate('/welcome');
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" type="submit">
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Box>
        </>
    );
};

export default UserCreateSourcePage;
