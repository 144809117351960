import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import SearchTerms from './partial/SearchTerms';

const schema = yup.object().shape({
    name: yup.string().required(),
    sort_order: yup.number().required().min(1),
    intro_title: yup.string().required(),
    intro_description: yup.string().required(),
});

const CreateEditModal = ({ setOpenModal, openModal, refresh, id, title }) => {
    const [subjects, setSubjects] = useState([]);
    const [sources, setSources] = useState([]);
    const [mostSearch, setMostSearch] = useState([]);

    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            // description: item?.description || "",
            name: '',
            sort_order: 0,
            intro_title: '',
            intro_description: '',
        },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const getData = async () => {
        const params = {
            id: id,
        };

        const ret = await axiosPost('/admin/program/getData', params);
        if (ret.status == 200) {
            const program = ret.data.program;
            const setValues = {
                name: program?.name,
                sort_order: program?.sort_order,
                intro_title: program?.intro_title,
                intro_description: program?.intro_description,
            };
            setSources(ret.data.sources);
            setSubjects(ret.data.subjects);
            setMostSearch(ret.data.most_search);
            reset(setValues);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const onSubmit = async (_data) => {
        console.log('on submit', _data);
        let url = '/admin/program/store';
        if (id) {
            _data['id'] = id;
            url = '/admin/program/update';
        }
        const ret = await axiosPost(url, _data);
        if (ret.status == 200) {
            refresh();
            setOpenModal(false);
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <DialogTitle> {title}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    variant="h6"
                                    sx={{ mb: 2 }}
                                ></Typography>

                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Name</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.name && (
                                                <span className="text-rose-500">
                                                    {errors.name.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="sort_order"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Sort Order</label>
                                            <TextField
                                                type="number"
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.sort_order && (
                                                <span className="text-rose-500">
                                                    {errors.sort_order.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                <Box className="mb-2 mt-2">
                                    <Typography variant="h6">
                                        Programme Introduction
                                    </Typography>
                                </Box>

                                <Controller
                                    name="intro_title"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>Introduction Title</label>
                                            <TextField
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.intro_title && (
                                                <span className="text-rose-500">
                                                    {errors.intro_title.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="intro_description"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-3 w-full">
                                            <label>
                                                Introduction Description
                                            </label>
                                            <TextField
                                                multiline
                                                rows={5}
                                                size="small"
                                                className="mt-1 w-full"
                                                value={value}
                                                onChange={onChange}
                                            />
                                            {errors.intro_description && (
                                                <span className="text-rose-500">
                                                    {
                                                        errors.intro_description
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />

                                {subjects.length > 0 && (
                                    <Box className="mb-2 mt-2">
                                        <Typography variant="h6">
                                            Programme Subjects
                                        </Typography>
                                    </Box>
                                )}

                                {subjects.length > 0 && (
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="right">
                                                        Description
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        Sort Order
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {subjects.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                            '&:last-child td, &:last-child th':
                                                                { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.description}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.sort_order}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}

                                {id && <SearchTerms id={id} />}

                                {sources.length > 0 && (
                                    <Box className="mb-2 mt-2">
                                        <Typography variant="h6">
                                            Top 5 Cited Sources
                                        </Typography>
                                    </Box>
                                )}
                                {sources.map((item, index) => (
                                    <p
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                            __html: item.br_apa,
                                        }}
                                    ></p>
                                ))}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateEditModal;
