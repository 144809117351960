import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import TablePagination from '@mui/material/TablePagination';

import { axiosPost } from '@/services/axios';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

const headCells = [
    {
        id: 'created_at',
        numeric: false,
        sortable: true,
        label: 'Sign On',
    },
    {
        id: 'end_at',
        numeric: true,
        sortable: true,
        label: 'Session end',
    },
    {
        id: 'id',
        numeric: false,
        sortable: false,
        label: 'Duration',
    },
    {
        id: 'browser',
        numeric: false,
        sortable: false,
        label: 'Browser',
    },
    {
        id: 'ip',
        numeric: false,
        sortable: false,
        label: 'Ip',
    },
    {
        id: 'country',
        numeric: false,
        sortable: false,
        label: 'Country',
    },
    {
        id: 'city',
        numeric: false,
        sortable: false,
        label: 'City',
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white">
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        align={headCell?.align}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <div>{headCell.label}</div>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const AccessHistory = ({ user_id }) => {
    const [loading, setLoading] = useState(false);

    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_at');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        // Clear previous timeout to avoid redundant API calls
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout to trigger search after 500ms (adjust as needed)
        const timeout = setTimeout(() => {
            getData();
        }, 1000); // Adjust delay time (in milliseconds) as needed

        // Update searchTimeout state to store the timeout ID
        setSearchTimeout(timeout);

        // Cleanup function to clear timeout when component unmounts or searchKey changes
        return () => clearTimeout(timeout);
    }, [page, rowsPerPage, orderBy, order]);

    const getData = async () => {
        console.log(dayjs().utcOffset());
        setLoading(true);
        try {
            const params = {
                page,
                pageSize: rowsPerPage,
                id: user_id,
                orderBy,
                order,
            };
            const ret = await axiosPost('/admin/user/getHistory', params);
            if (ret.status == 200) {
                const list = ret.data.data;
                const total = ret.data.total;
                setRows(list);
                setTotal(total);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const duration = (start, end) => {
    //     const startTime = dayjs(start);
    //     const endTime = dayjs(end);
    //     const durationHours = endTime.diff(startTime, 'hour');
    //     return durationHours;
    // }

    const duration = (start, end) => {
        const startTime = dayjs(start);
        const endTime = dayjs(end);

        // Calculate total duration in minutes
        const durationMinutes = endTime.diff(startTime, 'minute');

        // Calculate days, hours, and minutes
        const days = Math.floor(durationMinutes / (60 * 24));
        const hours = Math.floor((durationMinutes % (60 * 24)) / 60);
        const minutes = durationMinutes % 60;

        // Build the formatted duration string
        const parts = [];

        if (days > 0) {
            parts.push(`${days} day${days > 1 ? 's' : ''}`);
        }

        if (hours > 0) {
            parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
        }

        if (minutes > 0 || parts.length === 0) {
            parts.push(`${minutes} min${minutes !== 1 ? 's' : ''}`);
        }

        console.log(parts);
        return parts.join(' ');
    };

    return (
        <div className="">
            <div className="">
                <TableContainer
                    component={Paper}
                    className="rounded-lg border border-gray-500"
                >
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="rounded-lg bg-gray-100 text-black dark:bg-gray-700 dark:text-white"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {row.created_at &&
                                                dayjs(row.created_at).format(
                                                    'YYYY-MM-DD HH:mm:ss'
                                                )}
                                        </TableCell>
                                        <TableCell>
                                            {row.updated_at &&
                                                dayjs(row.updated_at).format(
                                                    'YYYY-MM-DD HH:mm:ss'
                                                )}
                                        </TableCell>
                                        <TableCell>
                                            {duration(
                                                row.created_at,
                                                row.updated_at
                                            )}
                                        </TableCell>
                                        <TableCell>{row.browser}</TableCell>
                                        <TableCell>{row.ip}</TableCell>
                                        <TableCell>{row.country}</TableCell>
                                        <TableCell>{row.city}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

export default AccessHistory;
