import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Input,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { html } from '@codemirror/lang-html';
import {
    basicLight,
    basicLightInit,
    basicDark,
    basicDarkInit,
} from '@uiw/codemirror-theme-basic';

const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    from_name: yup.string().required(),
    from: yup.string().email().required(),
    content: yup.string().required(),
});

const ViewModal = ({ setOpenModal, openModal, id, title }) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const getData = async () => {
        const params = {
            id: id,
        };

        const ret = await axiosPost('/admin/notification/getData', params);
        if (ret.status == 200) {
            const data = ret.data.data;
            const setValues = {
                name: data?.name,
                description: data?.description,
                from_name: data?.from_name,
                from: data?.from,
                to: data?.to,
                cc: data?.cc,
                reply_to: data?.reply_to,
                subject: data?.subject,
                content: data?.content,
            };
            setContent(data?.content);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    // Define your extra tags
    const extraTags = ['first-name', 'last-name'];

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <DialogTitle> {title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <div className="w-full">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                ></div>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleCloseConfirm();
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ViewModal;
