import React, { useEffect, useState } from 'react';
import { Button, FormControl, Modal, TextField } from '@mui/material';
import { axiosPost } from '@/services/axios';
import { toast } from 'react-toastify';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

export default function DeleteModal({ openModal, setOpenModal }) {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const onChangePassword = (e) => {
        setPassword(e);
        setError(false);
    };

    const deleteAccount = async () => {
        if (password == '') {
            setError(true);
            return;
        }

        try {
            const res = await axiosPost('/user/profile/delete', {
                password,
            });

            console.log(res);
            if (res.status == 200) {
                setOpenModal(false);
                toast.success(res.data.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });

                localStorage.removeItem('user');
                window.location.href = '/';
            } else {
                toast.warn(res.data.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            }
        } catch (err) {
            toast.warn(err.response.data.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const handleClose = () => {
        setOpenModal(false);
        setPassword('');
    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="flex min-h-screen items-center px-4 py-8">
                    <div className="relative mx-auto w-full max-w-lg rounded-md bg-white p-4 text-black shadow-lg dark:bg-gray-700 dark:text-white">
                        <div className="mt-3 sm:flex">
                            <div className="mx-auto flex h-12 w-12 flex-none items-center justify-center rounded-full">
                                <ExclamationTriangleIcon className="text-red-600" />
                            </div>
                            <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                <h2 className="text-lg font-medium">
                                    Are you sure you want to delete your
                                    account?
                                </h2>

                                <p className="mt-1 text-sm">
                                    Once your account is deleted, all of its
                                    resources and data will be permanently
                                    deleted. Please enter your password to
                                    confirm you would like to permanently delete
                                    your account.
                                </p>

                                <div className="mt-3">
                                    <FormControl fullWidth size="small">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={password}
                                            onChange={(e) =>
                                                onChangePassword(e.target.value)
                                            }
                                            error={error}
                                            label={'Password'}
                                            type="password"
                                        />
                                    </FormControl>
                                </div>

                                <div className="mt-6 flex justify-end">
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        onClick={() => handleClose()}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="error"
                                        className="ml-3"
                                        sx={{ ml: 3 }}
                                        onClick={deleteAccount}
                                    >
                                        Delete Account
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
